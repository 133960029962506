import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TalkConversationStartModal } from '@common/components/app/talk/conversation-start/conversation-start.modal';
import { UserProfileInterface } from '@common/interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class TalkConversationStartController {

  constructor(private modalCtrl: ModalController) {
  }


  /**
   * Start group conversation
   */
  async startNewConversation(callback: (participants: UserProfileInterface[], title: string) => void) {
    const modal = await this.modalCtrl.create({
      component: TalkConversationStartModal,
      cssClass: 'default-modals messages-group-start-modal',
      keyboardClose: true,
      showBackdrop: true
    });

    modal.onDidDismiss().then(data => {
      if (data?.role === 'apply' && callback) {
        callback(data?.data?.participants, data?.data?.title);
      }
    });

    return await modal.present();
  }


}

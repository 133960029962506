import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from '@common/services/common.service';
import { ProfileFieldsModal } from './profile-fields.modal';
import { Observable } from 'rxjs';
import { UsersService } from '@common/services/api/client';
import {
  buildFilteredFieldsCode,
  hasMandatoryFieldsNotSet,
  hasMandatoryListFieldsWithBadValues,
  hasProvisioningFieldsNotSet
} from '@common/helpers/users';
import { ProfileFieldsModalProps, ProfileFieldsModalResult } from '@common/components/app/profile-fields/profile-fields.interface';


/**
 * Component that manage medias viewer modal showing
 */

@Injectable()
export class ProfileFieldsController {

  constructor(protected modalCtrl: ModalController,
              protected usersServ: UsersService,
              protected commonServ: CommonService) {
  }

  checkForProfileFields(idUser?: number) {
    return new Observable(observer => {
      this.usersServ.provisioningProfile(idUser).subscribe(response => {
        if (
          hasMandatoryFieldsNotSet(response.fields) ||
          hasMandatoryListFieldsWithBadValues(response.fields) ||
          hasProvisioningFieldsNotSet(response.fields, response.provisioningFields)
        ) {
          this.show({
            idCustomer: idUser || 0,
            fields: response.fields,
            filteredFields: buildFilteredFieldsCode(response.fields, response.provisioningFields),
            showFields: true,
            allowDisable: false,
            editMode: 'default'
          }).then(() => {
            observer.next();
            observer.complete();
          });
        } else {
          observer.next();
          observer.complete();
        }
      });
    });
  }

  /**
   * Display modal
   * @param props options of modal
   * @param closable can be closed
   */
  show(props: ProfileFieldsModalProps, closable: boolean = false) {
    return new Promise<ProfileFieldsModalResult>(async resolve => {
      const modal = await this.modalCtrl.create({
        component: ProfileFieldsModal,
        componentProps: {...props, closable},
        cssClass: 'profile-fields-modal',
        keyboardClose: closable,
        showBackdrop: true,
        backdropDismiss: closable
      });

      modal.onDidDismiss().then((data: ProfileFieldsModalResult) => {
        resolve(data);
      });

      modal.present().then(/* Nothing to do */);
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpBaseService } from '@common/services/api/http-base.service';
import { FeedPostType } from '@common/interfaces/api/client';
import { StandardResponseInterface } from '@common/interfaces/api';
import { ReqAdminShareableInterface } from '@common/interfaces/api/admin/sharing.interface';
import { Observable } from 'rxjs';


@Injectable()
export class AdminSharingService extends HttpBaseService {

  /**
   * Used to publish content on team feed
   * @param idItem id of item (Challenge, Quiz, ...)
   * @param type type of content
   * @param idTeamFeed team feed id
   */
  publish(idItem: number, type: FeedPostType, idTeamFeed: number = 0) {
    const body = new FormData();
    let uri = 'Challenge';

    switch (type) {
      case 'challenge':
        body.append('id_challenge', idItem.toString());
        uri = 'Challenge';
        break;
      case 'quiz':
        body.append('id_quizz', idItem.toString());
        uri = 'Quizz';
        break;
      case 'survey':
        body.append('id_sondage', idItem.toString());
        uri = 'Sondage';
        break;
      case 'form':
        body.append('id_form', idItem.toString());
        uri = 'Form';
        break;
      case 'gallery':
        body.append('id_gallery', idItem.toString());
        uri = 'Gallery';
        break;
      default:
        throw new Error('Type not supported');
    }

    if (idTeamFeed > 0) {
      body.append('id_team_feed', idTeamFeed.toString());
    }

    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/${ uri }/publish`, body));
  }

  /**
   * Used to know if content is shareable on team feed
   * @param idItem id of item (Challenge, Quiz, ...)
   * @param type type of content
   */

  shareable(idItem: number, type: FeedPostType): Observable<ReqAdminShareableInterface> {
    const body = new FormData();
    let uri = 'Challenge';

    switch (type) {
      case 'challenge':
        body.append('id_challenge', idItem.toString());
        uri = 'Challenge';
        break;
      case 'quiz':
        body.append('id_quizz', idItem.toString());
        uri = 'Quizz';
        break;
      case 'survey':
        body.append('id_sondage', idItem.toString());
        uri = 'Sondage';
        break;
      case 'form':
        body.append('id_form', idItem.toString());
        uri = 'Form';
        break;
      case 'gallery':
        body.append('id_gallery', idItem.toString());
        uri = 'Gallery';
        break;
      default:
        throw new Error('Type not supported');
    }

    return this.stdRequest(this.http.post<ReqAdminShareableInterface>(`${ this.rootApi }/administration/${ uri }/shareable`, body));
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { CommonService } from '@common/services/common.service';
import { ConnectionService } from '@common/services/connection.service';

/**
 * Maintenance interceptor for managing maintenance mode
 */

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {


  constructor(private commonServ: CommonService,
              private connectionServ: ConnectionService,
              private navCtrl: NavController) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If maintenance mode is enabled, block all requests
    if (this.connectionServ.isMaintenance()) {
      return throwError('Maintenance');
    }

    return next.handle(request).pipe(
      catchError((error) => throwError(error)),
      tap((response: any) => {
        // Enter in maintenance mode if [maintenance_enabled] is true
        if (response?.body?.maintenance_enabled === true) {
          this.connectionServ.setIsMaintenance(true);
          setTimeout(() => {
            this.navCtrl.navigateRoot(['/maintenance']).then(/* Nothing to do */);
            this.commonServ.logout(true);
          }, 500);
          return throwError('Maintenance');
        } else if (response?.body?.maintenance_enabled === false) {
          this.connectionServ.setIsMaintenance(false);
        }
      })
    );
  }
}

import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AdminApiOauthInterface,
  AdminMainData,
  AdminMainInterface,
  ReqAdminApiInterface,
  ReqAdminMainInterface
} from '@common/interfaces/api/admin';
import { StandardResponseInterface } from '@common/interfaces/api';
import { map, tap } from 'rxjs/operators';
import { Utils } from '@common/helpers/utils';

@Injectable()
export class AdminMainService extends HttpBaseService {


  /**
   * Get main settings
   */
  main(): Observable<AdminMainInterface> {
    return this.stdRequest(this.http.post<ReqAdminMainInterface>(`${ this.rootApi }/administration/Main`, null).pipe(
      tap(response => {
        // cast data
        if (response?.mainInfos) {
          response.mainInfos.team_feed = Utils.resultToBoolean(response.mainInfos.team_feed);
          response.mainInfos.team_feed_comment = Utils.resultToBoolean(response.mainInfos.team_feed_comment);
          response.mainInfos.team_feed_post = Utils.resultToBoolean(response.mainInfos.team_feed_post);
          response.mainInfos.team_feed_view_list = Utils.resultToBoolean(response.mainInfos.team_feed_view_list);
          response.mainInfos.team_feed_like_list = Utils.resultToBoolean(response.mainInfos.team_feed_like_list);
        }
      }),
      map(response => response.mainInfos)
    ));
  }

  /**
   * Update main settings
   * @param data input data
   */
  updateMain(data: AdminMainData): Observable<StandardResponseInterface> {
    const body = new FormData();

    body.append('main_infos', JSON.stringify(data.main_infos));

    if (data.logo) {
      body.append('logo', data.logo);
    }
    if (data.logo_dm) {
      body.append('logo_dm', data.logo_dm);
    }
    if (data.logo_square) {
      body.append('logo_square', data.logo_square);
    }
    if (data.image) {
      body.append('image', data.image);
    }

    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Main/update`, body));
  }

  /**
   * Get api info
   */
  api(): Observable<ReqAdminApiInterface> {
    return this.stdRequest(this.http.post<ReqAdminApiInterface>(`${ this.rootApi }/administration/Api`, null).pipe(
      map(response => response)
    ));
  }

  /**
   * Renew api info
   */
  apiRenew(): Observable<ReqAdminApiInterface> {
    return this.stdRequest(this.http.post<ReqAdminApiInterface>(`${ this.rootApi }/administration/Api/renew`, null).pipe(
      map(response => response)
    ));
  }

  /**
   * Renew oauth client secret
   */
  oauthRenew(data: AdminApiOauthInterface): Observable<ReqAdminApiInterface> {
    const body = new FormData();

    body.append('id_user', data.id_user);
    body.append('redirect_uri', data.redirect_uri);
    body.append('renew_client_secret', '1');
    return this.stdRequest(this.http.post<ReqAdminApiInterface>(`${ this.rootApi }/administration/Api/update`, body).pipe(
      map(response => response)
    ));
  }

}

import { Component, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserProfileInterface } from '@common/interfaces/common.interface';

@Component({
  selector: 'app-talk-conversation-start-modal',
  templateUrl: './conversation-start.modal.html',
  styleUrls: ['./conversation-start.modal.scss'],
})
export class TalkConversationStartModal {

  maxParticipants: number = 19;

  title: string = '';

  participants: UserProfileInterface[] = [];
  profile?: UserProfileInterface;

  constructor(private modalCtrl: ModalController) { }


  addUser(user: UserProfileInterface) {
    const index = this.participants.findIndex(item => item.id_customer === user.id_customer);
    if (index === -1 && this.participants?.length < this.maxParticipants) {
      this.participants.push(user);
    }
  }

  removeUser(index: number) {
    this.participants.splice(index, 1);
  }

  validate() {
    this.modalCtrl.dismiss({
      participants: this.participants,
      title: this.title
    }, 'apply').then(/* Nothing to do */);
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel').then(/* Nothing to do */);
  }
}

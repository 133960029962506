import { Injectable } from '@angular/core';
import { BadgeNotificationInterface, PlatformInterface } from '@common/interfaces/api';
import { GeneralService } from '@common/services/api';
import { BadgeNotificationsEvents } from '@common/services/notifications/badge-notifications-events.model';
import {
  BadgeNotificationEventInterface,
  BadgeNotificationsEventsIdsInterface,
  BadgeNotificationsEventsInterface
} from '@common/services/notifications/notifications.interface';
import { NotificationsUtils } from '@common/services/notifications/notifications.utils';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Badge } from '@capawesome/capacitor-badge';
import { arrayUniqueValues } from '@common/helpers/utils';

/**
 * Notifications service
 *
 * There are 3 kind of notifications managed by this service:
 *   - InApp Notifications Number - Notifications get from backend and displayed through bell icon
 *   - Native Icon Notifications badge - The number displayed next to App Icon on mobile device
 *   - Badge Notifications events - Notifications get from backend and displayed as route badge.
 *
 * Badge Notifications Events Operation behavior:
 * . At initialization, this component gets all badge notifications events and build the related model for client and dashboard.
 * . When user navigate on page which shall clear notification, after calling [clearEvents] interface,
 * this action register a state to refresh notifications list on the next route change.
 * . When user change the route, new list is get from backend and build in model. -> All component which supervise badge events are noticed of changes.
 * . Auto refresh is applied on route change if no refresh occurs during last 2 mins
 */
@Injectable({providedIn: 'root'})
export class NotificationsService {

  /**
   * Data to manage InApp notifications count
   * @private
   */
  private inAppNotificationsCountState: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /**
   * Data to manage Native Icon Badge notifications number
   * @private
   */
  private canManageNativeIconBadge?: boolean = undefined;
  private nativeIconNotificationsCount = 0;

  /**
   * Data to store and manage badge notifications events
   * @private
   */
  private clientEventsState: BehaviorSubject<BadgeNotificationsEventsInterface> = new BehaviorSubject<BadgeNotificationsEventsInterface>(new BadgeNotificationsEvents());
  private dashboardEventsState: BehaviorSubject<BadgeNotificationsEventsInterface> = new BehaviorSubject<BadgeNotificationsEventsInterface>(new BadgeNotificationsEvents());

  private shallRefreshEvents = true; // Refresh events at initialization
  private lastRefreshTime = 0; // Last refresh time

  private notificationsToClear: BadgeNotificationEventInterface[] = [];

  private subscription?: Subscription;

  private platform?: PlatformInterface;

  constructor(private generaleServ: GeneralService,
              private ionicPlatform: Platform,
              private router: Router) {
  }

  /**
   * Initialize notifications service
   */
  async init() {
    this.refreshEvents();

    // Manage refresh on route change
    if (!this.subscription) {
      this.subscription = this.router.events
        .pipe(
          filter(e => e instanceof NavigationEnd),
          distinctUntilChanged()
        )
        .subscribe(() => {
          // Supervise NavigationEnd event to manage refresh notifications
          if (!!this.platform) {
            this.applyClearEvents().then(() => {
              this.refreshEvents();
            });
          }
        });
    }

    // Determine Native Icon Badge authorization
    if (this.canManageNativeIconBadge === undefined) {
      this.canManageNativeIconBadge = false;
      if (this.ionicPlatform.is('capacitor')) {
        const supported = await Badge.isSupported();
        if (supported?.isSupported) {
          const permission = await Badge.requestPermissions();
          if (permission.display !== 'denied') {
            this.nativeIconNotificationsCount = (await Badge.get())?.count || 0;
            this.canManageNativeIconBadge = true;
          }
        }
      }
    }
  }

  /**
   * Flush the service
   * To call when user is disconnected
   */
  flush() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }

    this.clientEventsState.next(new BadgeNotificationsEvents());
    this.dashboardEventsState.next(new BadgeNotificationsEvents());

    this.shallRefreshEvents = true;
    this.lastRefreshTime = 0;
    this.notificationsToClear = [];
  }

  /**
   * Callback to call on platform change
   * @param platform
   */
  onPlatformChange(platform?: PlatformInterface) {
    this.platform = platform;
    this.refreshEvents();
  }

  /*******
   *  THIS SECTION MANAGE IN-APP NOTIFICATIONS EVENTS
   */

  /**
   * Get notifications count state
   * @info Notifications count is the number of notifications not viewed by user. It differs from not read notifications.
   */
  getInAppNotificationsCountState() {
    return this.inAppNotificationsCountState;
  }

  /**
   * Get notifications count value
   */
  getInAppNotificationsCountValue() {
    return this.inAppNotificationsCountState.getValue();
  }

  /**
   * Set notifications count value
   * @info this callback is called in interceptor when field notifications_count is provided in response of request
   * @param value new value
   */
  setInAppNotificationsCountValue(value: number) {
    if (this.inAppNotificationsCountState.getValue() !== value) {
      this.inAppNotificationsCountState.next(value);
    }
  }

  /*******
   *  THIS SECTION MANAGE NATIVE-ICON NOTIFICATIONS BADGE
   */
  setNativeIconNotificationsBadge(value: number) {
    if (this.canManageNativeIconBadge) {
      if (this.nativeIconNotificationsCount !== value) {
        Badge.set({count: value}).then(() => this.nativeIconNotificationsCount = value);
      }
    }
  }


  /*******
   *  THIS SECTION MANAGE BADGE NOTIFICATIONS EVENTS
   */
  /**
   * Get notifications events in subscription strategy
   * @param forDashboard events for dashboard
   */
  eventsState(forDashboard = false): Observable<BadgeNotificationsEventsInterface> {
    return forDashboard ? this.dashboardEventsState.asObservable() : this.clientEventsState.asObservable();
  }

  /**
   * Get notifications events
   * @param forDashboard events for dashboard
   */
  events(forDashboard = false): BadgeNotificationsEventsInterface {
    return forDashboard ? this.dashboardEventsState.getValue() : this.clientEventsState.getValue();
  }

  /**
   * Build notifications events
   * @param notifications notifications list
   */
  buildEvents(notifications: BadgeNotificationInterface[]) {
    const clientEvents = this.clientEventsState.getValue();
    const dashboardEvents = this.dashboardEventsState.getValue();

    // Pre treatment
    clientEvents.challengesCategoriesIds = [];
    clientEvents.roadboxCategoriesIds = [];

    // Build events
    notifications?.forEach(notification => {
      if (notification.is_admin) {
        dashboardEvents.addId(notification.type, notification.reference_id, notification.id_notification);
        // Categories
        if (notification.type === NotificationsUtils.CHALLENGE_PROOF) {
          dashboardEvents.challengesCategoriesIds = dashboardEvents.challengesCategoriesIds.concat(notification.categories?.map(val => val.id_category));
        }
      } else {
        clientEvents.addId(notification.type, notification.reference_id, notification.id_notification);
        // Categories
        if (
          notification.type === NotificationsUtils.CHALLENGE ||
          notification.type === NotificationsUtils.CHALLENGE_UPDATE ||
          notification.type === NotificationsUtils.CHALLENGE_PROOF
        ) {
          clientEvents.challengesCategoriesIds = clientEvents.challengesCategoriesIds.concat(notification.categories?.map(val => val.id_category));
        } else if (notification.type === NotificationsUtils.ROADBOX) {
          clientEvents.roadboxCategoriesIds = clientEvents.roadboxCategoriesIds.concat(notification.categories?.map(val => val.id_category));
        }
      }
    });

    // Post treatment
    clientEvents.challengesCategoriesIds = arrayUniqueValues(clientEvents.challengesCategoriesIds);
    clientEvents.roadboxCategoriesIds = arrayUniqueValues(clientEvents.roadboxCategoriesIds);

    // Build flags
    clientEvents.buildFlags();
    dashboardEvents.buildFlags();

    // Update events
    this.clientEventsState.next(clientEvents);
    this.dashboardEventsState.next(dashboardEvents);

    // console.log(clientEvents);
  }

  /**
   * Mark to clear events in the next route changes
   * @param type type of notification
   * @param relationId id of notification
   * @param forDashboard for dashboard
   */
  markToClearEventsByRelationId(type: string, relationId: number, forDashboard = false) {
    this.notificationsToClear.push({type, relationId, forDashboard});
  }

  /**
   * Clear events by relation id
   * @param type type of notification
   * @param relationId id of notification
   * @param forDashboard for dashboard
   * @param refreshNow refresh events now instead on last route change
   */
  clearEventsByRelationId(type: string,
                          relationId: number,
                          forDashboard = false,
                          refreshNow = false) {
    const events = forDashboard ? this.dashboardEventsState.getValue() : this.clientEventsState.getValue();
    const ids = NotificationsUtils.badgeListByType(events, type)?.filter(item => item.relationId === relationId);

    if (ids && ids?.length > 0) {
      // Clear backend
      let notificationIds: Array<number> = [];
      ids.forEach(item => {
        notificationIds = notificationIds.concat(item.notificationIds);
      });

      this.generaleServ.readNotificationByList(notificationIds).subscribe(() => {

        // Reset events
        events.removeId(type, relationId);
        this.shallRefreshEvents = true;
        if (refreshNow) {
          this.refreshEvents();
        } else {
          events.buildFlags();
        }

        // console.log('RESET EVENTS', events, type, relationId);
      });
    }
  }

  /**
   * Clear events by type
   * @param type type of notification
   * @param forDashboard for dashboard
   */
  clearEventsByType(type: string, forDashboard = false) {
    const events: any = forDashboard ? this.dashboardEventsState.getValue() : this.clientEventsState.getValue();
    const idsKey = NotificationsUtils.badgeIdsKeyByType(type);
    const ids: BadgeNotificationsEventsIdsInterface[] = events[idsKey];
    if (ids?.length > 0) {
      // Clear backend
      let notificationIds: Array<number> = [];
      ids.forEach(item => {
        notificationIds = notificationIds.concat(item.notificationIds);
      });
      this.generaleServ.readNotificationByList(notificationIds).subscribe(() => {
        // Reset events
        this.shallRefreshEvents = true;
        events[idsKey] = [];
        events.buildFlags();
      });
    }
  }

  /**
   * Apply clear events
   * @private
   */
  private applyClearEvents(): Promise<void> {
    return new Promise<void>(resolve => {
      if (this.notificationsToClear.length > 0) {

        let notificationIds: Array<number> = [];
        // Build ids from all notifications events
        for (const notification of this.notificationsToClear) {
          const events = notification.forDashboard ? this.dashboardEventsState.getValue() : this.clientEventsState.getValue();
          const ids = NotificationsUtils.badgeListByType(events, notification.type)?.filter(item => item.relationId === notification.relationId);

          if (ids?.length > 0) {
            // Build backend notification
            ids.forEach(item => {
              notificationIds = notificationIds.concat(item.notificationIds);
            });

            // Reset events
            events.removeId(notification.type, notification.relationId);
            this.shallRefreshEvents = true;
            events.buildFlags();
          }
        }
        // Clear backend
        this.generaleServ.readNotificationByList(notificationIds).subscribe({
          next: () => {
            this.notificationsToClear = [];
            resolve();
          },
          error: () => resolve()
        });

      } else {
        resolve();
      }
    });
  }

  /**
   * Refresh events
   * @private
   */
  private refreshEvents() {
    const now = Date.now();
    // 120000 = 2min (2 * 60 * 1000)
    if (this.shallRefreshEvents || ((now - this.lastRefreshTime) > 120000)) {
      this.shallRefreshEvents = false;
      this.lastRefreshTime = now;
      this.generaleServ.badgeNotifications().subscribe(response => {
        this.buildEvents(response.notifications);
      });
    }
  }
}

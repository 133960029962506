import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor  {

  @ViewChild('inputElement', {static: false}) inputElement: ElementRef;

  @Input() value = '';
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() invalid: boolean;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  inputType = 'password';

  isFocused = false;

  public onChange = (_: any) => {
    this.valueChange.emit(_ || this.value);
  }
  public onTouch = () => {};

  constructor() { }

  ngOnInit() {}

  focus(options?: FocusOptions) {
    this.inputElement?.nativeElement?.focus(options);
  }

  blur() {
    this.inputElement?.nativeElement?.blur();
  }

  /**
   * ControlValueAccessor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {

    this.value = inputValue;

    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}

import { Injectable } from '@angular/core';
import { SheetModalController } from '@common/services/sheet-modal.controller';
import { AssistanceChoiceModal } from '@common/components/modals/assistance/assistance-choice.modal';
import { AssistanceContactModal } from '@common/components/modals/assistance/assistance-contact.modal';

/**
 * Component that manage filter panel modal
 */

@Injectable()
export class AssistanceController {

  constructor(protected modalCtrl: SheetModalController) {
  }

  /**
   * Display assistance modal
   */
  async show() {
    const modal = await this.modalCtrl.create({
      component: AssistanceChoiceModal,
      componentProps: {contactFn: this.showContactForm},
      cssClass: 'assistance-choice-modal',
      breakpoints: [0, .25],
      initialBreakpoint: .25,
      keyboardClose: true,
      showBackdrop: true
    });

    return await modal.present();
  }


  /**
   * Display contact form
   */
  async showContactForm(callback?: (data: any) => void) {
    const modal = await this.modalCtrl.create({
      component: AssistanceContactModal,
      cssClass: 'assistance-contact-modal',
      breakpoints: [0, .5, 1],
      initialBreakpoint: .5,
      keyboardClose: true,
      showBackdrop: true
    });

    modal.onDidDismiss().then(data => {
      if (callback) {
        callback(data);
      }
    });

    return await modal.present();
  }

}

import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import {
  AdminChallengeCategoryData,
  AdminChallengeData,
  AdminChallengeInterface,
  AdminChallengesData, AdminChallengeProofStatusType,
  AdminChallengeUpdateProgressionData, ChallengeExtraData,
  ReqAdminChallengeCategoriesInterface,
  ReqAdminChallengeDistributionsInterface, ReqAdminChallengeExtrasInterface,
  ReqAdminChallengeHistoryInterface, ReqAdminChallengeProgressionsInterface,
  ReqAdminChallengesInterface,
  ReqAdminChallengeUserFiltersInterface, ReqAdminChallengeAddUpdateInterface
} from '@common/interfaces/api/admin';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { ChallengeCategoryInterface, ChallengeStatusType } from '@common/interfaces/api/client';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { Utils } from '@common/helpers/utils';
import { StandardExportInterface, StandardResponseInterface } from '@common/interfaces/api';


@Injectable()
export class AdminChallengesService extends HttpBaseService {

  static NUMBER_OF_CHALLENGES = 20;

  public isChallengeDrag = false;
  public isCategoryDrag = false;

  private lastChallenges: {
    result?: ReqAdminChallengesInterface,
    debut: number;
  };

  private categoriesFromRoot: ReqAdminChallengeCategoriesInterface;
  private categoriesLastStatus: ChallengeStatusType = 'all';

  private nbRootCategories = 0;
  private nbRootChallenges = 0;

  private extrasState: BehaviorSubject<ChallengeExtraData[]> = new BehaviorSubject<ChallengeExtraData[]>(undefined);

  constructor(protected http: HttpClient,
              protected translateServ: TranslocoService) {
    super(http);
  }

  /**
   * Used to get challenges
   * @param params params
   * @param next get next results
   */
  challenges(params?: AdminChallengesData, next = false): Observable<ReqAdminChallengesInterface> {
    if (next === false) {
      this.lastChallenges = {result: null, debut: 0};
    } else {
      this.lastChallenges.debut += AdminChallengesService.NUMBER_OF_CHALLENGES;
    }

    const body = new FormData();
    body.append('debut', this.lastChallenges.debut.toString());
    if (params?.search) {
      body.append('search_txt', params?.search);
    }
    if (params?.status && params?.status !== 'all') {
      body.append('filter', params?.status);
    }
    if (params?.idCategory) {
      body.append('id_category', params?.idCategory);
    }
    if (params?.sendPoints) {
      body.append('send_points', params?.sendPoints);
    }
    if (params?.child) {
      body.append('child', params?.child);
    }
    if (params?.parentBase) {
      body.append('parent_base', params?.parentBase);

      if (Utils.toNumber(params?.parentBase) === 2) {
        body.append('parent_update_team_only', params?.parentUpdateTeamOnly ? '1' : '0');
      }
    }

    return this.stdRequest<ReqAdminChallengesInterface>(this.http.post(`${ this.rootApi }/administration/Challenge`, body)).pipe(
      tap(response => {
        // Cast Data
        response.challenges?.forEach(challenge => {
          challenge.main = Utils.resultToBoolean(challenge.main);

          challenge.children?.forEach(child => child.main = Utils.resultToBoolean(child.main));
          challenge.periodicity_archives?.forEach(child => child.main = Utils.resultToBoolean(child.main));
        });
        // Manage next results
        if (next) {
          this.lastChallenges.result.challenges = this.lastChallenges.result.challenges.concat(response.challenges);
        } else {
          this.lastChallenges.result = response;
        }
        if (!params?.idCategory) {
          const count = Utils.toNumber(response.count);
          if (count > 0 || !next) {
            this.nbRootChallenges = count;
          }
        }
      }),
      map(() => this.lastChallenges.result)
    );
  }

  /**
   * Used to ge challenge
   * @param idChallenge id of challenge
   */
  challenge(idChallenge: string): Observable<AdminChallengeInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    return this.stdRequest<ReqAdminChallengesInterface>(this.http.post(`${ this.rootApi }/administration/Challenge`, body)).pipe(
      tap(response => {
        // Cast Data
        response.challenges?.forEach(challenge => {
          challenge.main = Utils.resultToBoolean(challenge.main);

          challenge.children?.forEach(child => child.main = Utils.resultToBoolean(child.main));
          challenge.periodicity_archives?.forEach(child => child.main = Utils.resultToBoolean(child.main));
        });
      }),
      map(response => response?.challenges?.length > 0 ? response.challenges.pop() : null)
    );
  }

  /**
   * Used to delete a challenge.
   * @param idChallenge id of challenge
   */
  delete(idChallenge: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Challenge/delete`, body));
  }

  /**
   * Used to add create challenge
   * @param data
   * @param image
   * @param attachments
   * @param progressEvent event to report progress
   */
  add(data: AdminChallengeData,
      image?: File,
      attachments?: File[],
      progressEvent?: BehaviorSubject<number>): Observable<ReqAdminChallengeAddUpdateInterface> {
    const body = new FormData();
    body.append('challenge', JSON.stringify(data));
    if (image) {
      body.append('image', image);
    }
    if (attachments?.length > 0) {
      attachments.forEach((attachment, index) => body.append(`attachment[${index}]`, attachment));
    }
    return this.uploadRequest<ReqAdminChallengeAddUpdateInterface>(`${ this.rootApi }/administration/Challenge/add`, body, progressEvent);
  }

  /**
   * Used to update challenge
   * @param data
   * @param image
   * @param attachments
   * @param progressEvent event to report progress
   */
  update(data: AdminChallengeData,
         image?: File,
         attachments?: File[],
         progressEvent?: BehaviorSubject<number>): Observable<ReqAdminChallengeAddUpdateInterface> {
    const body = new FormData();
    body.append('id_challenge', data.id_challenge);
    body.append('challenge', JSON.stringify(data));
    if (image) {
      body.append('image', image);
    }
    if (attachments?.length > 0) {
      attachments.forEach((attachment, index) => body.append(`attachment[${index}]`, attachment));
    }
    return this.uploadRequest<ReqAdminChallengeAddUpdateInterface>(`${ this.rootApi }/administration/Challenge/update`, body, progressEvent);
  }

  /**
   * Used to notify enabling of challenge
   * @param idChallenge
   */
  notify(idChallenge: number): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge.toString());
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Challenge/notify`, body));
  }

  /**
   * Used to sort challenges
   * @param list list of new ids
   * @param page page number
   */
  sort(list: Array<string>, page: number = 1) {
    const body = new FormData();
    body.append('page', page.toString());
    body.append('challenges', JSON.stringify(list));

    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Challenge/sort`, body));
  }


  /**
   * Used to unlink child challenge from parent
   * @param idChallenge id of parent challenge
   * @param idChild id of child challenge
   */
  unlink(idChallenge: string, idChild: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    body.append('id_child', idChild);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Challenge/unlink`, body));
  }

  /**
   * Used to get user filters
   */
  userFilters(): Observable<ReqAdminChallengeUserFiltersInterface> {
    return this.stdRequest(
      this.http.post<ReqAdminChallengeUserFiltersInterface>(`${ this.rootApi }/administration/getChallengeUserFilters`, null)
    );
  }

  /**
   * Used to get distributions data
   * @param idChallenge id of challenge
   * @param search structured search field {"filters":{},"string":[]}
   */
  distributions(idChallenge: string, search?: string): Observable<ReqAdminChallengeDistributionsInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    if (search) {
      body.append('search', search);
    }
    return this.stdRequest(
      this.http.post<ReqAdminChallengeDistributionsInterface>(`${ this.rootApi }/administration/Challenge/viewDistribution`, body)
    );
  }

  /**
   * Used to get progressions with proof to validate
   * @param idChallenge
   * @param search
   * @param status
   */
  progressions(idChallenge: string, search?: string, status?: AdminChallengeProofStatusType): Observable<ReqAdminChallengeProgressionsInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    if (search) {
      body.append('search', search);
    }
    if (status && status !== 'all') {
      body.append('status', status);
    }
    return this.stdRequest(
      this.http.post<ReqAdminChallengeProgressionsInterface>(`${ this.rootApi }/administration/Challenge/progressions`, body)
    );
  }

  /**
   * Used to update status of proofs
   * @param idChallenge
   * @param acceptedIds
   * @param refusedIds
   */
  progressionsUpdateStatus(idChallenge: string,
                           acceptedIds?: Array<number>,
                           refusedIds?: Array<number>) {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    if (acceptedIds) {
      body.append('accepted_ids', JSON.stringify(acceptedIds));
    }
    if (refusedIds) {
      body.append('refused_ids', JSON.stringify(refusedIds));
    }
    return this.stdRequest(
      this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Challenge/progressions/update`, body)
    );
  }

  /**
   * Used to get CSV file
   * @param idChallenge id of challenge
   */
  distributionsCsv(idChallenge: string): Observable<StandardExportInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    body.append('get_csv', '1');
    return this.stdRequest(
      this.http.post<StandardExportInterface>(`${ this.rootApi }/administration/Challenge/viewDistribution`, body)
    );
  }

  /**
   * Used to update challenge progression
   * @param idChallenge id of challenge
   * @param params params of request
   */
  updateProgressions(idChallenge: string, params: AdminChallengeUpdateProgressionData): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    body.append('pay_reward', (params?.distributeCoins || params?.distributeCash || params?.distributeGifts || params?.distributeBadges) ? '1' : '0');
    body.append('distribute_coins', params?.distributeCoins ? '1' : '0');
    body.append('distribute_cash', params?.distributeCash ? '1' : '0');
    body.append('distribute_gifts', params?.distributeGifts ? '1' : '0');
    body.append('distribute_badges', params?.distributeBadges ? '1' : '0');
    body.append('overwrite', params?.overwriteProgression ? '1' : '0');
    if (params?.users?.length > 0) {
      body.append('users', JSON.stringify(params?.users));
    }
    if (params?.teams?.length > 0) {
      body.append('teams', JSON.stringify(params?.teams));
    }
    return this.stdRequest(
      this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/updateChallengeProgression`, body)
    );
  }

  /**
   * Used to get CSV for update progressions
   * @param idChallenge id of challenge
   */
  getCsvForUpdate(idChallenge: string): Observable<StandardExportInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    body.append('get_csv', '1');
    return this.stdRequest(
      this.http.post<StandardExportInterface>(`${ this.rootApi }/administration/updateChallengeProgression`, body)
    );
  }

  /**
   * Used to update challenge progression through CSV
   * @param idChallenge id of challenge
   * @param csvFile csv file
   */
  updateProgressionsThroughCsv(idChallenge: string, csvFile: File): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    body.append('csvFile', csvFile);
    return this.stdRequest(
      this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/updateChallengeProgression`, body)
    );
  }

  /**
   * Used to get progression history of challenge
   * @param idChallenge id of challenge
   * @param downloadCsv download CSV ?
   */
  history(idChallenge: string, downloadCsv = false): Observable<ReqAdminChallengeHistoryInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    if (downloadCsv) {
      body.append('get_csv', '1');
    }
    return this.stdRequest(
      this.http.post<ReqAdminChallengeHistoryInterface>(`${ this.rootApi }/administration/Challenge/history`, body)
    );
  }

  /**
   * Used to get challenge categories
   * @param idCategory id of category
   * @param status status to load
   * @param reload reload request
   */
  categories(idCategory?: string, status: ChallengeStatusType = 'all', reload = false): Observable<ReqAdminChallengeCategoriesInterface> {
    if (this.categoriesFromRoot && status === this.categoriesLastStatus && !reload && !idCategory) {
      return of(this.categoriesFromRoot);
    } else {
      const body = new FormData();
      if (idCategory) {
        body.append('id_category', idCategory);
      }
      if (status) {
        this.categoriesLastStatus = status;
        body.append('status', status);
      }
      return this.stdRequest<ReqAdminChallengeCategoriesInterface>(
        this.http.post(`${ this.rootApi }/administration/challengeCategories`, body)
      ).pipe(tap(response => {
        if (!idCategory) {
          this.categoriesFromRoot = response;
          this.nbRootCategories = response?.challengeCategories?.length;
        }
      }));
    }
  }

  /**
   * Used to get category list
   * @param idCategory id of category
   * @param syncReload sync reload
   * @param status status to load
   */
  categoriesList(idCategory?: string, syncReload = false, status: ChallengeStatusType = 'all'): Observable<ChallengeCategoryInterface[]> {

    return this.categories(null, status, syncReload).pipe(
      switchMap(response => {
        // console.log(idCategory, response.challengeCategories);
        if (!idCategory) {
          return of(response.challengeCategories);
        } else {
          return of(this.findChildrenCategoriesInList(response.challengeCategories, idCategory));
        }
      })
    );
  }

  /**
   * Get root category property
   */
  rootCategory(): ChallengeCategoryInterface {
    return {
      children: [],
      filter: '',
      id: null,
      id_action: null,
      id_category: undefined,
      id_parent: null,
      name: this.translateServ.translate('utils.root'),
      nb_challenges: this.nbRootChallenges,
      nb_sub_dir: this.nbRootCategories,
    };
  }

  /**
   * Used to add new category
   * @param data category data object
   */
  addCategory(data: AdminChallengeCategoryData): Observable<StandardResponseInterface> {
    const body = new FormData();
    if (data?.id_category) {
      delete data.id_category;
    }
    body.append('category', JSON.stringify(data));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/challengeCategories/add`, body));
  }

  /**
   * Used to update new category
   * @param idCategory id of categoy
   * @param data category data object
   */
  updateCategory(idCategory: string, data: AdminChallengeCategoryData): Observable<StandardResponseInterface> {
    const body = new FormData();
    data.id_category = idCategory;
    body.append('category', JSON.stringify(data));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/challengeCategories/update`, body));
  }

  /**
   * Used to delete category
   * @param idCategory id of category
   */
  deleteCategory(idCategory: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_category', idCategory);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/challengeCategories/delete`, body));
  }

  /**
   * Used to sort categories of challenge
   * @param list list of new ids
   */
  sortCategories(list: Array<string>) {
    const body = new FormData();
    body.append('categories', JSON.stringify(list));

    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/challengeCategories/sort`, body));
  }

  /**
   * Used to get challenge extras
   * @param reload reload extras ?
   */
  challengeExtras(reload = false): Observable<ChallengeExtraData[]> {
    if (this.extrasState.getValue() === null) {
      return this.extrasState.asObservable().pipe(first(res => !!res));
    } else if (this.extrasState.getValue() && !reload) {
      return of(this.extrasState.getValue());
    } else {
      this.extrasState.next(null);
      return this.stdRequest<ReqAdminChallengeExtrasInterface>(
        this.http.post<ReqAdminChallengeExtrasInterface>(`${ this.rootApi }/administration/ChallengeExtra`, null)
      ).pipe(
        tap(response => this.extrasState.next(response?.challengeExtras)),
        map(response => response.challengeExtras)
      );
    }
  }

  /**
   * Used to add new extra
   * @param data extra data object
   */
  addExtra(data: ChallengeExtraData): Observable<ReqAdminChallengeExtrasInterface> {
    const body = new FormData();
    body.append('extra', JSON.stringify(data));
    return this.stdRequest<ReqAdminChallengeExtrasInterface>(this.http.post<ReqAdminChallengeExtrasInterface>(`${ this.rootApi }/administration/ChallengeExtra/add`, body)).pipe(
      tap(() => this.extrasState.next(undefined))
    );
  }

  /**
   * Used to update etra
   * @param data extra data object
   */
  updateExtra(data: ChallengeExtraData): Observable<ReqAdminChallengeExtrasInterface> {
    const body = new FormData();
    body.append('extra', JSON.stringify(data));
    return this.stdRequest<ReqAdminChallengeExtrasInterface>(this.http.post<ReqAdminChallengeExtrasInterface>(`${ this.rootApi }/administration/ChallengeExtra/update`, body)).pipe(
      tap(() => this.extrasState.next(undefined))
    );
  }

  /**
   * Used to delete extra
   * @param idExtra id of category
   */
  deleteExtra(idExtra: string): Observable<ReqAdminChallengeExtrasInterface> {
    const body = new FormData();
    body.append('id_extra', idExtra);
    return this.stdRequest<ReqAdminChallengeExtrasInterface>(this.http.post<ReqAdminChallengeExtrasInterface>(`${ this.rootApi }/administration/ChallengeExtra/delete`, body)).pipe(
      tap(() => this.extrasState.next(undefined))
    );
  }

  /**
   * Invalidate extras for next call
   */
  invalidateExtras() {
    this.extrasState.next(undefined);
  }

  /**
   * Used to set challenge success level
   * @param idChallenge if of challenge
   * @param successLevel selected level
   */
  setSuccessLevel(idChallenge: string, successLevel: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    body.append('success_level', successLevel);
    return this.stdRequest(this.http.post<ReqAdminChallengeExtrasInterface>(`${ this.rootApi }/administration/Stats/Performances/setSuccessLevel`, body));
  }

  /**
   * Used to set update challenge extra
   * @param idChallenge if of challenge
   * @param extras selected extras
   */
  updateChallengeExtra(idChallenge: string, extras: ChallengeExtraData[]): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge);
    body.append('extras', JSON.stringify(extras));
    return this.stdRequest(this.http.post<ReqAdminChallengeExtrasInterface>(`${ this.rootApi }/administration/ChallengeExtra/updateChallengeDatas`, body));
  }

  /**
   * Build breadcrumbs according current categories list and id
   * @param idCategory id of category
   */
  buildBreadcrumbs(idCategory?: string): ChallengeCategoryInterface[] {
    let breadcrumbs = [];

    if (idCategory && idCategory !== '0') {
      const temp = this.findCategoriesBreadcrumbs(idCategory, this.categoriesFromRoot?.challengeCategories);

      if (temp) {
        breadcrumbs = breadcrumbs.concat(temp);
      }
    }

    return breadcrumbs;
  }

  /**
   * Is category drag
   */
  clearDrag() {
    this.isChallengeDrag = false;
    this.isCategoryDrag = false;
  }

  /**
   * Find categories breadcrumbs
   * @param idCategory id of categories
   * @param categories categories object
   * @private
   */
  private findCategoriesBreadcrumbs(idCategory: string, categories: ChallengeCategoryInterface[]): ChallengeCategoryInterface[] {
    let temp = [];
    let found = false;
    categories?.forEach(category => {
      if (!found) {
        if (category.id_category === idCategory) {
          temp.push(category);
          found = true;
        } else if (category.children?.length > 0) {
          const result = this.findCategoriesBreadcrumbs(idCategory, category.children);
          if (result?.length > 0) {
            found = true;
            temp.push(category);
            temp = temp.concat(result);
          }
        }
      }
    });
    return found ? temp : null;
  }

  /**
   * Find children of id category in categories list
   * @param categories
   * @param idCategory
   * @private
   */
  private findChildrenCategoriesInList(categories: ChallengeCategoryInterface[], idCategory: string): ChallengeCategoryInterface[] {
    if (!categories) {
      return [];
    }
    for (const category of categories) {
      if (Utils.toNumber(category.id_category) === Utils.toNumber(idCategory)) {
        return category.children;
      } else if (category.children?.length > 0) {
        const results = this.findChildrenCategoriesInList(category.children, idCategory);
        if (results?.length > 0) {
          return results;
        }
      }
    }
    return [];
  }

}

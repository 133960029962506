import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@common/helpers/auth.guard';
import { DashboardGuard } from '@common/helpers/dashboard.guard';

const routes: Routes = [
  {
    path: 'feed',
    canActivate: [AuthGuard],
    loadChildren: () => import('./feed/feed.module').then(m => m.FeedPageModule)
  },
  {
    path: 'challenge',
    canActivate: [AuthGuard],
    loadChildren: () => import('./challenges/challenges.module').then(m => m.ChallengesPageModule)
  },
  {
    path: 'challenges',
    canActivate: [AuthGuard],
    loadChildren: () => import('./challenges/challenges.module').then(m => m.ChallengesPageModule)
  },
  {
    path: 'library', // --roadbox--
    canActivate: [AuthGuard],
    loadChildren: () => import('./roadbox/roadbox.module').then(m => m.RoadBoxPageModule)
  },
  {
    path: 'form',
    canActivate: [AuthGuard],
    loadChildren: () => import('./forms/forms.module').then(m => m.FormsPageModule)
  },
  {
    path: 'forms',
    canActivate: [AuthGuard],
    loadChildren: () => import('./forms/forms.module').then(m => m.FormsPageModule)
  },
  {
    path: 'quiz',
    canActivate: [AuthGuard],
    loadChildren: () => import('./quizzes/quizzes.module').then(m => m.QuizzesPageModule)
  },
  {
    path: 'quizzes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./quizzes/quizzes.module').then(m => m.QuizzesPageModule)
  },
  {
    path: 'e-store',
    canActivate: [AuthGuard],
    loadChildren: () => import('./e-store/e-store.module').then(m => m.EStorePageModule)
  },
  {
    path: 'rewards',
    canActivate: [AuthGuard],
    loadChildren: () => import('./rewards/rewards.module').then(m => m.RewardsPageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'messages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./messages/messages.module').then(m => m.MessagesPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, DashboardGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'test',
    canActivate: [AuthGuard],
    loadChildren: () => import('./test/test.module').then(m => m.TestPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./security/security-routing.module').then(m => m.SecurityRoutingModule)
  },
  {
    path: 'oAuth2',
    data: {context: 'oauth'},
    loadChildren: () => import('./security/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'signup',
    data: {context: 'signup'},
    loadChildren: () => import('./security/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'signin',
    data: {context: 'signin'},
    loadChildren: () => import('./security/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'signup-continue',
    data: {context: 'change-password'},
    loadChildren: () => import('./security/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'password-reset',
    data: {context: 'reset-password'},
    loadChildren: () => import('./security/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'set-password',
    data: {context: 'set-password'},
    loadChildren: () => import('./security/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./security/maintenance/maintenance.module').then(m => m.MaintenancePageModule)
  },
  {
    path: 'update',
    loadChildren: () => import('./security/update/update.module').then(m => m.UpdatePageModule)
  },
  {
    path: 'download-app',
    loadChildren: () => import('./security/download-app/download-app.page.module').then( m => m.DownloadAppPageModule)
  },
  {
    path: '**',
    redirectTo: 'feed',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      enableTracing: false,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class NameValidator {

  /**
   * Validate name is correctly defined
   */
  static isValid: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!(/^[^0-9!<>,;?=+()@#"Â°{}_$%:]*$/gi.test(control?.value || ''))) {
      return {'not-valid': true};
    }

    return null;
  }

}

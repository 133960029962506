import { Component, Input } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { ToolboxService } from '@common/services/toolbox.service';

@Component({
  selector: 'app-copy-input',
  templateUrl: './copy-input.component.html',
  styleUrls: ['./copy-input.component.scss'],
})
export class CopyInputComponent {

  @Input() value: string;

  @Input() multiline: boolean;

  constructor(private toolboxServ: ToolboxService) { }

  /**
   * Copy value to clipboard
   */
  copyValue() {
    Clipboard.write({
      string: this.value
    }).then(_ => {
      this.toolboxServ.flash({
        messageKey: 'settings.copy-success'
      }).then(/* Nothing to do */);
    });
  }
}

import { UserProfileInterface } from '@common/interfaces/common.interface';
import { arrayUniqueValues, Utils } from '@common/helpers/utils';
import { UserLegacyPublicInterface } from '@common/interfaces/connection.interface';
import { ConfigFieldInterface, StandardConfigFieldsInterface, UserOptionsInterface } from '@common/interfaces/api/client';
import { FilteredFieldsInterface } from '@common/components/app/profile-fields';

export const profileFields = ['email', 'firstname', 'lastname', 'nickname', 'birthday', 'profile_bg', 'profile_pic'];

export const toUserProfile = (user: UserProfileInterface): UserProfileInterface => {
  return {
    id: Utils.toNumber(user.id),
    id_customer: Utils.toNumber(user.id_customer),
    firstname: user.firstname,
    lastname: user.lastname,
    profile_pic: user.profile_pic,

    email: user.email,
    profile_bg: user.profile_bg,
    birthday: user.birthday,
    prefered_language: user.prefered_language,
    id_country_customer: user.id_country_customer,

    signatureTJS: user.signatureTJS,
    tfa_enabled: user.tfa_enabled
  };
};

export const fromLegacyToUserProfile = (user: UserLegacyPublicInterface): UserProfileInterface => {
  return {
    id: Utils.toNumber(user.id),
    id_customer: Utils.toNumber(user.id_customer),
    firstname: user.firstname,
    lastname: user.lastname,
    profile_pic: user.profile_pic,
    profile_bg: user.profile_bg,
    email: user.email,
    birthday: user.birthday,
    prefered_language: user.prefered_language,
    id_country_customer: user.id_country_customer,
    signatureTJS: user.signatureTJS,
    tfa_enabled: user.tfa_enabled,
    nb_teams: Utils.toNumber(user.nb_teams || 0)
  };
};

export const toUserOptions = (user: UserLegacyPublicInterface): UserOptionsInterface => {
  return {
    allow_display_profile: !!user.allow_display_profile,
    allow_notifs_mail: !!user.allow_notifs_mail,
    allow_notifs_push: !!user.allow_notifs_push,
    allow_notifs_sound: !!user.allow_notifs_sound,
    optin: !!user.optin,
    optin_medias: !!user.optin_medias,
    prefered_language: user.prefered_language,
    tfa_enabled: !!user.tfa_enabled
  };
};

export const hasMandatoryFieldsNotSet = (fields: StandardConfigFieldsInterface): boolean => {
  return !((
    (fields?.basic_config || [])
      .filter(field => field.mandatory && field?.edit)
      .every(field => (field.value?.length || 0) > 0)
  ) && (
    (fields?.custom_config || [])
      .filter(field => field.mandatory && field?.edit)
      .every(field => (field.value?.length || 0) > 0)
  ));
};

export const hasMandatoryListFieldsWithBadValues = (fields: StandardConfigFieldsInterface): boolean => {
  return !((
    (fields?.basic_config || [])
      .filter(field => field.mandatory && field.edit && field.data_type === 'list')
      .every(field => field.values.indexOf(field.value) > -1)
  ) && (
    (fields?.custom_config || [])
      .filter(field => field.mandatory && field.edit && field.data_type === 'list')
      .every(field => field.values.indexOf(field.value) > -1)
  ));
};

export const hasProvisioningFieldsNotSet = (fields: StandardConfigFieldsInterface, provisioningFields: ConfigFieldInterface[]): boolean => {
  let result = false;

  provisioningFields.forEach(provisioningField => {
    const field = []
      .concat(fields?.basic_config || [], fields?.custom_config || [])
      .find(item => item.code === provisioningField.code);
    if (field && (field.value?.length || 0) === 0 && field.edit) {
      result = true;
    }
  });

  return result;
};

/**
 * Used to build filtered fields according mandatory fields and provisioning fields
 * @param fields fields to filter
 * @param provisioningFields provisioning fields
 */
export const buildFilteredFieldsCode = (fields: StandardConfigFieldsInterface, provisioningFields?: ConfigFieldInterface[]): FilteredFieldsInterface => {
  const mandatoryBasicFields = fields?.basic_config
    ?.filter(field => field.mandatory && (((field.value?.length || 0) === 0) || (field.data_type === 'list' && field.values?.indexOf(field.value) === -1)))
    .map(field => field.code) || [];
  const mandatoryCustomFields = fields?.custom_config
    ?.filter(field => field.mandatory && (((field.value?.length || 0) === 0) || (field.data_type === 'list' && field.values?.indexOf(field.value) === -1)))
    .map(field => field.code) || [];
  const provisioningFieldsCode = provisioningFields?.map(field => field.code) || [];
  const provisioningBasicFields = fields?.basic_config?.filter(field => provisioningFieldsCode.includes(field.code)).map(field => field.code) || [];
  const provisioningCustomFields = fields?.custom_config?.filter(field => provisioningFieldsCode.includes(field.code)).map(field => field.code) || [];
  return {
    basic_fields: arrayUniqueValues([...mandatoryBasicFields, ...provisioningBasicFields]),
    custom_fields: arrayUniqueValues([...mandatoryCustomFields, ...provisioningCustomFields])
  };
};

/**
 * Used to build strict filtered fields according input fields
 * @param fields fields to filter
 * @param filteredFields fields to keep
 */
export const buildStrictFilteredFieldsCode = (fields: StandardConfigFieldsInterface, filteredFields?: ConfigFieldInterface[]): FilteredFieldsInterface => {
  const basicFields = fields?.basic_config?.map(field => field.code) || [];
  const customFields = fields?.custom_config?.map(field => field.code) || [];
  const filteredFieldsCode = filteredFields?.map(field => field.code) || [];
  return {
    basic_fields: basicFields.filter(field => filteredFieldsCode.includes(field)),
    custom_fields: customFields.filter(field => filteredFieldsCode.includes(field))
  };
};


import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@common/services/common.service';
import { ResponsiveService } from '@common/services/responsive.service';
import { Subscription } from 'rxjs';
import {
  AccessControlService,
  PermissionsForDashboardInterface
} from '@common/services/access-control';
import { NavController, PopoverController } from '@ionic/angular';
import { NotificationsPopover } from '../notifications/notifications.popover';
import { Router } from '@angular/router';
import { TalkService } from '@common/components/app/talk';
import { NotificationsService } from '@common/services/notifications';
import { OnboardingService } from '@common/services/onboarding.service';

@Component({
  selector: 'app-side-options',
  templateUrl: './side-options.component.html',
  styleUrls: ['./side-options.component.scss']
})
export class AppSideOptionsComponent implements OnInit, OnDestroy {

  notificationsCount = 0;
  messagesCount = 0;

  subscriptions: Subscription[] = [];

  permissions: PermissionsForDashboardInterface;

  canCreateItemsInDashboard = false;
  hasDashboardEvents = false;

  isTalkEnabled = false;
  canDisplayVideos = false;

  canAccessDashboard = false;

  dashboardMainRoute = '/';

  constructor(public commonServ: CommonService,
              public responsiveServ: ResponsiveService,
              protected popoverCtrl: PopoverController,
              protected navCtrl: NavController,
              protected accessServ: AccessControlService,
              protected notificationsServ: NotificationsService,
              protected talkServ: TalkService,
              protected onboardingServ: OnboardingService,
              protected router: Router) {
  }

  ngOnInit() {
    this.subscriptions.push(this.accessServ.permissionsForDashboardState().subscribe(permissions => this.buildPermissions(permissions)));
    this.subscriptions.push(this.notificationsServ.getInAppNotificationsCountState().subscribe(val => this.notificationsCount = val));
    this.subscriptions.push(this.notificationsServ.eventsState(true).subscribe(events => this.hasDashboardEvents = events.accessFlag));
    this.subscriptions.push(this.talkServ.getUnreadMessagesCountState().subscribe(val => this.messagesCount = val));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  buildPermissions(permissions: PermissionsForDashboardInterface) {
    this.permissions = permissions;

    this.canCreateItemsInDashboard = (permissions.isChallengesEnabled && !!permissions.permissions.Challenge?.add) ||
      (permissions.isRoadboxEnabled && !!permissions.permissions.Gallery?.add) ||
      (permissions.isQuizEnabled && !!permissions.permissions.Quizz?.add) ||
      (permissions.isSurveysEnabled && !!permissions.permissions.Sondage?.add) ||
      (permissions.isFormsEnabled && !!permissions.permissions.Form?.add);

    this.canAccessDashboard = this.commonServ.canAccessDashboard();
    this.dashboardMainRoute = this.accessServ.getDashboardMainRoute();

    this.isTalkEnabled = this.accessServ.permissionsForClient().isTalkEnabled;
    this.canDisplayVideos = this.onboardingServ.isOfferForOnboarding() && this.permissions.platform?.is_admin;

    this.superviseDashboardAccess();
  }

  /**
   * Open notifications
   * @param event click event
   */
  async openNotifications(event) {
    if (this.responsiveServ.isMobile) {
      this.navCtrl.navigateForward('/notifications')?.then(/* Nothing to do */);
    } else {
      const popover = await this.popoverCtrl.create({
        component: NotificationsPopover,
        cssClass: 'notifications-popover',
        event,
        translucent: true,
        showBackdrop: false
      });
      return await popover.present();
    }
  }

  openFunctionality(functionality) {
    this.onboardingServ.introVideo(functionality);
  }

  /**
   * Supervise Dashboard menu
   * Got to home if not admin access
   */
  superviseDashboardAccess() {
    if (!this.commonServ.canAccessDashboard() && this.router.url?.indexOf('/dashboard') > -1) {
      this.navCtrl.navigateRoot(['/'])?.then(/* Nothing to do */);
    }
  }

}

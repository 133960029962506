import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class TreeItemNode {
  children?: TreeItemNode[];
  name: string;
  id: string;
  extraData?: any;
  imageSrc?: string;
  icon?: string;

  isSelected?: boolean;
}

@Injectable()
export class TreeDatabase {
  dataChange = new BehaviorSubject<TreeItemNode[]>([]);

  get data(): TreeItemNode[] { return this.dataChange.value; }

  /**
   * Set items
   */
  setItems(items: TreeItemNode[]) {
    this.dataChange.next(items);
  }

  /** Add an item to tree list */
  insertItem(item: TreeItemNode, parent?: TreeItemNode) {
    if (parent.children) {
      parent.children.push(item);
      this.dataChange.next(this.data);
    } else {
      const data = this.data;
      data.push(item);
      this.dataChange.next(data);
    }
  }

  updateItem(node: TreeItemNode, name: string) {
    node.name = name;
    this.dataChange.next(this.data);
  }
}

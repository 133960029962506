import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SheetModalController } from '@common/services/sheet-modal.controller';
import { params } from '@environments/params';
import { CommonService } from '@common/services/common.service';

@Component({
  selector: 'app-assistance-choice-modal',
  templateUrl: './assistance-choice.modal.html',
  styleUrls: ['./assistance-choice.modal.scss'],
})
export class AssistanceChoiceModal implements OnInit {

  @Input() contactFn: (callback) => void;

  isAdmin = false;

  constructor(private modalCtrl: SheetModalController,
              private commonServ: CommonService) { }

  ngOnInit() {
    this.isAdmin = this.commonServ.getCurrentPlatform()?.is_admin || false;
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel').then(/* Nothing to do */);
  }

  userHelpAction() {
    this.commonServ.openUrl(params.links.user_help_link);
  }

  adminHelpAction() {
    this.commonServ.openUrl(params.links.admin_help_link);
  }

  async contactAction() {
    if (this.contactFn) {
      this.contactFn((data) => {
        this.modalCtrl.dismiss(data, 'apply').then(/* Nothing to do */);
      });
    }
  }

}

import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  AdminSurveyData,
  ReqAdminSurveyInterface,
  ReqAdminSurveyResultsInterface,
  ReqAdminSurveysInterface
} from '@common/interfaces/api/admin';
import { switchMap } from 'rxjs/operators';
import { StandardExportInterface, StandardResponseInterface } from '@common/interfaces/api';

@Injectable()
export class AdminSurveysService extends HttpBaseService {

  /**
   * Used to get the list of all platform survey.
   */
  surveys(): Observable<ReqAdminSurveysInterface> {
    return this.stdRequest<ReqAdminSurveysInterface>(
      this.http.post<ReqAdminSurveysInterface>(`${ this.rootApi }/administration/Sondage`, null)
    ).pipe(switchMap(response => {
      response.surveys = [].concat(
        response?.sondages?.coming,
        response?.sondages?.in_progress,
        response?.sondages?.finished
      );
      return of(response);
    }));
  }

  /**
   * Used to get a survey.
   * @param idSurvey id of survey
   */
  survey(idSurvey: number): Observable<ReqAdminSurveyInterface> {
    const body = new FormData();
    body.append('id_sondage', idSurvey.toString());
    return this.stdRequest(this.http.post<ReqAdminSurveyInterface>(`${ this.rootApi }/administration/Sondage`, body));
  }

  /**
   * Used to create a new survey.
   * @param data data object
   * @param picture picture to add
   * @param progressEvent event to report progress
   */
  add(data: AdminSurveyData,
      picture?: File,
      progressEvent?: BehaviorSubject<number>): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('sondage', JSON.stringify(data));
    if (picture) {
      body.append('image', picture);
    }
    return this.uploadRequest<StandardResponseInterface>(`${ this.rootApi }/administration/Sondage/add`, body, progressEvent);
  }

  /**
   * Used to update a new survey.
   * @param idSurvey id of survey
   * @param data data object
   * @param picture picture to add
   * @param progressEvent event to report progress
   */
  update(idSurvey: number,
         data: AdminSurveyData,
         picture?: File,
         progressEvent?: BehaviorSubject<number>): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_sondage', idSurvey.toString());
    body.append('sondage', JSON.stringify(data));
    if (picture) {
      body.append('image', picture);
    }
    return this.uploadRequest<StandardResponseInterface>(`${ this.rootApi }/administration/Sondage/update`, body, progressEvent);
  }

  /**
   * Used to delete a survey.
   * @param idSurvey id of survey
   */
  delete(idSurvey: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_sondage', idSurvey);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Sondage/delete`, body));
  }

  /**
   * Used to get results of a survey.
   * @param idSurvey if of survey
   */
  results(idSurvey: string): Observable<ReqAdminSurveyResultsInterface> {
    const body = new FormData();
    body.append('id_sondage', idSurvey);
    return this.stdRequest(this.http.post<ReqAdminSurveyResultsInterface>(`${ this.rootApi }/administration/Sondage/getResults`, body));
  }

  /**
   * Used to export results of a survey.
   * @param idSurvey id of forms
   */
  exportResults(idSurvey: string): Observable<StandardExportInterface> {
    const body = new FormData();
    body.append('id_sondage', idSurvey);
    body.append('get_csv', '1');
    return this.stdRequest(this.http.post<StandardExportInterface>(`${ this.rootApi }/administration/Sondage/getResults`, body));
  }

  /**
   * Used t delete result of survey
   * TODO: To implement in back and Front (id of result is needed)
   * @param idSurvey id of survey
   * @param idAnswer id of answer
   */
  deleteResults(idSurvey: string, idAnswer: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_sondage', idSurvey);
    body.append('id_answer', idAnswer);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Sondage/deleteResult`, body));
  }

}

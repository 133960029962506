import Talk from 'talkjs';
import { Injectable } from '@angular/core';
import { TalkDeferred } from '@common/components/app/talk/talk.deferred';
import { environment } from '@environments/environment';
import { Utils } from '@common/helpers/utils';
import { PlatformInterface } from '@common/interfaces/api';
import { UserProfileInterface } from '@common/interfaces/common.interface';

export type TalkThemeType = 'DarkTheme' | 'LightTheme';

/**
 * Service for interfacing TalkJS SDK
 */
@Injectable({providedIn: 'root'})
export class TalkSdkService {
  private config = environment.talkJs;

  private platform: PlatformInterface;
  private locale = 'en';
  private theme: TalkThemeType = 'LightTheme';
  private currentUser: UserProfileInterface;

  private currentTalkUser: Talk.User;
  private currentSessionDeferred: TalkDeferred<Talk.Session> = new TalkDeferred<Talk.Session>();

  private isInitialized = false;

  /**
   * Initialize session for user
   * @param user connected user
   */
  async initCurrentSession(user: UserProfileInterface) {
    await Talk.ready;

    this.currentUser = user;

    // If has already talk user created, start new deferred session
    if (this.currentTalkUser) {
      this.currentSessionDeferred = new TalkDeferred<Talk.Session>();
    }
    const currentTalkUser = await this.createTalkUser(user);

    const session = new Talk.Session({
      appId: this.config.appId,
      me: currentTalkUser,
      signature: user.signatureTJS
    });

    this.currentTalkUser = currentTalkUser;

    this.currentSessionDeferred.resolve(session);

    this.isInitialized = true;

    return session;
  }

  async reloadSession(user: UserProfileInterface) {
    if (!this.isInitialized) {
      return await false;
    }
    return await this.initCurrentSession(user);
  }

  setPlatform(platform: PlatformInterface) {
    this.platform = platform;
  }

  setTheme(theme: TalkThemeType) {
    this.theme = theme;
  }

  setLocale(locale: string) {
    if (locale !== this.locale) {
      this.locale = locale;
      this.reloadSession(this.currentUser).then(/* Nothing to do */);
    }
  }

  /*******************
   * SDK Interfacing
   ******************/

  /**
   * Create chatbox with other user
   * @param otherApplicationUsers other users profile
   * @param subject subject of conversation
   */
  async createChatbox(otherApplicationUsers?: UserProfileInterface[], subject?: string): Promise<Talk.Chatbox> {
    const session = await this.currentSessionDeferred.promise;
    const chatbox = session.createChatbox({
      theme: this.theme
    });

    if (otherApplicationUsers?.length > 0) {
      const conversationBuilder = await this.getOrCreateConversation(session, otherApplicationUsers);
      if (subject) {
        conversationBuilder.setAttributes({subject});
      }
      chatbox.select(conversationBuilder);
    }

    return chatbox;
  }

  /**
   * Create inbox
   */
  async createInbox(): Promise<Talk.Inbox> {
    const session = await this.currentSessionDeferred.promise;

    return session.createInbox({
      theme: this.theme
    });
  }

  /**
   * Get conversation of other application user
   * @param otherApplicationUsers
   */
  async getConversation(otherApplicationUsers?: UserProfileInterface[]): Promise<Talk.ConversationBuilder> {
    const session = await this.currentSessionDeferred.promise;
    if (otherApplicationUsers?.length > 0) {
      return await this.getOrCreateConversation(session, otherApplicationUsers);
    }
    return null;
  }

  /**
   * Return current session
   */
  async getSession(): Promise<Talk.Session> {
    return await this.currentSessionDeferred.promise;
  }

  /**
   * Create new user
   * @param applicationUser User profile
   */
  async createTalkUser(applicationUser: UserProfileInterface): Promise<Talk.User> {
    await Talk.ready;

    const data: any = {
      id: this.createUserId(applicationUser.id_customer),
      name: applicationUser.firstname + ' ' + applicationUser.lastname,
      photoUrl: applicationUser.profile_pic,
      role: applicationUser.email?.indexOf('roadoo.com') > -1 ? 'roadoo' : (!!this.platform.is_admin ? 'admin' : 'default'),
      custom: {
        id_action: Utils.toString(this.platform.id_action),
        id_customer: Utils.toString(applicationUser.id_customer)
      }
    };

    if (this.currentUser?.id === applicationUser?.id) {
      data.locale = this.locale;
    }

    return new Talk.User(data);
  }

  /**
   * Create Talk User ID
   * @param applicationId id of customer
   */
  createUserId(applicationId: string | number) {
    return Utils.toString(applicationId) + '-' + environment.talkJs.env;
  }

  /**
   * Get or create conversation with user
   * @param session session
   * @param otherApplicationUsers other application users
   * @private
   */
  private async getOrCreateConversation(session: Talk.Session, otherApplicationUsers: UserProfileInterface[]) {
    let conversationBuilder = null;

    if (otherApplicationUsers?.length === 1) {
      // Only one user
      const otherApplicationUser = otherApplicationUsers[0];
      const otherTalkUser = await this.createTalkUser(otherApplicationUser);

      conversationBuilder = session.getOrCreateConversation(Talk.oneOnOneId(this.currentTalkUser, otherTalkUser));
      conversationBuilder.setParticipant(this.currentTalkUser);

      conversationBuilder.setParticipant(otherTalkUser);

      conversationBuilder.setAttributes({
        custom: {
          id_action: Utils.toString(this.platform.id_action),
        }
      });

      return conversationBuilder;
    } else if (otherApplicationUsers?.length > 1) {
      // More than 1 user

      // Build conversation id
      const ids = otherApplicationUsers.map(item => Utils.toNumber(item.id_customer))
        .sort((a, b) => {
          return a - b;
        });
      const encodedIds = JSON.stringify(ids);
      const hash = Utils.SHA1(encodedIds).slice(0, 20);

      conversationBuilder = session.getOrCreateConversation(hash);
      conversationBuilder.setParticipant(this.currentTalkUser);

      await Promise.all(otherApplicationUsers.map(async otherApplicationUser => {
        const otherTalkUser = await this.createTalkUser(otherApplicationUser);
        conversationBuilder.setParticipant(otherTalkUser);
      }));
    }

    conversationBuilder?.setAttributes({
      custom: {
        id_action: Utils.toString(this.platform.id_action),
      }
    });

    return conversationBuilder;
  }
}

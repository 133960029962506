import { Component, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@common/helpers/utils';
import { IncrementInputArrowsType, IncrementInputSizeType } from '@common/components/forms/increment-input/increment-input.interface';

@Component({
  selector: 'app-increment-input',
  templateUrl: './increment-input.component.html',
  styleUrls: ['./increment-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncrementInputComponent),
      multi: true,
    }
  ],
})
export class IncrementInputComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() value: string;

  @Input() min: number = null;
  @Input() max: number = null;

  @Input() size: IncrementInputSizeType = 'normal';
  @Input() editable = true;
  @Input() arrows: IncrementInputArrowsType = 'visible';
  @Input() arrowsBreakpoint = 0;

  @Output() minEv: EventEmitter<number> = new EventEmitter<number>();
  @Output() maxEv: EventEmitter<number> = new EventEmitter<number>();

  isArrowsVisible = false;

  private lastValue: string;

  public onChange = (_: any) => {};
  public onTouch = () => {};

  constructor() { }

  @HostListener('window:resize')
  ngOnInit() {
    this.isArrowsVisible = this.arrows === 'visible' && (this.arrowsBreakpoint === 0 || window.innerWidth < this.arrowsBreakpoint);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && this.value) {
      this.lastValue = this.value;
    }
  }

  updateValue() {
    this.onChange(this.value);
  }

  increment(inc: number) {
    let value = Utils.toNumber(this.value);
    value += inc;
    if (this.min !== null && value < this.min) {
      value = this.min;
      this.minEv.emit(this.min);
    }
    if (this.max !== null && value > this.max) {
      value = this.max;
      this.maxEv.emit(this.max);
    }
    this.value = value.toString();

    if (this.lastValue !== this.value) {
      this.updateValue();
      this.lastValue = this.value;
    }
  }

  /**
   * ControlValueAccessor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {
    this.value = inputValue;
    this.lastValue = Utils.toString(this.value);
    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}

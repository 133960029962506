import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessControlService, PermissionsForClientInterface } from '@common/services/access-control';
import { Subscription } from 'rxjs';
import { ConnectionService } from '@common/services/connection.service';
import { Platform } from '@ionic/angular';
import { RouterDirection } from '@ionic/core';
import { NotificationsService } from '@common/services/notifications';

interface MenuItem {
  iconActive: string;
  icon: string;
  url: string;
  active: () => boolean;
  badge?: () => number | boolean;
  nameKey: string;
  routerDir: RouterDirection;
}

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class AppMainMenuComponent implements OnInit, OnDestroy {

  @Input() position: 'header' | 'footer' = 'header';

  menus: MenuItem[];
  notificationsCount = 0;

  isIos = false;

  feedFlag = false;
  challengesFlag = false;

  private subscriptions: Subscription[] = [];

  constructor(protected router: Router,
              protected accessServ: AccessControlService,
              protected ionicPlatform: Platform,
              protected connectionServ: ConnectionService,
              protected notificationsServ: NotificationsService) { }

  ngOnInit() {
    this.isIos = this.ionicPlatform.is('ios');

    if (this.position === 'footer') {
      this.subscriptions.push(this.notificationsServ.getInAppNotificationsCountState().subscribe(val => this.notificationsCount = val));
    }

    // Manage badge flags
    this.subscriptions.push(this.notificationsServ.eventsState().subscribe(events => {
      this.feedFlag = events.feedFlag;
      this.challengesFlag = events.challengesFlag;
    }));

    this.subscriptions.push(this.accessServ.permissionsForClientState().subscribe(permissions => {
      this.buildMenu(permissions);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  buildMenu(permissions: PermissionsForClientInterface) {
    let nbEnabled = 0;
    if (permissions.isFeedEnabled) {
      nbEnabled++;
    }
    if (permissions.isChallengesEnabled) {
      nbEnabled++;
    }
    if (permissions.isStoreEnabled) {
      nbEnabled++;
    }

    this.menus = [];
    // For desktop, shall have at least 2 pages
    if ((nbEnabled > 1) || (this.position === 'footer')) {

    if (permissions.isFeedEnabled) {
      this.menus.push({
        iconActive: 'reader',
        icon: 'reader-outline',
        url: '/feed',
        active: () => {
          return this.routeIsEnabled('/feed') || this.routeIsEnabled('/', true);
        },
        nameKey: 'menu.home',
        routerDir: 'root',
        badge: () => this.feedFlag
      });
    }

    if (permissions.isChallengesEnabled) {
      this.menus.push({
        iconActive: 'podium',
        icon: 'podium-outline',
        url: '/challenges',
        active: () => {
          return this.routeIsEnabled('/challenge');
        },
        nameKey: 'menu.challenge',
        routerDir: 'root',
        badge: () => this.challengesFlag
      });
    }

    if (permissions.isStoreEnabled) {
      this.menus.push({
        iconActive: 'storefront',
        icon: 'storefront-outline',
        url: '/e-store',
        active: () => {
          return this.routeIsEnabled('/e-store');
        },
        nameKey: 'menu.shop',
        routerDir: 'root'
      });
    }
    }

    if (this.position === 'footer') {
      this.menus.push({
        iconActive: 'notifications',
        icon: 'notifications-outline',
        url: '/notifications',
        active: () => {
          return this.routeIsEnabled('/notifications');
        },
        badge: () => this.notificationsCount,
        nameKey: 'settings.notifications',
        routerDir: 'forward'
      });
    }
  }


  /**
   * Check if route is enabled
   * @param route route to check
   * @param strict if cehckt shall be strict
   */
  routeIsEnabled(route: string, strict = false) {
    if (strict) {
      return this.router.url === route;
    }
    return this.router.url.startsWith(route);
  }

}

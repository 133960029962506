import { Injectable } from '@angular/core';
import { AnimationController, ModalController } from '@ionic/angular';
import { LoadingModal } from '@common/components/utils/loading/loading.modal';
import { Observable, Subject } from 'rxjs';

export interface LoadingProps {
  message?: string;
  progressEvent?: Observable<number>;
}

@Injectable({
  providedIn: 'root'
})
export class LoadingController {

  private modal?: HTMLIonModalElement;

  private message$: Subject<string> = new Subject();

  constructor(private modalCtrl: ModalController,
              private animationCtrl: AnimationController) {
  }

  show(props: LoadingProps): Promise<HTMLIonModalElement | undefined> {
    return new Promise<HTMLIonModalElement | undefined>(resolve => {
      this.modalCtrl.create({
        component: LoadingModal,
        componentProps: {progressEvent: props.progressEvent, message: this.message$.asObservable()},
        cssClass: 'overlay-modal loading-modal',
        keyboardClose: false,
        backdropDismiss: false,
        showBackdrop: true,
        enterAnimation: (baseEl) => {
          return this.animationCtrl.create()
            .addElement(baseEl?.shadowRoot.querySelector('.modal-wrapper'))
            .duration(200)
            .beforeStyles({
              opacity: 0
            })
            .fromTo('transform', 'scale(0.2)', 'scale(1)')
            .fromTo('opacity', '0', '1');
        },
        leaveAnimation: (baseEl) => {
          return this.animationCtrl.create()
            .addElement(baseEl?.shadowRoot.querySelector('.modal-wrapper'))
            .duration(200)
            .beforeStyles({
              opacity: 1
            })
            .fromTo('transform', 'scale(1)', 'scale(0.2)')
            .fromTo('opacity', '1', '0');
        }
      }).then(modal => {
        this.modal = modal;

        this.modal.present().then(() => {
          this.message$.next(props.message || '');

          resolve(this.modal);
        });
      });
    });
  }

  close(): Promise<void> {
    return new Promise(resolve => {
      if (this.modal) {
        this.modal.dismiss().then(() => {
          this.modal = undefined;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  isPresent() {
    return !!this.modal;
  }

  setMessage(message: string) {
    if (this.modal && this.modal.componentProps) {
      this.message$.next(message);
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHeaderComponent } from './header.component';
import { IonicModule } from '@ionic/angular';
import { AppMainMenuComponent } from './main-menu/main-menu.component';
import { AppSideOptionsComponent } from './side-options/side-options.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NotificationsPopover } from './notifications/notifications.popover';
import { PipesModule } from '@common/pipes';
import { AppHeaderLogoComponent } from './logo/logo.component';
import { DirectivesModule } from '@common/directives/directives.module';
import { ClientMenuButtonComponent } from '@common/components/app/header/menu-button/menu-button.component';
import { ImgBrandModule } from '@common/components/images/img-brand/img-brand.module';
import { IconCircleModule } from '@common/components/images';


@NgModule({
  declarations: [
    AppHeaderComponent,
    AppMainMenuComponent,
    AppSideOptionsComponent,
    AppHeaderLogoComponent,
    ClientMenuButtonComponent,
    NotificationsPopover
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    MatTooltipModule,
    FormsModule,
    TranslocoModule,
    PipesModule,
    DirectivesModule,
    ImgBrandModule,
    IconCircleModule
  ],
  exports: [
    AppHeaderComponent,
    AppMainMenuComponent,
    AppSideOptionsComponent,
    AppHeaderLogoComponent,
    ClientMenuButtonComponent,
    NotificationsPopover
  ]
})
export class AppHeaderModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComputeInputComponent } from './compute-input.component';
import { FormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { IonicModule } from '@ionic/angular';
import { SearchInputModule } from '@common/components/forms/search-input/search-input.module';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    ComputeInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TextFieldModule,
    IonicModule,
    SearchInputModule,
    TranslocoModule,
    MatTooltipModule
  ],
  exports: [
    ComputeInputComponent
  ]
})
export class ComputeInputModule { }

import { NotificationInterface, PlatformInterface } from '@common/interfaces/api';
import { GeneralService } from '@common/services/api';
import { ConnectionService } from '@common/services/connection.service';
import { NavController } from '@ionic/angular';
import { Component, Inject } from '@angular/core';
import { NotificationsUtils } from '@common/services/notifications';

@Component({
  template: ''
})
export abstract class NotificationsBase {

  constructor(@Inject(GeneralService) protected generaleServ: GeneralService,
              @Inject(ConnectionService) protected connectionServ: ConnectionService,
              @Inject(NavController) protected navCtrl: NavController) {
  }

  /**
   * Build data
   * @param notification notification object
   * @param platform platform object
   */
  buildData(notification: NotificationInterface, platform: PlatformInterface) {
    notification.isFromCurrentPlatform = notification.id_action === platform.id_action;
    notification.isFeed = false;

    if (notification.type === 'FeedNotification') {
      notification.isFeed = !notification.items[0] || !notification.items[0].id_action_quizz;
    } else if (notification.type === 'LikeNotification') {
      notification.isFeed = true;
    }
  }

  /**
   * Get image notification
   * @param notification notification object
   */
  getNotificationImage(notification: NotificationInterface) {
    let image;
    if (notification.items?.length > 0 && notification.items[0].profile_pic) {
      image = notification.items[0].profile_pic;
    }

    if (!notification.isFromCurrentPlatform) {
      return notification.action_logo;
    } else {
      return image || '/assets/images/placeholder/user.svg';
    }
  }

  /**
   * Action to do when click on notification
   * @param notification notification object
   */
  clickAction(notification: NotificationInterface) {
    this.generaleServ.readNotification(notification.id_notification).subscribe();
    notification.readed = true;
    if (!notification.isFromCurrentPlatform) {
      // Switch platform
      this.connectionServ.setSelectedPlatformId(notification.id_action).subscribe(() => {
        this.routeTo(notification);
      });
    } else {
      this.routeTo(notification);
    }
  }

  /**
   * Get icon notification name
   * @param notification notification object
   */
  getNotificationIconName(notification: NotificationInterface) {
    switch (notification.type) {
      case NotificationsUtils.QUIZ:
        return 'extension-puzzle';
      case NotificationsUtils.SURVEY:
        return 'rd-survey';
      case NotificationsUtils.LIKE:
        return 'heart';
      case NotificationsUtils.FEED:
        return 'home';
      case NotificationsUtils.FORM:
        return 'rd-form';
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
      case NotificationsUtils.CHALLENGE_PROOF:
        return 'podium';
      case NotificationsUtils.ROADBOX:
        return 'library';
      case NotificationsUtils.COINS:
        return 'rd-coins';
      case NotificationsUtils.CUSTOMER:
        return 'person';
    }
    return 'help';
  }

  /**
   * Get icon notification color
   * @param notification notification data
   */
  getNotificationIconColor(notification: NotificationInterface) {
    switch (notification.type) {
      case NotificationsUtils.QUIZ:
        return 'medium';
      case NotificationsUtils.SURVEY:
        return 'blue';
      case NotificationsUtils.FORM:
      case NotificationsUtils.COINS:
        return 'green';
      case NotificationsUtils.FEED:
        return 'primary';
      case NotificationsUtils.LIKE:
        return 'red';
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
      case NotificationsUtils.CHALLENGE_PROOF:
        return 'challenges';
      case NotificationsUtils.ROADBOX:
        return 'yellow';
      case NotificationsUtils.CUSTOMER:
        return 'purple';
    }
    return 'foreground';
  }

  /**
   * Route to notification page
   * @param notification notification object
   */
  routeTo(notification: NotificationInterface) {
    const referenceId = NotificationsUtils.getReferenceId(notification);
    const route = NotificationsUtils.getRoute(notification.type, referenceId, notification.is_admin);

    if (route) {
      this.navCtrl.navigateForward(route.url, route.options)?.then(/* Nothing to do */);
    }
  }
}

import { HttpBaseService } from '@common/services/api/http-base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from '@common/services/connection.service';
import { Observable, of } from 'rxjs';
import {
  EStoreBreadcrumbInterface,
  EStoreBrandsData,
  EStoreCategoriesData,
  EStoreCategoryInterface,
  EStoreProductsData, ReqEStoreBrandsInterface,
  ReqEStoreCategoriesInterface, ReqEStoreOrdersInterface, ReqEStoreProductInterface,
  ReqEStoreProductsInterface, ReqEStoreOrderDetailsInterface
} from '@common/interfaces/api/client';
import { map, switchMap, tap } from 'rxjs/operators';
import { CartService } from './cart.service';
import { Utils } from '@common/helpers/utils';
import { buildDeliverable } from '@common/helpers/e-store.utils';

@Injectable()
export class EStoreService extends HttpBaseService {

  static NUMBER_OF_PRODUCTS = 30;
  static NUMBER_OF_BRANDS = 75;

  private lastProducts: {
    result?: ReqEStoreProductsInterface,
    debut: number,
    limit: number
  };

  private lastBrands: {
    result?: ReqEStoreBrandsInterface,
    debut: number,
    limit: number
  };

  private categoriesData: EStoreCategoryInterface[];

  constructor(protected http: HttpClient,
              protected connectionServ: ConnectionService,
              protected cartServ: CartService) {
    super(http);

    this.lastProducts = {debut: 0, limit: EStoreService.NUMBER_OF_PRODUCTS};
  }

  /**
   * Return categories of e-store
   * @param params parameters
   * @param reload force reload
   */
  categories(params?: EStoreCategoriesData, reload = false): Observable<EStoreCategoryInterface[]> {
    // If id_category set, use subCategories endpoint
    if (params?.id_category) {
      return this.subCategories(params.id_category);
    } else {
      if (this.categoriesData && !reload) {
        return of(this.categoriesData);
      } else {
        const body = new FormData();
        if (params?.depth) {
          body.append('depth', params.depth.toString());
        }
        return this.stdRequest(this.http.post<ReqEStoreCategoriesInterface>(`${ this.rootApi }/getCategories`, body).pipe(
          map(response => response.categories),
          tap(response => this.categoriesData = response)
        ));
      }
    }
  }

  /**
   * Get a sub-categories from e-store
   * @param idCategory id of category
   */
  subCategories(idCategory: string): Observable<EStoreCategoryInterface[]> {
    const request = this.categoriesData ? of(this.categoriesData) : this.categories();

    return request.pipe(
      switchMap<EStoreCategoryInterface[], Observable<EStoreCategoryInterface[]>>(() => {
        const category = this.getCategory(idCategory);
        if (category) {
          return of(category.sub_categories);
        } else {
          return this.stdRequest(this.http.post<ReqEStoreCategoriesInterface>(`${ this.rootApi }/getCategories/${ idCategory }`, null).pipe(
            map(response => response.categories),
            // Update local list
            tap(categories => categories?.forEach(item => this.addCategory(item, idCategory)))
          ));
        }
      })
    );
  }

  /**
   * Get current category
   * @param idCategory
   */
  category(idCategory: string): EStoreCategoryInterface | null {
    return this.getCategory(idCategory);
  }

  /**
   * Ge breadcrumbs
   * @param idCategory
   */
  breadcrumbs(idCategory: string): EStoreBreadcrumbInterface[] | null {
    return this.buildBreadcrumbs(idCategory).breadcrumbs;
  }

  /**
   * Get orders History
   */
  ordersHistory(): Observable<ReqEStoreOrdersInterface> {
    return this.stdRequest<ReqEStoreOrdersInterface>(
      this.http.post<ReqEStoreOrdersInterface>(`${ this.rootApi }/getordershistory`, null)
    );
  }

  /**
   * Get order details
   */
  orderDetails(idOrder: number): Observable<ReqEStoreOrderDetailsInterface> {
    return this.stdRequest<ReqEStoreOrderDetailsInterface>(
      this.http.post<ReqEStoreOrderDetailsInterface>(`${ this.rootApi }/getorderdetail/${idOrder.toString()}`, null)
    );
  }

  /**
   * Get products from e-store
   * @param categoryId category id
   * @param data data of products
   * @param next get the next page
   */
  products(categoryId?: string, data?: EStoreProductsData, next = false): Observable<ReqEStoreProductsInterface> {
    // Init
    if (next === false) {
      this.lastProducts = {result: null, debut: 0, limit: EStoreService.NUMBER_OF_PRODUCTS};
    } else {
      this.lastProducts.debut += EStoreService.NUMBER_OF_PRODUCTS;

      if (this.lastProducts.debut > Utils.toNumber(this.lastProducts.result?.products_count)) {
        return of(this.lastProducts.result);
      }
    }

    const body = new FormData();
    body.append('debut', this.lastProducts.debut.toString());
    body.append('limit', this.lastProducts.limit.toString());
    if (data) {
      Object.keys(data).forEach(key => {
        if (key === 'search') {
          if (data[key]) {
            body.append(key, JSON.stringify({string: data[key]}));
          }
        } else {
          if (data[key]) {
            body.append(key, data[key]);
          }
        }
      });
    }
    let tempResponse: ReqEStoreProductsInterface;
    return this.stdRequest(this.http.post<ReqEStoreProductsInterface>(
      categoryId ? `${ this.rootApi }/getproducts/${ categoryId }` : `${ this.rootApi }/getproducts`,
      body
    ).pipe(
      // Synchronize with cart
      tap(response => tempResponse = response),
      switchMap(response => this.cartServ.syncProductsWithCart(response.products)),
      switchMap(response => {
        tempResponse.products = response;
        return of(tempResponse);
      }),
      // Update local products
      tap(response => {
        // Compute isDeliverable
        response?.products?.forEach(item => buildDeliverable(item));
        // TODO: to remove
        response?.products?.forEach(item => {
          item.images?.forEach(image => {
            image.link = image.link.replace('dev.', '');
            image.link = image.link.replace('preprod.', '');
          });
        });
        if (next) {
          // Update if next
          this.lastProducts.result.products = this.lastProducts.result.products.concat(response.products);
          this.lastProducts.result.notification_count = response.notification_count;
          this.lastProducts.result.pending_posts = response.pending_posts;
          this.lastProducts.result.user_coins = response.user_coins;
        } else {
          this.lastProducts.result = response;
        }
      }),
      map(() => this.lastProducts.result)
    ));
  }

  /**
   * Get a product from e-store
   * @param idProduct id of product
   * @param idCat id of product category
   */
  product(idProduct: string, idCat?: string): Observable<ReqEStoreProductInterface> {
    const body = new FormData();
    if (idCat) {
      body.append('id_category', idCat);
    }
    let tempResponse: ReqEStoreProductInterface;
    return this.stdRequest(this.http.post<ReqEStoreProductInterface>(`${ this.rootApi }/getproduct/${ idProduct }`, body).pipe(
      // Synchronize with cart
      tap(response => tempResponse = response),
      switchMap(response => this.cartServ.syncProductsWithCart([response.product])),
      switchMap(response => {
        tempResponse.product = response[0];
        return of(tempResponse);
      }),
      tap(response => {
        buildDeliverable(response.product);
        // TODO: to remove
        response?.product?.images?.forEach(image => {
          image.link = image.link.replace('dev.', '');
          image.link = image.link.replace('preprod.', '');
        });
      })
    ));
  }

  /**
   * Get brands
   * @param data filters
   * @param next get next result
   */
  brands(data?: EStoreBrandsData, next = false): Observable<ReqEStoreBrandsInterface> {
    // Init
    if (next === false) {
      this.lastBrands = {result: null, debut: 0, limit: EStoreService.NUMBER_OF_BRANDS};
    } else {
      this.lastBrands.debut += EStoreService.NUMBER_OF_BRANDS;

      // TODO: manage end of products
    }

    const body = new FormData();
    body.append('debut', this.lastBrands.debut.toString());
    body.append('limit', this.lastBrands.limit.toString());

    if (data) {
      Object.keys(data).forEach(key => {
        if (data[key]) {
          body.append(key, data[key]);
        }
      });
    }

    return this.stdRequest(this.http.post<ReqEStoreBrandsInterface>(`${ this.rootApi }/brands`, body).pipe(
      // TODO: to remove
      tap(response => {
        response?.brands?.forEach(item => {
          item.img_url = item.img_url.replace('dev.', '');
          item.img_url = item.img_url.replace('preprod.', '');
        });
      }),
      // Update local brands
      tap(response => {
        if (next) {
          // Update if next
          this.lastBrands.result.brands = this.lastBrands.result.brands.concat(response.brands);
          this.lastBrands.result.notification_count = response.notification_count;
          this.lastBrands.result.pending_posts = response.pending_posts;
          this.lastBrands.result.user_coins = response.user_coins;
        } else {
          this.lastBrands.result = response;
        }
      }),
      map(() => this.lastBrands.result)
    ));
  }

  /**
   * Build breadcrumbs
   * @param idCategory id to build
   * @param categories list
   * @private
   */
  private buildBreadcrumbs(idCategory: string, categories = this.categoriesData) {
    let breadcrumbs: EStoreBreadcrumbInterface[] = [];
    let findCategory = false;
    categories?.forEach(item => {

      if (!findCategory) {
        breadcrumbs = [{
          id_category: item.id_category,
          name: item.name
        }];

        if (Utils.toNumber(item.id_category) === Utils.toNumber(idCategory)) {
          findCategory = true;
        }

        if (item.sub_categories && !findCategory) {
          const result = this.buildBreadcrumbs(idCategory, item.sub_categories);
          if (result.findCategory) {
            breadcrumbs = breadcrumbs.concat(result.breadcrumbs);
            findCategory = true;
          }
        }
      }
    });
    return {breadcrumbs, findCategory};
  }

  /**
   * Get category item from list
   * @param idCategory id to get
   * @param categories optional list
   * @private
   */
  private getCategory(idCategory: string, categories = this.categoriesData) {
    let returnCat = null;
    categories?.forEach(item => {
      if (Utils.toNumber(item.id_category) === Utils.toNumber(idCategory)) {
        returnCat = item;
      }
      if (item.sub_categories && !returnCat) {
        returnCat = this.getCategory(idCategory, item.sub_categories);
      }
    });
    return returnCat;
  }

  /**
   * Add category to list
   * @param category
   * @param idParent
   * @param categories
   * @private
   */
  private addCategory(category: EStoreCategoryInterface, idParent?: string, categories = this.categoriesData) {
    if (!categories) {
      return null;
    }
    if (idParent) {
      categories.forEach(item => {
        if (item.id_category === idParent) {
          if (!item.sub_categories) {
            item.sub_categories = [];
          }
          item.sub_categories.push(category);
          item.updated = true;
          return true;
        } else if (item.sub_categories) {
          return this.addCategory(category, idParent, item.sub_categories);
        }
      });
    } else {
      category.updated = true;
      categories.push(category);
      return true;
    }
    return false;
  }
}

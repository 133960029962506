import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AdminConsistencyFieldsValidateProps, UserFieldsItemInterface } from '@common/interfaces/api/admin';
import { AdminUsersService } from '@common/services/api/admin';
import { ToolboxService } from '@common/services/toolbox.service';
import { ProfileFieldsController, ProfileFieldsModalResult } from '@common/components/app/profile-fields';
import { buildStrictFilteredFieldsCode } from '@common/helpers/users';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigFieldInterface } from '@common/interfaces/api/client';
import { CommonService } from '@common/services/common.service';
import { Utils } from '@common/helpers/utils';

interface FieldItem {
  user: UserFieldsItemInterface;
  field: ConfigFieldInterface;
  isSelected: boolean;
}

@Component({
  selector: 'app-consistency-fields-modal',
  templateUrl: './consistency-fields.modal.html',
  styleUrls: ['./consistency-fields.modal.scss'],
})
export class DashboardConsistencyFieldsModal implements OnInit {

  @Input() users: UserFieldsItemInterface[] = [];

  usersToNotify: UserFieldsItemInterface[] = [];
  usersToUpdate: UserFieldsItemInterface[] = [];
  fieldsToValidate: FieldItem[] = [];

  usersToValidate: UserFieldsItemInterface[] = [];

  nbSelected: number = 0;

  isNotDisplayed: boolean = false;

  constructor(private modalCtrl: ModalController,
              private usersServ: AdminUsersService,
              private toolboxServ: ToolboxService,
              private commonServ: CommonService,
              private profileFieldsCtrl: ProfileFieldsController) {
  }

  ngOnInit() {
    this.commonServ.dataGet('__dashboard_consistency_fields_not_displayed')
      .subscribe(result => this.isNotDisplayed = Utils.resultToBoolean(result));

    this.users?.forEach(user => {
      if ((user.consistency_fields.to_update?.length || 0) > 0) {
        this.usersToUpdate.push(user);
      }
      if ((user.consistency_fields.to_notify?.length || 0) > 0) {
        this.usersToNotify.push(user);
      }
      if ((user.consistency_fields.to_validate?.length || 0) > 0) {
        this.usersToValidate.push(user);
        user.consistency_fields.to_validate.forEach(field => {
          this.fieldsToValidate.push({
            user,
            field,
            isSelected: false
          });
        });
      }
    });
  }

  selectAll(list: 'notify' | 'validate') {
    if (list === 'notify') {
      this.usersToNotify?.forEach(user => user.isSelected = true);
    } else {
      this.fieldsToValidate?.forEach(user => user.isSelected = true);
    }
    this.buildNbSelected(list);
  }

  unselectAll(list: 'notify' | 'validate') {
    if (list === 'notify') {
      this.usersToNotify?.forEach(user => user.isSelected = false);
    } else {
      this.fieldsToValidate?.forEach(user => user.isSelected = false);
    }
    this.buildNbSelected(list);
  }

  buildNbSelected(list: 'notify' | 'validate') {
    if (list === 'notify') {
      this.nbSelected = this.usersToNotify?.filter(user => user.isSelected).length || 0;
    } else {
      this.nbSelected = this.fieldsToValidate?.filter(field => field.isSelected).length || 0;
    }
  }

  resetSelection(event: CustomEvent) {
    if (event?.detail?.value === 'group-validate') {
      this.buildNbSelected('validate');
    } else if (event?.detail?.value === 'group-notify') {
      this.buildNbSelected('notify');
    }
  }

  notifyUsers() {
    if (this.nbSelected > 0) {
      this.handleRequest(this.usersServ.consistencyFieldsNotify(this.usersToNotify.filter(user => user.isSelected).map(user => user.id)))
        .subscribe(/* Nothing to do*/);
    }
  }

  editUser(user: UserFieldsItemInterface, list: 'notify' | 'update') {
    this.usersServ.user(user.id).subscribe(response => {
      this.profileFieldsCtrl.show({
        idCustomer: user.id,
        fields: response.fields,
        filteredFields: buildStrictFilteredFieldsCode(response.fields, list === 'notify' ? user.consistency_fields.to_notify : user.consistency_fields.to_update),
        showFields: true,
        allowDisable: false,
        editMode: 'default',
        userName: user.firstname + ' ' + user.lastname,
        isAdminContext: true
      }, true).then((data: ProfileFieldsModalResult) => {
        if (data.role === 'apply') {
          this.handleRequest(
            this.usersServ.updateUser({fields: data.data, id_customer: user.id, disableMandatoryCheck: true}),
            'alerts.std-update'
          ).subscribe(() => {
            if (list === 'notify') {
              this.usersToNotify = this.usersToNotify.filter(item => item.id !== user.id);
              this.buildNbSelected('notify');
            } else {
              this.usersToUpdate = this.usersToUpdate.filter(item => item.id !== user.id);
            }
          });
        }
      });
    });
  }

  validateFields(shallReject: boolean = false) {
    if (this.nbSelected > 0) {
      const selectedFieldIds = this.fieldsToValidate.filter(field => field.isSelected).map(item => item.field.id_temp_value);
      const data: AdminConsistencyFieldsValidateProps = {
        accepted: shallReject ? [] : selectedFieldIds,
        refused: shallReject ? selectedFieldIds : []
      };
      this.handleRequest(
        this.usersServ.consistencyFieldsValidate(data),
        shallReject ? 'dashboard.consistency-fields.message-reject-validate' : 'dashboard.consistency-fields.message-accept-validate',
        shallReject ? ToolboxService.TYPE_WARNING : ToolboxService.TYPE_SUCCESS
      ).subscribe(() => {
        this.fieldsToValidate = this.fieldsToValidate.filter(field => !field.isSelected);
        this.usersToValidate = this.usersToValidate.filter(user => this.fieldsToValidate.find(field => field.user.id === user.id));
        this.buildNbSelected('validate');
      });
    }
  }

  onDisplayChange() {
    this.commonServ.dataSave('__dashboard_consistency_fields_not_displayed', this.isNotDisplayed ? '1' : '0');
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel')?.then(/* Nothing to do */);
  }

  private handleRequest<T>(request: Observable<T>,
                           successMessageKey: string = 'dashboard.consistency-fields.message-notify',
                           type: string = ToolboxService.TYPE_SUCCESS): Observable<T> {
    this.toolboxServ.showLoading()?.then(/*nothing to do */);
    return request.pipe(
      tap({
        next: () => {
          this.toolboxServ.hideLoading()?.then(/* Nothing to do */);
          this.toolboxServ.flash({
            messageKey: successMessageKey,
            messageParams: {num: this.nbSelected},
            type,
            duration: 5000
          })?.then(/* Nothing to do */);
        },
        error: err => {
          console.log(err);
          this.toolboxServ.hideLoading()?.then(/* Nothing to do */);
          this.toolboxServ.flash({
            message: this.toolboxServ.translate('alerts.std-error') + '<br />' + err?.error?.message,
            duration: 5000,
            type: ToolboxService.TYPE_ERROR
          })?.then(/* Nothing to do */);
        }
      })
    );
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssistanceController } from '@common/components/modals/assistance/assistance.controller';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { AssistanceChoiceModal } from '@common/components/modals/assistance/assistance-choice.modal';
import { ToolbarModalModule } from '@common/components/modals/toolbar-modal';
import { AssistanceContactModal } from '@common/components/modals/assistance/assistance-contact.modal';
import { TextInputModule } from '@common/components/forms';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AssistanceChoiceModal,
    AssistanceContactModal
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    ToolbarModalModule,
    TextInputModule,
    FormsModule
  ],
  providers: [
    AssistanceController
  ]
})
export class AssistanceModule { }

import { Component } from '@angular/core';
import { params } from '@environments/params';
import { CommonService } from '@common/services/common.service';

@Component({
  selector: 'app-copyright',
  template: `© {{ date.getFullYear() }} <a (click)="openLink($event)" href="{{params.links.brand_link}}" target="_blank">Maslo</a>`,
  styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent {

  date = new Date();

  params = params;

  constructor(private commonServ: CommonService) { }

  openLink(event: Event) {
    event.preventDefault();
    this.commonServ.openUrl(params.links.brand_link);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { UsersService } from '@common/services/api/client';
import { FormsModule } from '@angular/forms';
import { PlatformSelectModule } from '@common/components/app/platform-select';
import { ImgAvatarModule } from '@common/components/images';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@common/directives/directives.module';
import { AssistanceModule } from '@common/components/modals/assistance';
import { PipesModule } from '@common/pipes';
import { CopyrightModule } from '@common/components/app/copyright/copyright.module';



@NgModule({
  declarations: [
    SideMenuComponent
  ],
  exports: [
    SideMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    IonicModule,
    TranslocoModule,
    PlatformSelectModule,
    ImgAvatarModule,
    TranslocoLocaleModule,
    MatTooltipModule,
    DirectivesModule,
    AssistanceModule,
    PipesModule,
    CopyrightModule
  ],
  providers: [
    UsersService
  ]
})
export class SideMenuModule { }


export interface BadgeNotificationsEventsInterface {
  // Main access
  accessFlag: boolean;
  // Flag
  challengesFlag: boolean;
  coinsFlag: boolean;
  cashFlag: boolean;
  giftsFlag: boolean;
  feedFlag: boolean;
  formsFlag: boolean;
  quizzesFlag: boolean;
  roadboxFlag: boolean;
  surveysFlag: boolean;

  // Categories identifications
  challengesCategoriesIds: Array<number>;
  roadboxCategoriesIds: Array<number>;

  // Identifications
  challengesIds: Array<BadgeNotificationsEventsIdsInterface>;
  coinsIds: Array<BadgeNotificationsEventsIdsInterface>;
  cashIds: Array<BadgeNotificationsEventsIdsInterface>;
  giftsIds: Array<BadgeNotificationsEventsIdsInterface>;
  feedIds: Array<BadgeNotificationsEventsIdsInterface>;
  formsIds: Array<BadgeNotificationsEventsIdsInterface>;
  quizzesIds: Array<BadgeNotificationsEventsIdsInterface>;
  roadboxIds: Array<BadgeNotificationsEventsIdsInterface>;
  surveysIds: Array<BadgeNotificationsEventsIdsInterface>;

  addId(type: string, relationId: number, notificationId: number): void;
  buildFlags(): void;
  hasRelationEvent(type: string, relationId: number): boolean;
  hasRelationCategoryEvent(type: string, relationId: number): boolean;
  removeId(type: string, relationId: number): void;
}

export interface BadgeNotificationsEventsIdsInterface {
  relationId: number;
  notificationIds: Array<number>;
}

export interface BadgeNotificationEventInterface {
  forDashboard?: boolean;
  relationId: number;
  type: string;
}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { PlatformInterface } from '@common/interfaces/api';
import { ConnectionService } from '@common/services/connection.service';
import { Subscription } from 'rxjs';
import { AccessControlService, PermissionsForClientInterface, PermissionsForSettingsModel } from '@common/services/access-control';

@Component({
  selector: 'app-platform-select',
  templateUrl: './platform-select.component.html',
  styleUrls: ['./platform-select.component.scss'],
})
export class PlatformSelectComponent implements OnInit, OnDestroy {

  @Output() platformChange: EventEmitter<PlatformInterface> = new EventEmitter<PlatformInterface>();

  @Output() platformsCount: EventEmitter<number> = new EventEmitter<number>();

  platforms: PlatformInterface[];
  currentPermissions: PermissionsForClientInterface;

  private subscription: Subscription;

  constructor(private connectionServ: ConnectionService,
              private accessServ: AccessControlService) { }

  ngOnInit() {
    this.connectionServ.getPlatforms().subscribe(platforms => {
      this.platforms = platforms;
      this.platformsCount.emit(this.platforms?.length || 0);

      this.platforms?.forEach(platform => {
        const permissions = new PermissionsForSettingsModel();
        permissions.buildPermissions(platform.access?.routeList?.settings);
        platform.isThemingEnabled = permissions.isThemingEnabled;
      });
    });

    this.subscription = this.accessServ.permissionsForClientState().subscribe(permissions => {
      this.currentPermissions = permissions;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  selectPlatform(platform: PlatformInterface) {
    if (this.currentPermissions.platform?.id_action !== platform.id_action) {
      this.connectionServ.setSelectedPlatformId(platform.id_action).subscribe(response => {
        this.platformChange.emit(response);
      });
    }
  }

}

import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { ReqLandingPageInterface, PlatformInterface } from '@common/interfaces/api';
import { GeneralService } from './api';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs/operators';
import { ConnectionService } from './connection.service';
import { TrackingClientService } from './client';
import { NavController, Platform } from '@ionic/angular';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Locale, NumberTypes } from '@ngneat/transloco-locale/lib/transloco-locale.types';
import { LinkItemInterface, UserProfileInterface } from '@common/interfaces/common.interface';
import { Utils } from '@common/helpers/utils';
import { TeamService } from '@common/services/team.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { ThemeService } from '@common/services/theme.service';
import { params as EnvParams } from '@environments/params';
import { InAppReview } from '@capacitor-community/in-app-review';
import { AccessControlService } from '@common/services/access-control';
import { DarkColor } from '@common/helpers/_colors';
import { CartService } from '@common/services/api/client';
import { QueueService } from '@common/services/queue.service';
import { ConfigFieldInterface } from '@common/interfaces/api/client';

import { Storage } from '@ionic/storage';
import { Browser } from '@maslo/browser';
import { App } from '@capacitor/app';
import { AppUpdate } from '@capawesome/capacitor-app-update';

import * as moment from 'moment';

/**
 * Common service for common task
 */

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  static AUTH_STORAGE_KEY = '__auth-config';

  landingPage: ReqLandingPageInterface;

  private kpiKeyValues = {
    // From models
    '-appointments-': {value: 'kpi.appointments'},
    '-rdv-': {value: 'kpi.appointments'},
    '-opportunity-': {value: 'kpi.opportunity'},
    '-post-': {value: 'kpi.post'},
    '-post-image-': {value: 'kpi.post-image'},
    '-post-video-': {value: 'kpi.post-video'},
    '-like-feed-': {value: 'kpi.like-feed'},
    '-like-gallery-': {value: 'kpi.like-gallery'},
    '-comment-feed-': {value: 'kpi.comment-feed'},
    '-comment-gallery-': {value: 'kpi.comment-gallery'},
    '-form-answer-': {value: 'kpi.form-answer', title: 'kpi.form-answer-title'},
    '-points-': {value: 'kpi.points'},
    '-km-': {value: 'kpi.km'},
    '-victory-': {value: 'kpi.victory'},
    '-sale-': {value: 'kpi.sale'},
    '-lead-': {value: 'kpi.lead'},
    '-call-': {value: 'kpi.call'},
    '-missed-call-': {value: 'kpi.missed-call'},
    '-star-': {value: 'kpi.star'},
    '-day-': {value: 'kpi.day'},
    '-contact-': {value: 'kpi.contact'},
    '-review-': {value: 'kpi.review'},
    '-vehicle-sold-': {value: 'kpi.vehicle-sold'},
    '-contract-sold-': {value: 'kpi.contract-sold'},
    '-options-sold-': {value: 'kpi.options-sold', includeCA: true},
    '-approved-funding-': {value: 'kpi.approved-funding', includeCA: true},
    '-mandate-signed-': {value: 'kpi.mandate-signed'},
    '-signed-deeds-': {value: 'kpi.signed-deeds'},
    // Legacy KPIs
    Appointments: {value: 'kpi.appointments'},
    Project: {value: 'kpi.opportunity'},
    Projects: {value: 'kpi.opportunity'},
    Post: {value: 'kpi.post'},
    'Post image': {value: 'kpi.post-image'},
    'Post video': {value: 'kpi.post-video'},
    'Like feed': {value: 'kpi.like-feed'},
    'Like galery': {value: 'kpi.like-gallery'},
    'Comment feed': {value: 'kpi.comment-feed'},
    'Comment galery': {value: 'kpi.comment-gallery'},
    'Form Answer': {value: 'kpi.form-answer', title: 'kpi.form-answer-title'},
    Points: {value: 'kpi.points'},
    points: {value: 'kpi.points'}
  };

  private params: any;

  private defaultLang: string;
  private sourceLang: 'navigator' | 'platform' | 'storage' | 'none' = 'none';

  private version: {
    isMandatoryUpdate: boolean,
    hasUpdate: boolean,
    shallUpdateServerVersion: boolean;
    current?: string,
    available?: string,
    currentCode?: number,
    availableCode?: number
  } = {isMandatoryUpdate: false, hasUpdate: false, shallUpdateServerVersion: false};

  constructor(private generalServ: GeneralService,
              private connectionServ: ConnectionService,
              private trackingServ: TrackingClientService,
              private accessServ: AccessControlService,
              private navCtrl: NavController,
              private translateServ: TranslocoService,
              private teamServ: TeamService,
              private ionicPlatform: Platform,
              private oauthServ: OAuthService,
              private transLocaleServ: TranslocoLocaleService,
              private themeServ: ThemeService,
              private cartServ: CartService,
              private queueServ: QueueService,
              private storage: Storage) {
  }

  addToQueue(data: any, name: string = 'default') {
    this.queueServ.add(data, name);
  }

  getConnectionService() {
    return this.connectionServ;
  }

  getTranslocoService() {
    return this.translateServ;
  }

  getGeneralService() {
    return this.generalServ;
  }

  /**
   * Get current profile
   */
  getProfile(): UserProfileInterface {
    return this.connectionServ.getPlatformUserProfileValue();
  }

  /**
   * Get current platform
   */
  getCurrentPlatform(): PlatformInterface {
    return this.connectionServ.getSelectedPlatformValue();
  }

  /**
   * Return absolute url if exist
   */
  getAbsoluteDomainUrl(): string | null {
    if (
      window &&
      'location' in window &&
      'protocol' in window.location &&
      'host' in window.location
    ) {
      return window.location.hostname;
    }
    return null;
  }

  /**
   * Check if landing shall be retrieved and return it
   */
  checkAndGetLandingPage(): Observable<ReqLandingPageInterface | null> {

    // If landing page already get, return it
    if (this.landingPage) {
      return of(this.landingPage);
    }

    const sub = this.getWhiteMarkContext();

    if (sub) {
      return this.generalServ.getLandingPage(sub, this.translateServ.getActiveLang()).pipe(
        tap(response => this.landingPage = response)
      );
    }

    return of(null);
  }

  /**
   * Get current scheme
   */
  getCurrentScheme() {
    return this.themeServ.getCurrentScheme();
  }

  /**
   * Get whiteMark context of App
   */
  getWhiteMarkContext() {
    return this.getCurrentScheme().landing || this.getSubDomain();
  }

  /**
   * Return the subdomain if provided and different from www and app
   */
  getSubDomain(): string {
    // return 'mideaplus';
    // return 'demotech';
    // return 'mylink';
    // return 'testsso';
    const url = this.getAbsoluteDomainUrl();
    if (!url) {
      return null;
    }
    if (url === 'localhost') {
      return 'dev';
    }
    const parts = url.split('.');
    if (parts.length >= 3) {
      const sub = parts[0];
      if (sub && sub !== 'www' && sub !== 'app') {
        return sub;
      }
    }
    return null;
  }

  /**
   * Logout user from platform
   * @param noRedirect no redirect after logout
   */
  logout(noRedirect = false) {
    this.trackingServ.sendTracking(this.trackingServ.getTrackingData());
    this.cartServ.flushCart();
    this.teamServ.flush();
    this.connectionServ.logout();
    if (this.oauthServ.hasValidAccessToken()) {
      this.oauthServ.logOut();
      this.dataRemove(CommonService.AUTH_STORAGE_KEY)?.then(/* Nothing to do */);
    }
    if (!noRedirect) {
      console.log('logout redirect to login');
      this.navCtrl.navigateBack(['/login'])?.then(/* Nothing to do */);
    }
  }

  /**
   * Get permission from platform
   * @param keys string of array of the key to check
   */
  checkPermissionFromPlatform(keys: string | Array<string>): boolean | any {
    return this.accessServ.checkPermissionFromPlatform(keys);
  }

  /**
   * Transform color in dark if platform is dark mode
   * @param hexLightColor light color to convert
   */
  public colorFromLightInput(hexLightColor: string) {
    return this.themeServ.isDarkMode() ? (new DarkColor(hexLightColor)).hex : hexLightColor;
  }

  /**
   * Shortcut to access localizeNumber
   */
  public localizeNumber(value: number | string, type: NumberTypes, locale?: Locale, options?: Intl.NumberFormatOptions) {
    return this.transLocaleServ.localizeNumber(value, type, locale, options);
  }

  /**
   * Shortcut access to translate
   */
  public translate(key: TranslateParams, params?: HashMap, lang?: string) {
    return this.translateServ.translate(key, params, lang);
  }

  /**
   * Shortcut to access getActiveLang
   */
  public getActiveLang() {
    return this.translateServ.getActiveLang();
  }

  /**
   * Translate challenge label
   * @param key key to translate
   * @param value value associated to label
   * @param useTitle use title label ?
   */
  translateChallengeLabel(key: string, value = 1, useTitle: boolean = false) {
    const keys = Object.keys(this.kpiKeyValues);

    // Check if key is in list
    const findKey = keys.find(val => val === key);
    if (findKey) {
      let transKey = this.kpiKeyValues[findKey].value;
      transKey = useTitle && this.kpiKeyValues[findKey].title ? this.kpiKeyValues[findKey].title : transKey;
      let message = this.translateServ.translate(transKey, {num: Utils.toNumber(value)});

      if (this.kpiKeyValues[findKey].includeCA === true) {
        message = (this.getCurrentPlatform()?.currency?.sign || '€') + ' ' + message;
      }

      return message;
    }

    if (key === 'Percent' || key === 'percent' || key === '-percent-') {
      return '%';
    }

    if (key === 'ca' || key === 'CA' || key === '-ca-') {
      return this.getCurrentPlatform()?.currency?.sign || '€';
    }

    return key;
  }

  translateProfileField(field?: ConfigFieldInterface) {
    if (!field) {
      return undefined;
    }
    const basicFieldKeys = [
      'email', 'collaboration',
      'firstname', 'lastname', 'nickname', 'phone', 'birthday',
      'poste', 'service', 'societe',
      'ville', 'departement', 'region', 'pays'
    ];
    if (basicFieldKeys.includes(field.code)) {
      return this.translateServ.translate('form.' + field.code);
    }
    return field.name;
  }

  /**
   * Indicate if is CA unit
   * @param key key to check
   */
  isCAUnitOfKPI(key: string) {
    if (key === 'ca' || key === 'CA' || key === '-ca-') {
      return true;
    }
    const findKey = Object.keys(this.kpiKeyValues).find(val => val === key);
    if (findKey) {
      return this.kpiKeyValues[findKey].includeCA === true;
    }
    return false;
  }

  /**
   * Return transloco events
   */
  translocoEvents() {
    return this.translateServ.events$;
  }

  /**
   * Translate key by inserting coins as param
   * @param key translate key
   */
  translateCoins(key: string) {
    const platform = this.connectionServ.getSelectedPlatformValue();
    return this.translateServ.translate(key, {
      coins: platform.money_name || 'Coins'
    });
  }

  /**
   * Translate challenge label
   * @param key key to translate
   */
  translateFilterLabel(key: string) {
    const formKeys = [
      'service', 'poste', 'departement',
      'region', 'pays', 'ville',
      'societe', 'team', 'nom_equipe'
    ];

    // Check if key is in list
    const findKey = formKeys.find(val => val === key);
    if (findKey) {
      return this.translateServ.translate('form.' + findKey);
    }

    return key;
  }

  /**
   * Indicate if user can access to dashboard
   */
  canAccessDashboard(): boolean {
    return this.accessServ.hasDashboardAccess();
  }

  /**
   * Storage management
   */
  async dataSave(key: string, data: any) {
    return await this.storage.set(key, data);
  }

  /**
   * Get data as observable
   * @param key key of data
   */
  dataGet(key: string): Observable<any> {
    return from(this.storage.get(key));
  }

  async dataRemove(key: string) {
    return await this.storage.remove(key);
  }

  /**
   * Get data keys as observable
   */
  dataKeys(): Observable<string[]> {
    return from(this.storage.keys());
  }

  /**
   * Format HTML text and include mentions, link...
   * @param html string
   * @param formatLinks string
   * @param formatEmails string
   */
  formatHTMLText(html: string, formatLinks = true, formatEmails = true): string {
    if (typeof html !== 'string') {
      return html;
    }

    // NL2BR
    html = html.replace(/\n/g, '<br />');

    if (formatLinks) {
      // http://, https://, ftp://
      html = html.replace(
        Utils.PATTERN_HTTP,
        '<a href="$&" target="_blank">$&</a>'
      );

      // www. without http:// or https://
      html = html.replace(
        Utils.PATTERN_WWW,
        '<a href="http://$&" target="_blank">$&</a>'
      );
    }

    // Emails
    if (formatEmails) {
      html = html.replace(
        Utils.PATTERN_EMAIL,
        '<a href="mailto:$&" target="_blank">$&</a>'
      );
    }

    // Mentions
    html = html.replace(/{{([^{}]*)}}/g, (c, p) => {
      const part = p.split(':');

      if (part[0] === '@') {
        return part[3] === 'user' ?
          `<a class="mention" data-m-id="${ part[1] }" data-m-name="${ part[2] }" data-m-type="${ part[3] }" href="/profile/${ part[1] }">${ part[2] }</a> ` :
          `<a class="mention" data-m-id="${ part[1] }" data-m-name="${ part[2] }" data-m-type="${ part[3] }">${ part[2] }</a> `;
      } else {
        return c;
      }
    });

    return html;
  }

  /**
   * Find links in text
   * @param text search text
   */
  findLinks(text: string) {
    const results: LinkItemInterface[] = [];

    let matches: Array<string> = text?.match(Utils.PATTERN_HTTP);
    matches?.map(val => val?.trim())?.forEach(value => results.push({value, href: value, type: 'url'}));

    matches = text?.match(Utils.PATTERN_WWW);
    matches?.map(val => val?.trim())?.forEach(value => results.push({value, href: 'http://' + value, type: 'url'}));

    matches = text?.match(Utils.PATTERN_EMAIL);
    matches?.map(val => val?.trim())?.forEach(value => results.push({value, href: 'http://' + value, type: 'email'}));

    return results;
  }

  /**
   * Build smart display date (not repeat year or month if the same)
   * @param dateFrom date from
   * @param dateTo date to
   */
  buildSmartFromToDate(dateFrom: string | Date, dateTo: string | Date) {
    moment.locale(this.translateServ.getActiveLang());

    const start = moment(dateFrom);
    const end = moment(dateTo);

    let startLabel;
    let endLabel;

    if (start.isSame(end, 'month')) {
      startLabel = start.format('D');
      endLabel = end.format('LL');
    } else if (start.isSame(end, 'year')) {
      startLabel = start.format('D MMMM');
      endLabel = end.format('LL');
    } else {
      startLabel = start.format('LL');
      endLabel = end.format('LL');
    }

    return this.translateServ.translate('utils.from-to', {
      start: startLabel,
      end: endLabel
    });
  }

  /**
   * Open URL in new window
   * @param url url to open
   */
  openUrl(url: string) {
    // Open device browser
    if (this.ionicPlatform.is('capacitor')) {
      Browser.open({url, view: 'default'}).then(/* Nothing to do */);
    } else {
      window.open(url, '_blank');
    }
  }

  /**
   * Download url
   * @param url link to download
   */
  downloadUrl(url: string) {
    if (this.ionicPlatform.is('capacitor')) {
      Browser.open({url, view: 'default'}).then(/* Nothing to do */);
    } else {
      Utils.download(url);
    }
  }

  /**
   * Open link to reload account
   */
  reloadAccount() {
    const lang = this.translateServ.getActiveLang();
    this.openUrl(EnvParams.reload_link[lang]);
  }

  /*
   * Display prompt to rate the app
   */
  promptAppRating(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.ionicPlatform.is('capacitor')) {
        const currentTime = Utils.getCurrentTimeStamp();
        this.storage.get('__lastRequestedRatingPrompt').then(lastRequestedPrompt => {
          if (!!lastRequestedPrompt) {
            // Check if diff is superior to 30 days
            if ((currentTime - lastRequestedPrompt) > 2592000) {
              InAppReview.requestReview().then(_ => {
                this.storage.set('__lastRequestedRatingPrompt', currentTime);
              });
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            // Init timestamp if not set
            this.storage.set('__lastRequestedRatingPrompt', currentTime).then(/* Nothing to do */);
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    });
  }

  /**
   * Manage Root level params
   * @param params params object
   */
  setParams(params: any) {
    this.params = params;
  }

  getParams() {
    return this.params;
  }

  /**
   * Manage languages
   *
   * Language priority: storage > platform > navigator > default
   * For platform, the language is applied in signup page
   */
  async getLang(): Promise<string> {
    if (this.defaultLang) {
      return this.defaultLang;
    }
    this.defaultLang = await this.dataGet('__lang').toPromise();
    if (this.defaultLang) {
      this.sourceLang = 'storage';
    } else {
      this.defaultLang = window.navigator.language?.substr(0, 2);
      if (this.defaultLang) {
        this.sourceLang = 'navigator';
      } else {
        this.defaultLang = 'en';
      }
    }
    return this.defaultLang;
  }

  getSourceLang() {
    return this.sourceLang;
  }

  setLang(lang: string, source?: 'navigator' | 'platform' | 'storage' | 'none') {
    if (source) {
      this.sourceLang = source || 'none';
    }
    this.dataSave('__lang', lang);
  }

  /**
   * App Version
   */
  getVersion() {
    return this.version;
  }

  /**
   * Check native app update
   */
  async checkVersionUpdate() {
    if (this.ionicPlatform.is('capacitor')) {
      const currentVersion = (await App.getInfo()).version;
      const serverInfo = (await firstValueFrom(this.generalServ.getAppsVersion()));
      const storeVersion = (await firstValueFrom(this.generalServ.checkAppsVersionOnStore())).version;

      const codeToVersion = (value: string | number): string => {
        value = String(value);
        if (value.indexOf('.') > -1) {
          return value;
        }
        value = Utils.toNumber(value);
        const hotfix: number = value % 100;
        const minor: number = Math.floor(value / 100) % 100;
        const major: number = Math.floor(value / 10000);
        return `${ major }.${ minor }.${ hotfix }`;
      };

      const versionToCode = (value: string | number): number => {
        value = String(value);
        if (value.indexOf('.') === -1) {
          return Utils.toNumber(value);
        }
        const parts = value.split('.');
        if (parts.length === 2) {
          parts.splice(0, 0, '0');
        }
        return Utils.toNumber(parts[0]) * 10000 + Utils.toNumber(parts[1]) * 100 + Utils.toNumber(parts[2]);
      };

      if (currentVersion && serverInfo) {
        this.version.current = codeToVersion(currentVersion);
        this.version.available = codeToVersion(serverInfo.version);
        this.version.currentCode = versionToCode(currentVersion);
        this.version.availableCode = versionToCode(serverInfo.version);
        this.version.shallUpdateServerVersion = storeVersion &&
          versionToCode(storeVersion) > versionToCode(serverInfo.version) &&
          versionToCode(serverInfo.version) < versionToCode(currentVersion);
        this.version.hasUpdate = versionToCode(serverInfo.version) > versionToCode(currentVersion);
        this.version.isMandatoryUpdate = serverInfo.mandatory || false;
      }

      console.log('Current version', currentVersion);
      console.log('Available version', serverInfo.version);
      console.log('Store version', storeVersion);
      console.log('Version object', this.version);
    }
    return this.version;
  }

  openAppInStore() {
    if (this.ionicPlatform.is('capacitor')) {
      AppUpdate.openAppStore().then(/* Nothing to do */);
    }
  }


}

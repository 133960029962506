import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigFieldInterface, StandardConfigFieldsInterface } from '@common/interfaces/api/client';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NameValidator } from '@common/validators';
import { FormErrorsService } from '@common/services/form-errors.service';
import { FilteredFieldsInterface, ProfileFieldsEditType } from '@common/components/app/profile-fields/profile-fields.interface';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-profile-fields',
  templateUrl: './profile-fields.component.html',
  styleUrls: ['./profile-fields.component.scss'],
})
export class ProfileFieldsComponent implements OnInit, OnChanges {

  @Input() fields: StandardConfigFieldsInterface;
  @Input() filteredFields?: FilteredFieldsInterface;

  @Input() editingSubtitle: string = '';

  @Input() showFields: boolean = false;
  @Input() allowDisable: boolean = true;
  /**
   * Edit mode
   * - none: no allow edit fields
   * - default: show in edit mode
   * - allow: show in view mode with possibility to edit
   */
  @Input() editMode: ProfileFieldsEditType = 'none';
  @Input() processing: boolean = false;
  @Input() isModalView: boolean = false;
  @Input() isAdminContext: boolean = false;

  @Output() fieldsChange: EventEmitter<StandardConfigFieldsInterface> = new EventEmitter<StandardConfigFieldsInterface>();

  isEditing: boolean = false;
  maxDateBirthday: string = moment().subtract(18, 'years').toISOString();
  maxDate: string = moment().add(20, 'years').toISOString();

  form!: FormGroup;

  fieldsGroups!: string[];
  fieldsGroupsSettings = {
    settings: ['email', 'collaboration'],
    contact: ['firstname', 'lastname', 'nickname', 'phone', 'birthday'],
    function: ['poste', 'service', 'societe'],
    localization: ['ville', 'departement', 'region', 'pays'],
    custom: []
  };
  fieldsStruct?: { [key: string]: ConfigFieldInterface[] };
  fieldsNoData: { [key: string]: boolean } = {};

  isGroupVisible = (group: string) => {
    if (!this.fields?.custom_config && !this.fields?.basic_config) {
      return false;
    }
    return this.fieldsGroupsSettings[group].some(fieldKey => this.isFieldVisible(fieldKey));
  };

  isInputVisible = (field: ConfigFieldInterface, input: string, group: string) => {
    switch (input) {
      case 'radio':
        return field.data_type === 'choice' || field.code === 'collaboration';
      case 'date':
        return field.data_type === 'date' || field.code === 'birthday';
      case 'autocomplete':
        return group !== 'contact' && field.data_type === 'text' && field.code !== 'birthday' && field.code !== 'collaboration';
      case 'text':
        return group === 'contact' && field.data_type === 'text' && field.code !== 'birthday' && field.code !== 'collaboration';
      case 'select':
        return field.data_type === 'list';
    }

    return false;
  };

  isFieldVisible = (code: string) => {
    if (!this.fields?.custom_config && !this.fields?.basic_config) {
      return false;
    }
    const fieldsList = (this.fields?.basic_config || []).concat(this.fields?.custom_config || []);
    const field = fieldsList.find(item => item.code === code);
    // Filtered visibility
    if (this.filteredFields) {
      return (this.filteredFields?.basic_fields?.includes(code) || this.filteredFields?.custom_fields?.includes(code)) &&
        this.isEditing &&
        (field?.edit || this.isAdminContext);
    }
    // Default visibility
    return ((!!field?.value || this.isEditing) && !!field?.display) || this.showFields;
  };

  getFieldMaxLength = (code: string) => {
    return code === 'phone' ? 20 : 50;
  };

  constructor(public errorsServ: FormErrorsService) {
    this.form = new FormGroup({
      settings: new FormGroup({
        email: new FormControl<string>(''),
        collaboration: new FormControl<number>(0)
      }),
      contact: new FormGroup({
        firstname: new FormControl<string>('', [NameValidator.isValid]),
        lastname: new FormControl<string>('', [NameValidator.isValid]),
        nickname: new FormControl<string>(''),
        phone: new FormControl<string>(''),
        birthday: new FormControl<string>(''),
      }),
      function: new FormGroup({
        poste: new FormControl<string>(''),
        service: new FormControl<string>(''),
        societe: new FormControl<string>(''),
      }),
      localization: new FormGroup({
        ville: new FormControl<string>(''),
        departement: new FormControl<string>(''),
        region: new FormControl<string>(''),
        pays: new FormControl<string>(''),
      }),
      custom: new FormGroup({})
    });

    this.fieldsGroups = Object.keys(this.fieldsGroupsSettings);
  }

  ngOnInit() {
    this.errorsServ.disable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields && this.fields) {
      this.initForm();
    }
    if (changes.processing && !this.processing) {
      this.isEditing = false;
    }
    if (changes.editMode) {
      this.isEditing = this.editMode === 'default';
    }
  }

  applyChanges() {
    const fields: StandardConfigFieldsInterface = Object.assign({}, this.fields);

    Object.keys(this.fieldsGroupsSettings).forEach(group => {
      this.fieldsGroupsSettings[group].forEach((fieldKey: string) => {
        const formField = this.form.get(group)?.get(fieldKey);
        if (formField) {
          const field = group === 'custom' ?
            fields.custom_config?.find(fieldItem => fieldItem.code === fieldKey) :
            fields.basic_config?.find(fieldItem => fieldItem.code === fieldKey);
          if (field) {
            field.value = formField.value;
          }
        }
      });
    });

    this.fieldsChange.emit(fields);
  }

  submitForm() {
    this.errorsServ.enable();

    if (this.form.valid) {
      this.applyChanges();
    }
  }

  private initForm() {
    const getBasicField = (key: string) => {
      return this.fields?.basic_config?.find(field => field.code === key);
    };
    const getCustomField = (key: string) => {
      return this.fields?.custom_config?.find(field => field.code === key);
    };

    // Custom Fields Group Settings
    this.fieldsGroupsSettings.custom = this.fields?.custom_config?.map(field => field.code) || [];

    this.fieldsStruct = {};

    Object.keys(this.fieldsGroupsSettings).forEach(group => {
      this.fieldsStruct[group] = [];
      this.fieldsNoData[group] = true;
      this.fieldsGroupsSettings[group].forEach((fieldKey: string) => {
        let formField: AbstractControl;
        const field = group === 'custom' ? getCustomField(fieldKey) : getBasicField(fieldKey);
        if (field) {
          if (group === 'custom') {
            formField = new FormControl<string>(field?.value || '');
            (this.form.get(group) as FormGroup)?.addControl(fieldKey, formField);
          } else {
            formField = this.form.get(group)?.get(fieldKey);
          }
          if (formField) {

            // Apply default value
            if (field.code === 'birthday') {
              field.value = field.value ? moment(field.value).format('YYYY-MM-DD') : '';
            }
            if (field.code === 'lastname' || field.code === 'firstname' || field.code === 'email') {
              field.mandatory = true;
            }

            // Reset value in case of mandatory list with value not in list
            if (field.data_type === 'list' && field.mandatory && field.values.indexOf(field.value) === -1) {
              field.value = '';
            }

            if (field.value) {
              this.fieldsNoData[group] = false;
            }

            this.fieldsStruct[group].push(field);
            formField.setValue(field.value);

            if (this.allowDisable && (!field.edit || (field.validation && field.is_pending))) {
              formField.disable({emitEvent: false});
            }

            if (field.mandatory && !this.isAdminContext) {
              formField.addValidators([Validators.required]);
            } else {
              formField.removeValidators([Validators.required]);
            }

            // Filter values
            field.values = field.values?.filter(value => value !== '') || [];
          }
        }
      });
    });
  }

}

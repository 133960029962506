import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { FormValidationItemData, ReqFormInterface, ReqFormsInterface } from '@common/interfaces/api/client';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StandardExportInterface, StandardResponseInterface } from '@common/interfaces/api';

@Injectable()
export class FormsService extends HttpBaseService {
  static FORM_NB_RESULTS = 20;

  formsData: ReqFormsInterface;

  /**
   * Used to get the list of available forms.
   */
  forms(reload = false): Observable<ReqFormsInterface> {
    if (this.formsData && !reload) {
      return of(this.formsData);
    } else {
      return this.stdRequest<ReqFormsInterface>(
        this.http.post<ReqFormsInterface>(`${ this.rootApi }/getforms`, null)
      ).pipe(
        tap(response => this.formsData = response)
      );
    }
  }

  /**
   * Used to get complete form with questions/answers.
   */
  form(idForm: number): Observable<ReqFormInterface> {
    return this.stdRequest<ReqFormInterface>(
      this.http.post<ReqFormInterface>(`${ this.rootApi }/getform/${ idForm.toString() }`, null)
    );
  }

  /**
   * Flag view of form
   * @param idForm if of form
   */
  flagView(idForm: string): Observable<StandardResponseInterface> {
    return this.stdRequest<StandardResponseInterface>(
      this.http.post<StandardResponseInterface>(`${ this.rootApi }/viewobject/Form/${ idForm }`, null)
    );
  }

  /**
   * Used to send user answers to the form. User results are returned into form object
   * @param idForm id of the form
   * @param answers answers data
   */
  validate(idForm: number, answers: FormValidationItemData[]): Observable<ReqFormInterface> {
    const body = new FormData();
    body.append('id_form', idForm.toString());

    // Manage files
    answers.forEach(item => {
      if (item.file) {
        body.append('form_answer[' + item.id_form_question.toString() + ']', item.file, item.file?.name);
        delete item.file;
      }
    });

    body.append('reponses', JSON.stringify(answers));

    return this.stdRequest<ReqFormInterface>(
      this.http.post<ReqFormInterface>(`${ this.rootApi }/validateform`, body)
    );
  }

  /**
   * Used to get csv file of user results for given form.
   * @param idForm if of form
   */
  exportResults(idForm: number): Observable<StandardExportInterface> {
    return this.stdRequest<StandardExportInterface>(
      this.http.post<StandardExportInterface>(`${ this.rootApi }/getform/${ idForm.toString() }/exportresults`, null)
    );
  }

}

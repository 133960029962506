import { Component, Inject } from '@angular/core';
import { TalkConversation } from '@common/components/app/talk/talk.interface';
import { TalkService } from '@common/components/app/talk/talk.service';
import { params } from '@environments/params';
import { Utils } from '@common/helpers/utils';
import { CommonService } from '@common/services/common.service';
import { UserProfileInterface } from '@common/interfaces/common.interface';


@Component({
  template: ''
})
export abstract class TalkConversationsBase {
  conversations: TalkConversation[];

  constructor(@Inject(TalkService) protected talkServ: TalkService,
              @Inject(CommonService) protected commonServ: CommonService) {

  }

  /**
   * Start new conversation
   * @param withUsers users for conversations
   * @param subject subject of conversation
   */
  startConversation(withUsers: UserProfileInterface[], subject?: string) {
    return new Promise<{ navigateTo: boolean, url?: any, queryParams?: any, conversation?: any }>(resolve => {
      if (withUsers?.length > 1 && !subject) {
        subject = this.talkServ.getSubjectFromParticipants(withUsers);
      }

      if (window.innerWidth < params.breakpoints.md) {
        const userId = withUsers?.length === 1 ? Utils.toNumber(withUsers[0].id_customer) : 0;
        // Mobile operation
        resolve({
          navigateTo: true,
          url: ['/messages/user', userId],
          queryParams: {
            users: userId === 0 ? withUsers?.map(val => val.id_customer).join(',') : null,
            subject
          }
        });

      } else {
        // Desktop operation
        this.talkServ.getConversation(withUsers).then(conversation => {
          if (conversation) {
            // Set conversation subject
            if (withUsers?.length > 1) {
              conversation.setAttributes({
                subject
              });
            }

            // Selection
            this.conversations.forEach(item => item.isSelected = false);

            // Fixture for new chat item
            this.conversations.splice(0, 0, {
              otherParticipants: withUsers,
              isSelected: true,
              isRead: true,
              lastMessage: {
                createdAt: null,
                id: null,
                senderId: null,
                text: this.commonServ.translate('messages.new-chat'),
                type: null
              },

              id: null,
              custom: null,
              subject,
              topicId: null,
              photoUrl: null,
              welcomeMessages: null
            });

            resolve({
              navigateTo: false,
              conversation
            });
          }
        });
      }
    });
  }


}

import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Filesystem } from '@capacitor/filesystem/';
import { Camera as CordovaCamera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { ActionSheetController, Platform as IonicPlatform } from '@ionic/angular';
import { MediaCapture as CordovaMediaCapture, MediaFile, CaptureError, CaptureVideoOptions } from '@awesome-cordova-plugins/media-capture/ngx';
import { CameraResultType, CameraSource, Camera } from '@capacitor/camera';
import { Utils } from '@common/helpers/utils';
import * as moment from 'moment';

export interface MediaSelectOptions {
  titleKey?: string;
  accept?: string;
  multiple?: boolean;
}

export interface MediaOptions {
  type: MediaType;
  accept?: string; // Accept MIME of extensions (desktop mode only)
  multiple?: boolean; // Accept multiple file (desktop mode only)
}

/**
 * Type of media managed by camera service:
 * - video: get/select only one video
 * - image: get/select only one image/photo
 * - images: get/select only multiple images/photos
 * - document: get/select only one document
 * - mixed: get/select all file
 */
export type MediaType = 'video' | 'image' | 'images' | 'document' | 'mixed';

/**
 * Service that manage Native Camera plugin
 */

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(
    private translateServ: TranslocoService,
    // Used to pick/get picture
    private camera: CordovaCamera,
    // Used to record video
    private mediaCapture: CordovaMediaCapture,
    private ionicPlatform: IonicPlatform,
    private actionSheetController: ActionSheetController) {
  }

  /**
   * Get a media from native or desktop selector
   * @param options
   */
  getMedia(options: MediaOptions): Promise<File[]> {
    return new Promise<File[]>((resolve, reject) => {
      if (
        this.ionicPlatform.is('capacitor') &&
        (options?.type === 'video' || options?.type === 'image' || options?.type === 'images' || options?.type === 'mixed')
      ) {
        if (options?.type === 'image' || options?.type === 'images') {
          this.selectImage().then(value => {
            resolve([value]);
          }).catch(err => reject(err));
        } else if (options?.type === 'video') {
          this.selectVideo().then(value => {
            resolve([value]);
          }).catch(err => reject(err));
        } else if (options?.type === 'mixed') {
          this.selectDocument(options).then(value => {
            resolve([value]);
          }).catch(err => reject(err));
        } else {
          reject('bad-type');
        }
      } else {
        if (options?.type === 'video' || options?.type === 'image' || options?.type === 'images' || options?.type === 'document' || options?.type === 'mixed') {
          const fileEl = document.createElement('input');
          fileEl.type = 'file';
          fileEl.name = 'file';
          fileEl.multiple = options?.multiple || (options?.type === 'images');
          fileEl.accept = options?.accept || Utils.getMediasExtensionsFromType(options?.type);
          fileEl.addEventListener('change', (event: any) => {
            const target = event?.target;
            if (target?.files?.length > 0) {
              // Convert FileList in File[];
              const files: File[] = [];
              for (let index = 0; index < target?.files?.length; index++) {
                files.push((target.files as FileList).item(index));
              }
              resolve(files);
            } else {
              reject('cancel');
            }
          });
          fileEl.click();
        } else {
          reject('bad-type');
        }
      }
    });
  }

  /**
   * Pick (open File selector) image from native Cordova plugin
   * @return Promise<File>
   */
  pickImage(): Promise<File> {
    return this.pickFromNative(this.camera.MediaType.PICTURE);
  }

  /**
   * Pick (open File selector) video from native Cordova plugin
   * @return Promise<File>
   */
  pickVideo(): Promise<File> {
    return this.pickFromNative(this.camera.MediaType.VIDEO);
  }

  /**
   * Prompt user to take photo from camera or select from gallery
   */
  selectImage(): Promise<File> {
    return new Promise<File>(async (resolve, reject) => {
      const actionSheet = await this.actionSheetController.create({
        header: this.translateServ.translate('utils.camera.add-picture'),
        cssClass: 'select-actions',
        buttons: [
          {
            text: this.translateServ.translate('utils.camera.from-gallery'),
            icon: 'images',
            handler: () => {
              this.pickImage().then(value => {
                resolve(value);
              });
            }
          },
          {
            text: this.translateServ.translate('utils.camera.take-picture'),
            icon: 'camera',
            handler: () => {
              this.takePhoto().then(async value => {
                resolve(value);
              });
            }
          },
          {
            text: this.translateServ.translate('buttons.cancel'),
            icon: 'close-circle',
            role: 'cancel',
            handler: () => reject('cancel')
          }
        ]
      });
      await actionSheet.present();
    });
  }

  /**
   * Prompt user to record video from camera or select from gallery
   */
  selectVideo(): Promise<File> {
    return new Promise<File>(async (resolve, reject) => {
      const actionSheet = await this.actionSheetController.create({
        header: this.translateServ.translate('utils.camera.add-video'),
        cssClass: 'select-actions',
        buttons: [
          {
            text: this.translateServ.translate('utils.camera.from-gallery'),
            icon: 'images',
            handler: () => {
              this.pickVideo().then(value => {
                resolve(value);
              });
            }
          },
          {
            text: this.translateServ.translate('utils.camera.take-video'),
            icon: 'videocam',
            handler: () => {
              this.recordVideo().then(value => {
                resolve(value);
              });
            }
          },
          {
            text: this.translateServ.translate('buttons.cancel'),
            icon: 'close-circle',
            role: 'cancel',
            handler: () => reject('cancel')
          }
        ]
      });
      await actionSheet.present();
    });
  }

  /**
   * Prompt user to select from gallery or take/record from camera
   */
  selectDocument(options?: MediaSelectOptions) {
    return new Promise<File>(async (resolve, reject) => {
      const actionSheet = await this.actionSheetController.create({
        header: this.translateServ.translate(options?.titleKey || 'utils.camera.add-document'),
        cssClass: 'select-actions',
        buttons: [
          {
            text: this.translateServ.translate('utils.camera.from-gallery'),
            icon: 'images',
            handler: () => {
              // TODO: Use native plugin which manage accept files
              Utils.promptFile(options?.accept || '', false).then(value => resolve(value?.item(0) || null));
            }
          },
          {
            text: this.translateServ.translate('utils.camera.take-picture'),
            icon: 'camera',
            handler: () => {
              this.takePhoto().then(async value => {
                resolve(value);
              });
            }
          },
          {
            text: this.translateServ.translate('utils.camera.take-video'),
            icon: 'videocam',
            handler: () => {
              this.recordVideo().then(value => {
                resolve(value);
              });
            }
          },
          {
            text: this.translateServ.translate('buttons.cancel'),
            icon: 'close-circle',
            role: 'cancel',
            handler: () => reject('cancel')
          }
        ]
      });
      await actionSheet.present();
    });
  }

  /**
   * Take new photo with native Capacitor plugin
   */
  takePhoto(): Promise<File> {
    return new Promise<File>(async (resolve) => {
      const capturedPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
        quality: 100,
        saveToGallery: true,
        presentationStyle: 'fullscreen'
      });
      const base64Response = await fetch(`data:image/jpeg;base64,${ capturedPhoto.base64String }`);
      const file: any = await base64Response.blob();
      file.name = `Photo_${ moment().format('YYYY-MM-DD_HH:mm:SS') }.${ capturedPhoto.format }`;
      resolve(file);
    });
  }

  /**
   * Record new video with native Capacitor plugin
   */
  recordVideo(): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const options: CaptureVideoOptions = {limit: 1};
      this.mediaCapture
        .captureVideo(options)
        .then((mediaFile: MediaFile[]) => {
          const firstVideo = mediaFile[0];
          let videoUrl = firstVideo.fullPath;
          if (this.ionicPlatform.is('ios')) {
            videoUrl = 'file://' + videoUrl.substring(8);
          }
          const extension = Utils.extractExtension(firstVideo.name);
          const type = Utils.getMimeTypeFromExtension(extension);
          this.uriToBlob(videoUrl, type).then(value => {
            const file: any = value;
            file.name = `Video_${ moment().format('YYYY-MM-DD_HH:mm:SS') }.${ extension }`;
            resolve(file);
          });
        })
        .catch((err: CaptureError) => {
          reject(err);
        });
    });
  }

  /**
   * Convert FileURI path to Blob
   * @param fullPath File URI path
   * @param type File Mime type
   */
  async uriToBlob(fullPath: string, type: string) {
    const file = await Filesystem.readFile({
      path: fullPath
    });
    const base64Response = await fetch(`data:${ type };base64,${ file.data }`);
    return await base64Response.blob();
  }

  /**
   * Pick (open File selector) file from native Cordova plugin
   * @param mediaType
   * @private
   */
  private pickFromNative(mediaType: number): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const options: CameraOptions = {
        quality: 100,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        encodingType: this.camera.EncodingType.JPEG,
        correctOrientation: true,
        destinationType: this.camera.DestinationType.DATA_URL,
        mediaType
      };
      this.camera.getPicture(options).then(async dataUrl => {
        if (mediaType === this.camera.MediaType.VIDEO) {
          const fullPath = 'file://' + dataUrl;
          const extension = Utils.extractExtension(dataUrl);
          const type = Utils.getMimeTypeFromExtension(extension);
          const file: any = await this.uriToBlob(fullPath, type);
          file.name = `Video_${ moment().format('YYYY-MM-DD_HH:mm:SS') }.${ extension }`;
          resolve(file);
        } else if (mediaType === this.camera.MediaType.PICTURE) {
          const base64Response = await fetch(`data:image/jpeg;base64,${ dataUrl }`);
          const file: any = await base64Response.blob();
          file.name = `Photo_${ moment().format('YYYY-MM-DD_HH:mm:SS') }.jpeg`;
          resolve(file);
        } else {
          resolve(null);
        }
      }, (err) => {
        console.log(err);
        reject(err);
      });
    });
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { CommonService } from '@common/services/common.service';
import { DateRangeInterface } from '@common/components/forms/date-range-select/date-range-select.interface';

type DateList = 'week' | 'month' | 'quarter' | 'year';

@Component({
  selector: 'app-date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.scss'],
})
export class DateRangeSelectComponent implements OnInit, OnDestroy {

  @Input() start: string | Date;
  @Input() end: string | Date;

  @Input() min: string | Date;
  @Input() max: string | Date;

  @Input() display: DateList = null;

  @Output() dateChange: EventEmitter<DateRangeInterface> = new EventEmitter<DateRangeInterface>();

  range: FormGroup;

  label: string;

  list: DateList[] = ['week', 'month', 'quarter', 'year'];
  selectedList: DateList;

  private subscriptions: Subscription[] = [];

  constructor(private translateServ: TranslocoService,
              private commonServ: CommonService) {
  }

  ngOnInit() {
    moment.locale(this.translateServ.getActiveLang());

    this.subscriptions.push(this.translateServ.langChanges$.subscribe(lang => {
      moment.locale(lang);
      setTimeout(() => {
        this.buildLabel();
      }, 300);
    }));

    if (!this.max) {
      this.max = new Date();
    }

    switch (this.display) {
      case 'week':
        this.start = moment().subtract(7, 'days').toDate();
        this.end = moment().toDate();
        this.selectedList = 'week';
        break;
      case 'month':
        this.start = moment().subtract(1, 'month').toDate();
        this.end = moment().toDate();
        this.selectedList = 'month';
        break;
      case 'quarter':
        this.start = moment().subtract(1, 'quarter').toDate();
        this.end = moment().toDate();
        this.selectedList = 'quarter';
        break;
      case 'year':
        this.start = moment().subtract(1, 'year').toDate();
        this.end = moment().toDate();
        this.selectedList = 'year';
        break;
    }

    if (!this.start) {
      this.start = moment().subtract(7, 'days').toDate();
      this.end = moment().toDate();
      this.selectedList = 'week';
    }

    this.applyLimits();

    this.range = new FormGroup({
      start: new FormControl<Date>(this.start),
      end: new FormControl<Date>(this.end)
    });

    this.buildLabel();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Set start and end date from external
   * @param start
   * @param end
   */
  async set(start: string | Date | moment.Moment, end: string | Date | moment.Moment) {
    this.range.get('start')?.setValue(moment(start).toDate());
    this.range.get('end')?.setValue(moment(end).toDate());
    this.onDateChange('end');
    return true;
  }

  /**
   * Select last week or month or quarter or year
   * @param val
   */
  selectLast(val: DateList) {
    this.end = moment().toDate();

    this.selectedList = val;

    switch (val) {
      case 'month':
        this.start = moment().subtract(1, 'months').toDate();
        break;
      case 'quarter':
        this.start = moment().subtract(1, 'quarters').toDate();
        break;
      case 'year':
        this.start = moment().subtract(1, 'years').toDate();
        break;
      default:
        this.start = moment().subtract(1, 'weeks').toDate();
        this.selectedList = 'week';
        break;
    }

    this.applyLimits();

    this.range.get('start').setValue(this.start);
    this.range.get('end').setValue(this.end);
    this.buildLabel();
    this.noticeDateChange();
  }

  /**
   * On date change callback
   */
  onDateChange(source: 'start' | 'end') {
    let start = moment(this.range.get('start').value);
    let end = moment(this.range.get('end').value);
    const today = moment();
    let isUpdated = false;

    if (end.isAfter(today)) {
      end = moment();
      isUpdated = true;
    }

    if (start.isAfter(today)) {
      start = moment();
      isUpdated = true;
    }

    if (start.toISOString()) {
      this.start = start.toDate();
    }
    if (end.toISOString()) {
      this.end = end.toDate();
    }

    this.applyLimits();

    if (isUpdated) {
      this.range.get('start').setValue(this.start);
      this.range.get('end').setValue(this.end);
    }

    if (source === 'end' && end.toISOString()) {
      this.noticeDateChange();
    }

    this.selectedList = null;

    this.buildLabel();
  }

  /**
   * Apply limits
   * @private
   */
  private applyLimits() {
    if (this.start && this.min) {
      this.start = moment.max(moment(this.start), moment(this.min)).toDate();
    }

    if (this.end && this.max) {
      this.end = moment.min(moment(this.end), moment(this.max)).toDate();
    }
  }

  /**
   * Notify date change
   * @private
   */
  private noticeDateChange() {
    this.dateChange.emit({
      start: moment(this.start).toDate(),
      end: moment(this.end).toDate()
    });
  }

  /**
   * Build the label
   * @private
   */
  private buildLabel() {
    this.label = this.commonServ.buildSmartFromToDate(this.start, this.end);
  }

}


/**
 * Service to managing Observable queueing treatment
 */

import { Injectable } from '@angular/core';
import { concatMap, Observable, of, Subject, Subscription, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class QueueService {

  private queue$: Subject<any> = new Subject<any>();
  private queueIndex: number = 0;
  private subscription?: Subscription;

  /**
   * Add an observable to the queue
   * @param obs observable to add
   * @param name name of service
   */
  add(obs: Observable<any>, name: string = 'default') {
    if (this.subscription === undefined) {
      this.subscription = this.queue$.pipe(
        concatMap(data => {
          return data.obs.pipe(
            switchMap(result => of({result, obs: data.obs, index: data.index, name: data.name}))
          );
        })
      ).subscribe({
        next: (data: any) => {
          // console.log(`Treat QueueService: ${data.index}/${this.queueIndex} - ${name}`);
          if (data.index >= this.queueIndex) {
            this.destroy();
          }
        },
        error: () => {
          this.destroy();
        }
      });
    }
    this.queueIndex++;
    // console.log(`Init QueueService: ${this.queueIndex} - ${name} - ${this.subscription === undefined}`);
    this.queue$.next({obs, index: this.queueIndex, name});
  }

  destroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
      this.queueIndex = 0;
    }
  }

}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateInputComponent } from './date-input.component';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxMaskModule } from 'ngx-mask';
import { DateInputPickerPopover } from '@common/components/forms/date-input/picker/picker.popover';



@NgModule({
  declarations: [
    DateInputComponent,
    DateInputPickerPopover
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    OverlayModule,
    NgxMaskModule
  ],
  exports: [
    DateInputComponent,
    DateInputPickerPopover
  ]
})
export class DateInputModule { }

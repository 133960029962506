import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CguAgreementController } from './cgu-agreement.controller';
import { CguAgreementModal } from './cgu-agreement.modal';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { PipesModule } from '@common/pipes';
import { ToolbarModalModule } from '@common/components/modals/toolbar-modal';



@NgModule({
  declarations: [
    CguAgreementModal,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    PipesModule,
    ToolbarModalModule
  ],
  providers: [
    CguAgreementController
  ]
})
export class CguAgreementModule { }

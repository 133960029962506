import { Component, HostListener, OnInit } from '@angular/core';
import { SheetModalController } from '@common/services/sheet-modal.controller';
import { UsersService } from '@common/services/api/client';
import { CommonService } from '@common/services/common.service';
import { ToolboxService } from '@common/services/toolbox.service';

@Component({
  selector: 'app-assistance-contact-modal',
  templateUrl: './assistance-contact.modal.html',
  styleUrls: ['./assistance-contact.modal.scss'],
})
export class AssistanceContactModal implements OnInit {

  subject = '';
  message = '';

  constructor(private modalCtrl: SheetModalController,
              private usersServ: UsersService,
              private commonServ: CommonService,
              private toolboxServ: ToolboxService) { }

  ngOnInit() {}

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel').then(/* Nothing to do */);
  }

  sendAction() {
    this.toolboxServ.showLoading().then(/* Nothing to do */);
    const profile = this.commonServ.getProfile();
    this.usersServ.messageToAdmin(this.message, this.subject, profile.id_customer).subscribe({
      next: () => {
        this.toolboxServ.flash({
          message: this.toolboxServ.translate('modals.assistance.message-sent'),
          duration: 5000
        }).then(/* Nothing to do */);
        this.toolboxServ.hideLoading().then(/* Nothing to do */);
        this.modalCtrl.dismiss(null, 'apply').then(/* Nothing to do */);
      },
      error: err => {
        this.toolboxServ.flash({
          message: this.toolboxServ.translate('alerts.std-error') + '<br />' + err?.error?.message,
          duration: 5000,
          type: ToolboxService.TYPE_ERROR
        }).then(/* Nothing to do */);
        this.toolboxServ.hideLoading().then(/* Nothing to do */);
      }
    });
  }

}

import {Pipe, PipeTransform} from '@angular/core';
import { CommonService } from '@common/services/common.service';

@Pipe({
  name: 'translocoFilterLabel'
})
export class TranslocoFilterLabelPipe implements PipeTransform {

  constructor(private commonServ: CommonService) {
  }

  public transform(key: string): string {
    return this.commonServ.translateFilterLabel(key);
  }
}

import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  ReqCoinsHistoryChartInterface,
  ReqRewardsBadgesInterface,
  ReqRewardsHistoryInterface, RewardsBadgeInterface, RewardsGiftInterface
} from '@common/interfaces/api/client';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { Utils } from '@common/helpers/utils';
import { ReqRewardsGiftsInterface } from '@common/interfaces/api/client';

export type RewardsType = 'cash' | 'coins' | 'gift' | 'badge';

@Injectable({providedIn: 'root'})
export class RewardsService extends HttpBaseService {

  badgesList: BehaviorSubject<RewardsBadgeInterface[]> = new BehaviorSubject<RewardsBadgeInterface[]>(undefined);
  giftsList: BehaviorSubject<RewardsGiftInterface[]> = new BehaviorSubject<RewardsGiftInterface[]>(undefined);

  /***************************
   ******** Rewards **********
   **************************/

  /**
   * Get rewards History
   * @param type type to get
   * @param date date (for cash only)
   */
  history(type: RewardsType = 'coins', date?: string): Observable<ReqRewardsHistoryInterface> {
    const body = new FormData();
    body.append('reward_type', type);
    if (date) {
      body.append('date', date);
    }
    return this.stdRequest<ReqRewardsHistoryInterface>(
      this.http.post<ReqRewardsHistoryInterface>(`${ this.rootApi }/getrewardshistory`, body).pipe(
        tap((res) => {
          res?.history?.forEach((item) => {
            item.added_coins = Utils.toFloat(item.added_coins);
            item.added_cash = Utils.toFloat(item.added_cash);
          });
        })
      )
    );
  }

  /**************************
   ******** Coins **********
   *************************/

  /**
   * Used to get coins History Chart data
   */
  coinsHistoryChart(): Observable<ReqCoinsHistoryChartInterface> {
    return this.stdRequest<ReqCoinsHistoryChartInterface>(
      this.http.post<ReqCoinsHistoryChartInterface>(`${ this.rootApi }/getpointshistorychart`, null)
    );
  }

  /**************************
   ******** Gifts **********
   *************************/
  gifts(reload = false): Observable<RewardsGiftInterface[]> {
    const list = this.giftsList?.value;
    if (list === null) {
      return this.giftsList.asObservable().pipe(
        filter(res => res !== null),
        first()
      );
    }
    if (list?.length > 0 && !reload) {
      return of(list);
    } else {
      this.giftsList.next(null);
      return this.stdRequest<ReqRewardsGiftsInterface>(
        this.http.post<ReqRewardsGiftsInterface>(`${ this.rootApi }/gifts`, null)
      ).pipe(
        tap(res => this.giftsList.next(res.gifts)),
        map(res => res.gifts)
      );
    }
  }

  gift(idGift: number): Observable<RewardsGiftInterface> {
    const list = this.giftsList?.value;
    let gift;
    if (list?.length > 0) {
      gift = list.find(g => g.id_gift === idGift);
    }
    if (gift) {
      return of(gift);
    }
    return this.gifts(true).pipe(
      switchMap(res => of(res?.find(g => g.id_gift === idGift)))
    );
  }

  giftsResetList() {
    this.giftsList.next(undefined);
  }

  /**************************
   ******** Badges **********
   *************************/
  /**
   * Used to get the list of all badges of user.
   */
  badgesOfUser(idUser: number): Observable<ReqRewardsBadgesInterface> {
    return this.stdRequest<ReqRewardsBadgesInterface>(
      this.http.post<ReqRewardsBadgesInterface>(`${ this.rootApi }/badges/${ idUser.toString() }`, null)
    );
  }

  badges(reload = false): Observable<RewardsBadgeInterface[]> {
    const list = this.badgesList?.value;
    if (list === null) {
      return this.badgesList.asObservable().pipe(
        filter(res => res !== null),
        first()
      );
    }
    if (list?.length > 0 && !reload) {
      return of(list);
    } else {
      this.badgesList.next(null);
      return this.stdRequest<ReqRewardsBadgesInterface>(
        this.http.post<ReqRewardsBadgesInterface>(`${ this.rootApi }/badges`, null)
      ).pipe(
        tap(res => this.badgesList.next(res.badges)),
        map(res => res.badges)
      );
    }
  }

  badge(idBadge: number): Observable<RewardsBadgeInterface> {
    const list = this.badgesList?.value;
    let badge;
    if (list?.length > 0) {
      badge = list.find(g => g.id_badge === idBadge);
    }
    if (badge) {
      return of(badge);
    }
    return this.badges(true).pipe(
      switchMap(res => of(res?.find(g => g.id_badge === idBadge)))
    );
  }

  badgesResetList() {
    this.badgesList.next(undefined);
  }

}

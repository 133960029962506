import { Injectable } from '@angular/core';
import { HashMap, TranslateParams } from '@ngneat/transloco';
import { IonContent } from '@ionic/angular';
import { CommonService } from '@common/services/common.service';
import { ResponsiveService } from '@common/services/responsive.service';
import { AccessControlService } from '@common/services/access-control';
import { HttpBaseService } from '@common/services/api/http-base.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

declare const introJs;

/**
 * Integration of dontShowAgainId:
 *   - In IntroParams: target the section/group of steps
 *   - In IntroParams.steps: target the step only
 */
export interface IntroParams {
  ionContentRef?: IonContent;
  options?: any;
  steps: Array<any>;
  runOnMobile?: boolean;
  // Id for dontShowAgain of section
  dontShowAgainId?: string;
}

@Injectable({providedIn: 'root'})
export class OnboardingService extends HttpBaseService {

  private videos = {
    clientAdminIntro: {
      fr: 'https://www.youtube.com/embed/e41qFfi_7no',
      default: 'https://www.youtube.com/embed/CiMmGRUMR6E'
    },
    clientAdminChallenges: {
      fr: 'https://www.youtube.com/embed/IoYMWXIUtB4',
      default: 'https://www.youtube.com/embed/FGoYJ4IybIY'
    },
    clientAdminNewsfeed: {
      fr: 'https://www.youtube.com/embed/HI0NyQGhq0I',
      default: 'https://www.youtube.com/embed/3P_QkYjfgFc'
    },
    clientAdminEStore: {
      fr: 'https://www.youtube.com/embed/KAx90o7SZv8',
      default: 'https://www.youtube.com/embed/k4UqfbDguPo'
    },
    clientAdminProfile: {
      fr: 'https://www.youtube.com/embed/R30au8ehjzM',
      default: 'https://www.youtube.com/embed/VDwm943jMDA'
    },
    clientAdminForms: {
      fr: 'https://www.youtube.com/embed/bPRchLZ9DmQ',
      default: 'https://www.youtube.com/embed/h9UiqMSAb54'
    },
    clientAdminQuiz: {
      fr: 'https://www.youtube.com/embed/1x4SLdtVVY8',
      default: 'https://www.youtube.com/embed/gpLLI8jckkE'
    },
    clientAdminBox: {
      fr: 'https://www.youtube.com/embed/GWQ4-oxuosk',
      default: 'https://www.youtube.com/embed/bjTx07SRPZU'
    },
    clientAdminDashboard: {
      fr: 'https://www.youtube.com/embed/HzkRT5ZMvt0',
      default: 'https://www.youtube.com/embed/sa84buvn8ng'
    }
  };

  private dontShowAgain: { [key: string]: boolean } = {};
  private dontShowAgainInitialized = false;
  private dontShowAgainBypassAll = false;

  private isAdminChallengesModelsStep2Displayed = false;

  private introAlreadyDisplayed: { [key: string]: boolean } = {};

  constructor(private commonServ: CommonService,
              private responsiveServ: ResponsiveService,
              private accessServ: AccessControlService,
              protected http: HttpClient) {
    super(http);
  }

  /**
   * Integrate a video in introJS
   * @param key
   * @private
   */
  private video(key: any) {
    const lang = this.commonServ.getActiveLang();
    const video = this.videos[key];
    const videoLink = video[lang] || video.default;

    return `<iframe width="560" height="315" src="${ videoLink }" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
  }

  private mergeIntroSteps(steps: Array<any>) {
    return [
      {
        intro: this.video('clientAdminIntro'),
        tooltipClass: 'introjs-video',
        dontShowAgainId: 'clientAdminIntro',
        isSharedStep: true,
        shallPlayOneTimes: true
      }]
      .concat(steps);
  }

  private mergeDashboardSteps(steps: Array<any>) {
    return [
      {
        intro: this.video('clientAdminDashboard'),
        tooltipClass: 'introjs-video',
        dontShowAgainId: 'clientAdminDashboard',
        isSharedStep: true,
        shallPlayOneTimes: true
      }]
      .concat(steps);
  }

  /**
   * Initialize introJS
   */
  init() {
    this.loadConfig().subscribe(/* Nothing to do */);
  }

  /**
   * Destroy configuration
   */
  destroy() {
    this.dontShowAgain = {};
    this.dontShowAgainBypassAll = false;
    this.dontShowAgainInitialized = false;
    this.isAdminChallengesModelsStep2Displayed = false;
    this.introAlreadyDisplayed = {};
  }

  isOfferForOnboarding(checkAdmin = true) {
    const platform = this.commonServ.getCurrentPlatform();

    // For standard plan and Custom plan
    return platform &&
      (checkAdmin && platform?.is_admin || !checkAdmin) &&
      platform?.offer !== 'frendli' &&
      platform?.offer !== 'pistonr';
  }

  /**
   * Reset onboarding
   * @param keys keys to reset
   * @param bypassAll bypass don't show again all presentation
   */
  async reset(keys: string | Array<string>, bypassAll = false) {
    keys = Array.isArray(keys) ? keys : [keys];

    keys.forEach(key => {
      this.dontShowAgain[key] = false;
    });

    if (bypassAll) {
      this.dontShowAgainBypassAll = true;
    }

    return await this.saveConfig().toPromise();
  }

  /**
   * Reset all config
   */
  async resetAll() {
    this.dontShowAgain = {};
    this.dontShowAgainBypassAll = false;

    return await this.saveConfig().toPromise();
  }

  /**
   * Load video in IntroJS
   * @param key
   */
  introVideo(key: string) {
    const keys = Object.keys(this.videos);
    if (keys.includes(key)) {
      this.setupIntroJs({
        runOnMobile: false,
        steps: [{
          intro: this.video(key),
          tooltipClass: 'introjs-video'
        }],
      }).then(/* Nothing to do */);
    }
  }

  clientAdminNewsfeed() {
    if (!this.isOfferForOnboarding()) {
      return;
    }
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeIntroSteps([
          {
            intro: this.video('clientAdminNewsfeed'),
            tooltipClass: 'introjs-video',
            dontShowAgainId: 'clientAdminNewsfeed'
          }
        ]),
      }).then(/* Nothing to do */);
    }, 1000);
  }

  clientAdminChallenges() {
    if (!this.isOfferForOnboarding()) {
      return;
    }
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeIntroSteps([
          {
            intro: this.video('clientAdminChallenges'),
            tooltipClass: 'introjs-video',
            dontShowAgainId: 'clientAdminChallenges'
          }
        ]),
      }).then(/* Nothing to do */);
    }, 1000);
  }

  clientAdminEStore() {
    if (!this.isOfferForOnboarding()) {
      return;
    }
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeIntroSteps([
          {
            intro: this.video('clientAdminEStore'),
            tooltipClass: 'introjs-video',
            dontShowAgainId: 'clientAdminEStore'
          }
        ]),
      }).then(/* Nothing to do */);
    }, 1000);
  }

  clientAdminQuiz() {
    if (!this.isOfferForOnboarding()) {
      return;
    }
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeIntroSteps([
          {
            intro: this.video('clientAdminQuiz'),
            tooltipClass: 'introjs-video',
            dontShowAgainId: 'clientAdminQuiz'
          }
        ]),
      }).then(/* Nothing to do */);
    }, 1000);
  }

  clientAdminForms() {
    if (!this.isOfferForOnboarding()) {
      return;
    }
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeIntroSteps([
          {
            intro: this.video('clientAdminForms'),
            tooltipClass: 'introjs-video',
            dontShowAgainId: 'clientAdminForms'
          }
        ]),
      }).then(/* Nothing to do */);
    }, 1000);
  }

  clientAdminBox() {
    if (!this.isOfferForOnboarding()) {
      return;
    }
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeIntroSteps([
          {
            intro: this.video('clientAdminBox'),
            tooltipClass: 'introjs-video',
            dontShowAgainId: 'clientAdminBox'
          }
        ]),
      }).then(/* Nothing to do */);
    }, 1000);
  }

  /**
   * Display intro admin onboarding only for admin user
   */
  clientAdminIntro() {
    if (!this.isOfferForOnboarding()) {
      return;
    }
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: [
          {
            intro: this.video('clientAdminIntro'),
            tooltipClass: 'introjs-video',
            dontShowAgainId: 'clientAdminIntro'
          }
        ],
      }).then(/* Nothing to do */);
    }, 1500);
  }

  /**
   * Stats onboarding
   */
  adminStatsChallenges() {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        dontShowAgainId: 'adminStatsChallenges',
        steps: this.mergeDashboardSteps([
          {
            intro: this.translate('onboarding.admin-stats-challenges-1'),
          },
          {
            element: document.querySelector('.challenges-filter-button'),
            intro: this.translate('onboarding.admin-stats-challenges-2'),
          },
          {
            element: document.querySelector('.users-filter-button'),
            intro: this.translate('onboarding.admin-stats-challenges-3'),
          },
          {
            element: document.querySelector('.export-button'),
            intro: this.translate('onboarding.admin-stats-challenges-4'),
          }
        ])
      }).then(/* Nothing to do */);
    }, 1000);
  }

  adminStatsInteractions(scrollEl) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        dontShowAgainId: 'adminStatsInteractions',
        steps: this.mergeDashboardSteps([
          {
            element: document.querySelector('.users-base'),
            intro: this.translate('onboarding.admin-stats-interactions-1'),
          },
          {
            element: document.querySelector('.interaction-section'),
            intro: this.translate('onboarding.admin-stats-interactions-2'),
          },
          {
            element: document.querySelector('.export-button'),
            intro: this.translate('onboarding.admin-stats-interactions-3'),
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  adminStatsAudience(withLogs = false, scrollEl = null) {
    setTimeout(() => {
      const steps = [];

      if (withLogs) {
        steps.push({
          element: document.querySelector('.logs-button'),
          intro: this.translate('onboarding.admin-stats-audience-1'),
          dontShowAgainId: 'adminStatsAudience1'
        });
      }

      steps.push({
        element: document.querySelector('.connections-time'),
        intro: this.translate('onboarding.admin-stats-audience-2'),
        dontShowAgainId: 'adminStatsAudience2'
      });

      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeDashboardSteps(steps),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 500);
  }

  adminStatsEStore(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeDashboardSteps([
          {
            element: document.querySelector('.users-coins-rank'),
            intro: this.translate('onboarding.admin-stats-e-store-1'),
            dontShowAgainId: 'adminStatsEStore1'
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  /**
   * Settings onboarding
   */
  adminSettings(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        dontShowAgainId: 'adminSettings',
        steps: this.mergeDashboardSteps([
          {
            element: document.querySelector('.theming'),
            intro: this.translate('onboarding.admin-settings-1'),
          },
          {
            element: document.querySelector('.images'),
            intro: this.translate('onboarding.admin-settings-2'),
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  /**
   * EStore onboarding
   */
  adminEStoreBilling(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        dontShowAgainId: 'adminEStoreBilling',
        steps: this.mergeDashboardSteps([
          {
            element: document.querySelector('.reload'),
            intro: this.translate('onboarding.admin-e-store-billing-1'),
          },
          {
            element: document.querySelector('.details-part'),
            intro: this.translate('onboarding.admin-e-store-billing-2'),
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  adminEStoreOrders(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeDashboardSteps([
          {
            element: document.querySelector('.export-button'),
            intro: this.translate('onboarding.admin-e-store-orders-1'),
            dontShowAgainId: 'adminEStoreOrders1'
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  /**
   * Animation onboarding
   */
  adminAnimationNewsFeed(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeDashboardSteps([
          {
            intro: this.translate('onboarding.admin-animation-news-feed-1'),
            dontShowAgainId: 'adminAnimationNewsFeed1'
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  /**
   * Coins onboarding
   */
  adminRewardsCoins(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        dontShowAgainId: 'adminRewardsCoins',
        steps: this.mergeDashboardSteps([
          {
            element: document.querySelector('.content table tbody tr:first-child'),
            intro: this.translate('onboarding.admin-rewards-coins-1'),
          },
          {
            element: document.querySelector('.buttons-part'),
            intro: this.translate('onboarding.admin-rewards-coins-2'),
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  /**
   * Cash onboarding
   */
  adminRewardsCash(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        dontShowAgainId: 'adminRewardsCash',
        steps: this.mergeDashboardSteps([
          {
            intro: this.translate('onboarding.admin-rewards-cash-1'),
          },
          {
            element: document.querySelector('.export-button'),
            intro: this.translate('onboarding.admin-rewards-cash-2'),
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  /**
   * Cash onboarding
   */
  adminRewardsGifts(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        dontShowAgainId: 'adminRewardsGifts',
        steps: this.mergeDashboardSteps([
          {
            intro: this.translate('onboarding.admin-rewards-gifts-1'),
          },
          {
            element: document.querySelector('.export-button'),
            intro: this.translate('onboarding.admin-rewards-gifts-2'),
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  /**
   * Members onboarding
   */
  adminMembersUsers(scrollEl = null) {
    setTimeout(() => {
      const permissions = this.accessServ.permissionsForDashboard();
      const steps = [];

      if (permissions?.permissions.User?.add) {
        steps.push({
          element: document.querySelector('.invite-button'),
          intro: this.translate('onboarding.admin-members-users-1'),
          dontShowAgainId: 'adminMembersUsers1'
        });
      }

      steps.push({
        element: document.querySelector('.manage-field-button'),
        intro: this.translate('onboarding.admin-members-users-2'),
        dontShowAgainId: 'adminMembersUsers2'
      });

      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeDashboardSteps(steps),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 800);
  }

  adminMembersTeams(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeDashboardSteps([
          {
            intro: this.translate('onboarding.admin-members-teams-1'),
            dontShowAgainId: 'adminMembersTeams1'
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 800);
  }

  adminChallenges(scrollEl = null) {
    setTimeout(() => {
      this.setupIntroJs({
        runOnMobile: false,
        steps: this.mergeDashboardSteps([
          {
            element: document.querySelector('.models-button'),
            intro: this.translate('onboarding.admin-challenges-1'),
            dontShowAgainId: 'adminChallenges1'
          }
        ]),
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 800);
  }

  adminChallengesModelsStep2(scrollEl = null, isTeam = false) {
    const keyParticipants = isTeam ? 'challenges.help.model-participants-team' : 'challenges.help.model-participants';

    if (this.isAdminChallengesModelsStep2Displayed) {
      return;
    }

    setTimeout(() => {

      const steps = [
        {
          element: document.querySelector('.users-list'),
          intro: this.commonServ.translate(keyParticipants),
        },
        {
          element: document.querySelector('.field-community'),
          intro: this.commonServ.translate('challenges.help.model-community')
        }
      ];

      this.setupIntroJs({
        runOnMobile: false,
        dontShowAgainId: 'adminChallengesModelsStep2',
        steps,
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);

      this.isAdminChallengesModelsStep2Displayed = true;
    }, 500);
  }

  clientProfile(scrollEl = null) {
    setTimeout(() => {
      let steps = [];
      if (this.commonServ.getCurrentPlatform()?.is_admin) {
        steps.push({
          intro: this.video('clientAdminProfile'),
          tooltipClass: 'introjs-video',
          dontShowAgainId: 'clientAdminProfile'
        });
      }
      steps = steps.concat([
        {
          element: document.querySelector('.avatar'),
          intro: this.translate('onboarding.client-user-profile-1'),
        },
        {
          element: document.querySelector('.cover'),
          intro: this.translate('onboarding.client-user-profile-2'),
        }
      ]);
      this.setupIntroJs({
        runOnMobile: false,
        dontShowAgainId: 'clientProfile',
        steps,
        ionContentRef: scrollEl
      }).then(/* Nothing to do */);
    }, 1000);
  }

  /**
   * Chore system
   */
  async setupIntroJs(params: IntroParams) {
    if (!params.runOnMobile && params.runOnMobile !== undefined && this.responsiveServ.isMobile) {
      return false;
    }

    const steps = [];
    const hasDontShowAgain = params.steps?.find((step) => step.dontShowAgainId?.length > 0) || false;

    // Check DontShowAgain option
    if (hasDontShowAgain || params.dontShowAgainId?.length > 0) {
      // Force init of dontShowAgain
      if (!this.dontShowAgainInitialized) {
        await firstValueFrom(this.loadConfig());
      }

      // Don't show again if all option is enabled
      if (this.dontShowAgain.all && !this.dontShowAgainBypassAll) {
        return false;
      }

      // Reset bypass
      if (this.dontShowAgainBypassAll) {
        this.dontShowAgainBypassAll = false;
      }

      // Check if dontShowAgain is enabled for each step
      params.steps?.forEach(step => {
        if (step.dontShowAgainId?.length > 0 && this.dontShowAgain[step.dontShowAgainId] === true) {
          return;
        }
        if (step.shallPlayOneTimes && this.introAlreadyDisplayed[step.dontShowAgainId]) {
          return;
        }
        steps.push(step);
      });

      if (steps.length === 0) {
        return false;
      }

      // Look for specific step to show
      let shallDisplayStep = false;
      steps.forEach(step => {
        if (step.dontShowAgainId?.length > 0 && !this.dontShowAgain[step.dontShowAgainId]) {
          shallDisplayStep = true;
        }
      });

      // Check if dontShowAgain is enabled for section and no specific step to show
      if (params.dontShowAgainId?.length > 0 && this.dontShowAgain[params.dontShowAgainId] === true && !shallDisplayStep) {
        return false;
      }

      params.options = Object.assign(params.options || {}, {
        dontShowAgain: true,
        dontShowAgainAutoManage: false,
        dontShowAgainLabel: this.translate('buttons.dont-show-again-pres'),
        dontShowAgainAll: true,
        dontShowAgainAllAutoManage: false,
        dontShowAgainAllLabel: this.translate('buttons.dont-show-again-all'),
        exitCallback: (intro) => {
          // Manage dontShowAgain of each step
          params.steps?.forEach(step => {
            if (step.dontShowAgainId?.length > 0) {
              this.dontShowAgain[step.dontShowAgainId] = this.dontShowAgain[step.dontShowAgainId] || intro.isDontShowAgain() || false;
            }
            // Shall play one times
            if (step.shallPlayOneTimes) {
              this.introAlreadyDisplayed[step.dontShowAgainId] = true;
            }
          });
          // Manage dontShowAgain of each section
          if (params.dontShowAgainId?.length > 0) {
            this.dontShowAgain[params.dontShowAgainId] = this.dontShowAgain[params.dontShowAgainId] || intro.isDontShowAgain() || false;
          }
          // Manage dontShowAgain for all
          if (intro.isDontShowAgainAll()) {
            this.dontShowAgain.all = true;
          }
          this.saveConfig().subscribe(/* Nothing to do */);
        }
      });
    } else {
      params.steps?.forEach(step => steps.push(step));
    }

    this.commonServ.addToQueue(this.introJs(steps, params.ionContentRef, params.options), 'setupIntroJs');

    return true;
  }

  /**
   * Launch introJS
   * @param steps steps of introJs
   * @param ionContentRef IonContent reference
   * @param options other options to be applied to introJs
   */
  introJs(steps: Array<any>, ionContentRef?: IonContent, options?: any): Observable<boolean> {
    return new Observable(observer => {
      options = Object.assign(options || {}, {
        steps,
        nextLabel: this.translate('buttons.next'),
        prevLabel: this.translate('buttons.previous'),
        doneLabel: 'OK'
      });

      if (introJs) {
        if (ionContentRef) {
          ionContentRef.getScrollElement().then(el => {
            introJs().setOptions(Object.assign(options || {}, {scrollElRef: el})).start();

            observer.next(true);
            observer.complete();
          }).catch(e => {
            observer.error(e);
            observer.complete();
          });
        } else {
          introJs().setOptions(options).start();

          observer.next(true);
          observer.complete();
        }
      }
    });
  }

  /**
   * Exit intro js
   */
  introJsExit() {
    if (introJs) {
      introJs().exit();
    }
  }

  private translate(key: TranslateParams, params?: HashMap, lang?: string) {
    return this.commonServ.translate(key, params, lang);
  }

  private loadConfig() {
    return this.stdRequest(this.http.get(`${ this.rootApi }/dont-show-again`)).pipe(
      tap((response: any) => {
        if (response?.settings?.length === 0) {
          this.dontShowAgain = {};
        } else {
          this.dontShowAgain = response?.settings || {};
        }
        this.dontShowAgainInitialized = true;
      })
    );
  }

  private saveConfig() {
    const body = new FormData();
    body.append('settings', JSON.stringify(this.dontShowAgain));
    return this.stdRequest(this.http.post(`${ this.rootApi }/dont-show-again`, body));
  }
}

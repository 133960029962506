import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export abstract class HttpBaseService {

  rootApi = environment.apiRoot;

  constructor(@Inject(HttpClient) protected http: HttpClient) {
  }

  /**
   * Make a standard request which manage standard response
   * @param request request to do
   */
  stdRequest<T>(request: Observable<any>): Observable<T> {
    return request.pipe(
      switchMap((response: any) => {
        if (response?.error) {
          return throwError(response);
        } else {
          return of(response);
        }
      })
    );
  }

  /**
   * Make an upload request and report progress status
   * @param url endpoint url
   * @param formData datas
   * @param progressEvent event to report progression
   * @param headers headers
   */
  uploadRequest<T>(url: string,
                   formData: FormData,
                   progressEvent?: BehaviorSubject<number>,
                   headers?: HttpHeaders): Observable<T> {
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      headers
    });

    return new Observable((observer) => {
      // HERE => this.popup.showProgress(0);
      this.http.request(req).subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            // This is an upload progress event. Compute and show the % done:
            /** @var {HttpProgressEvent} event */
            if (progressEvent) {
              progressEvent.next(Math.round(100 * event.loaded / event.total));
            }
          } else if (event instanceof HttpResponse) {
            // HERE => Upload finished
            if ((event.body as any)?.error) {
              observer.error(event.body);
            } else {
              observer.next(event.body as T);
              observer.complete();
            }
          }
        },
        error: (err) => {
          console.log(err);
          observer.error(err);
        }
      });
    });
  }

  /**
   * Remove mat tooltip
   * TODO: check for better integration
   * There is an existing issue about tooltip stuck after route change
   * -> https://github.com/angular/components/issues/11478
   *
   * The following process shall be executed after each page initialization that request mat-tooltip removing/refreshing
   * So it is provided by this class to be reachable for all component using route navigation
   * Other solution is to use Navigation event (NavigationEnd) but it add a additional event to be supervised,
   * not recommanded  for optimization
   *
   * Other possible solution: https://www.npmjs.com/package/ng2-tooltip-directive
   */
  removeMatTooltip() {
    while (document.getElementsByTagName('mat-tooltip-component').length > 0) {
      document.getElementsByTagName('mat-tooltip-component')[0].remove();
    }
  }
}


/**
 * Client permissions objects
 */
export interface PermissionsForSettingsInterface {
  isThemingEnabled: boolean;
  isUnlimitedChallengesEnabled: boolean;

  isUsersPostsAllowed: boolean;
  isTeamFeedAllowed: boolean;

  isHideDisabledRoute: boolean;
  isHideHelpAdmin: boolean;
  isHideHelpClient: boolean;
  isHideEarningsChart: boolean;
  isHideSponsorship: boolean;


  buildPermissions(routeList: any): void;
  resetPermissions(): void;
}

export class PermissionsForSettingsModel implements PermissionsForSettingsInterface {
  isThemingEnabled = false;
  isUnlimitedChallengesEnabled = false;
  isUsersPostsAllowed = false;
  isTeamFeedAllowed = false;

  isHideDisabledRoute = false;
  isHideHelpAdmin = false;
  isHideHelpClient = false;
  isHideEarningsChart = false;
  isHideSponsorship = false;

  /**
   * Build current permissions of model
   * @param routeList route list
   */
  buildPermissions(routeList: any) {
    this.resetPermissions();

    if (routeList) {
      const routeListArray = Object.keys(routeList).map((key) => {
        return routeList[key];
      });

      this.isThemingEnabled = routeListArray.indexOf('theming') > -1;
      this.isUnlimitedChallengesEnabled = routeListArray.indexOf('unlimitedChallenges') > -1;
      this.isUsersPostsAllowed = routeListArray.indexOf('allowUsersPosts') > -1;
      this.isTeamFeedAllowed = routeListArray.indexOf('allowTeamFeed') > -1;
      this.isHideDisabledRoute = routeListArray.indexOf('hideDisabledRoute') > -1;
      this.isHideHelpAdmin = routeListArray.indexOf('hideHelpAdmin') > -1;
      this.isHideHelpClient = routeListArray.indexOf('hideHelpClient') > -1;
      this.isHideEarningsChart = routeListArray.indexOf('hideEarningsChart') > -1;
      this.isHideSponsorship = routeListArray.indexOf('hideSponsorship') > -1;
    }
  }

  /**
   * Reset permissions
   */
  resetPermissions() {
    this.isThemingEnabled = false;
    this.isUnlimitedChallengesEnabled = false;
    this.isUsersPostsAllowed = false;
    this.isTeamFeedAllowed = false;
    this.isHideDisabledRoute = false;
    this.isHideHelpAdmin = false;
    this.isHideHelpClient = false;
    this.isHideEarningsChart = false;
    this.isHideSponsorship = false;
  }
}

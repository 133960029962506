import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { NewsFeedService, UsersService } from './api/client';
import {
  FeedTagData,
  FeedTagInterface, FeedSortTagData,
  ReqFeedTagsInterface,
  ReqTeamsAndMembersInterface,
  TeamFeedInterface
} from '@common/interfaces/api/client';
import { filter, first, map, tap } from 'rxjs/operators';
import { ConnectionService } from './connection.service';

/**
 * This service manage the team, customers and tags (categories)
 * Load the right tags according team feed
 */
@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private teamFeedState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private teamsFeedState: BehaviorSubject<TeamFeedInterface[]> = new BehaviorSubject<TeamFeedInterface[]>(undefined);

  private tags: ReqFeedTagsInterface;
  private tagsTeamFeed: number;
  private tagsPlatformId: number;

  private teamsAndMembersState: BehaviorSubject<ReqTeamsAndMembersInterface> = new BehaviorSubject(undefined);
  private teamsAndMembersPlatformId: number;
  private teamsFeedPlatformId: number;

  private initialized: boolean = false;
  private platformId: number;

  constructor(protected feedServ: NewsFeedService,
              protected connectionServ: ConnectionService,
              protected usersServ: UsersService) {
  }

  /**
   * Initialize service
   */
  init() {
    if (!this.initialized) {
      // Retrieve data from tags
      this.getTags().subscribe();

      // Retrieve data from teams and members
      this.getTeamsAndMembers().subscribe();


      // Update data when platform change
      this.connectionServ.getPlatformState().subscribe(platform => {
        if (platform && this.platformId !== platform.id_action) {
          this.platformId = platform.id_action;
          this.getTeamFeeds(true).subscribe(() => {
            // Select general feed
            if (this.teamFeedState.getValue() !== 0) {
              this.teamFeedState.next(0);
            }
          });
        }
      });

      // Delayed subscription in order to let platform initializing
      setTimeout(() => {
        // Update data when team feed change
        this.teamFeedState.subscribe(() => {
          this.getTags().subscribe();
        });
      }, 500);

      this.initialized = true;
    }
  }

  /**
   * Return the team feed state
   */
  getTeamFeedState() {
    return this.teamFeedState.asObservable();
  }

  /**
   * Return the team feed value
   */
  getTeamFeedValue() {
    return this.teamFeedState.getValue();
  }

  /**
   * Return the list of teams for feed state
   */
  getTeamFeedsState(initialize = false) {
    if (initialize && !this.teamsFeedState.getValue()) {
      this.getTeamFeeds().subscribe();
    }
    return this.teamsFeedState.asObservable();
  }

  /**
   * Return the list of teams for feed value
   */
  getTeamFeedsValue() {
    return this.teamsFeedState.getValue();
  }

  /**
   * Get tags according team feed
   * @param reload force reload from backend
   */
  getTags(reload = false): Observable<Array<FeedTagInterface>> {
    const idTeamFeed = this.teamFeedState.getValue();
    const idPlatform = this.connectionServ.getSelectedPlatformValue()?.id_action;
    if (this.tags && this.tagsTeamFeed === idTeamFeed && this.tagsPlatformId === idPlatform && !reload) {
      return of(this.tags.postCategories);
    } else {
      return this.feedServ.getTags(idTeamFeed > 0 ? idTeamFeed : null).pipe(
        tap(response => {
          this.tags = response;
          this.tagsTeamFeed = idTeamFeed;
          this.tagsPlatformId = idPlatform;
        }),
        map(response => response.postCategories)
      );
    }
  }

  /**
   * Add post tag by including team feed
   * @param data tag data
   */
  addPostTag(data: FeedTagData) {
    const idTeamFeed = this.teamFeedState.getValue();
    if (idTeamFeed) {
      data.id_team_feed = idTeamFeed;
    }
    return this.feedServ.addPostTag(data);
  }

  /**
   * Update post tag by including team feed
   * @param idTag id tag to update
   * @param data tag data
   */
  updatePostTag(idTag: number, data: FeedTagData) {
    const idTeamFeed = this.teamFeedState.getValue();
    if (idTeamFeed) {
      data.id_team_feed = idTeamFeed;
    }
    return this.feedServ.updatePostTag(idTag, data);
  }

  /**
   * Delete post tag by including team feed
   * @param idTag id tag to update
   */
  deletePostTag(idTag: number) {
    return this.feedServ.deletePostTag(idTag);
  }

  /**
   * Used to sort all post tag in one time
   * @param tags  array of tag objects
   */
  sortPostTags(tags: FeedSortTagData[]) {
    return this.feedServ.sortPostTags(tags);
  }

  /**
   * Get teams and members
   */
  getTeamsAndMembers(): Observable<ReqTeamsAndMembersInterface> {
    const idPlatform = this.connectionServ.getSelectedPlatformValue()?.id_action;
    const teamsAndMembers = this.teamsAndMembersState.getValue();
    // If team and members is in progress, wait until response receive
    if (teamsAndMembers === null) {
      return this.teamsAndMembersState.asObservable().pipe(
        filter(res => res !== null),
        first()
      );
    } else {
      if (teamsAndMembers && this.teamsAndMembersPlatformId === idPlatform) {
        return of(teamsAndMembers);
      } else {
        this.teamsAndMembersState.next(null);
        return this.usersServ.getTeamsAndMembers().pipe(
          tap(response => {
            this.teamsAndMembersState.next(response);
            this.teamsAndMembersPlatformId = idPlatform;
          })
        );
      }
    }
  }

  /**
   * Get teams for feed
   */
  getTeamFeeds(reload = false): Observable<TeamFeedInterface[]> {
    const platform = this.connectionServ.getSelectedPlatformValue();
    const teamsFeed = this.teamsFeedState.getValue();

    if (platform?.team_feed) {
      // Avoid many call of API endpoint
      if (teamsFeed === null) {
        return this.teamsFeedState.asObservable().pipe(
          filter(res => res !== null)
        );
      } else {
        if (teamsFeed && this.teamsFeedPlatformId === platform?.id_action && !reload) {
          return of(teamsFeed);
        } else {
          this.teamsFeedState.next(null);
          return this.usersServ.getTeamFeeds().pipe(
            tap(response => {
              this.teamsFeedState.next(response.teams);
              this.teamsFeedPlatformId = platform?.id_action;
            }),
            map(response => response.teams)
          );
        }
      }
    } else {
      return of(null);
    }
  }

  /**
   * Select the team feed
   * @param id team feed id
   */
  selectTeamFeed(id: number) {
    if (id !== this.teamFeedState.getValue()) {
      this.teamFeedState.next(id);
    }
  }

  /**
   * Get the selected feed
   */
  getSelectedTeamForFeed(): TeamFeedInterface | null {
    if (this.teamFeedState.getValue() <= 0 || !this.teamsFeedState.getValue()) {
      return null;
    }
    return this.teamsFeedState.getValue().find(team => team.id_action_equipe === this.teamFeedState.getValue());
  }

  /**
   * Get the filter data
   */
  getFilterData() {
    return forkJoin({
      teamsAndMembers: this.getTeamsAndMembers(),
      tags: this.getTags()
    });
  }

  /**
   * Apply view all posts
   * @param idTeam id of team
   */
  applyViewAllPosts(idTeam?: number) {
    if (idTeam > 0) {
      this.feedServ.viewAllPosts(idTeam).subscribe();
      return true;
    }
    return false;
  }

  /**
   * Flush data
   */
  flush() {
    this.teamFeedState.next(0);
    this.teamsFeedState.next(undefined);

    this.tags = undefined;
    this.tagsTeamFeed = undefined;
    this.tagsPlatformId = undefined;

    this.teamsAndMembersState.next(undefined);
    this.teamsAndMembersPlatformId = undefined;
    this.teamsFeedPlatformId = undefined;

    this.initialized = false;
  }


}

import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import packageJson from '../../../package.json';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${ lang }.json?v=${ packageJson.version }`);
  }
}

@NgModule({
  imports: [
    TranslocoMessageFormatModule.forRoot(),
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        en: 'en-US',
        es: 'es-ES',
        pt: 'pt-PT',
        fr: 'fr-FR',
        de: 'de-DE',
        it: 'it-RO'
      },
      localeConfig: {
        global: {
          decimal: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            useGrouping: true,
          }
        }
      }
    })
  ],
  exports: [
    TranslocoModule
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'fr', 'es', 'pt', 'de', 'it'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    },
    {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader}
  ]
})
export class TranslocoRootModule {
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '@common/services/common.service';
import { NavController } from '@ionic/angular';

/**
 * Guard to control access to dashboard
 */
@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {

  constructor(private commonServ: CommonService,
              private navCtrl: NavController) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Wait 50ms to let platform initialized
      setTimeout(() => {
        const canAccess = this.commonServ.canAccessDashboard();

        if (!canAccess) {
          this.navCtrl.navigateRoot(['/']);
          reject('Can\' access');
        }

        resolve(canAccess);
      }, 50);
    });
  }
}

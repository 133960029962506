import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import {
  AdminTeamData, AdminTeamOptionsData,
  AdminUsersFilterData, ReqAdminTeamInterface, ReqAdminTeamsInterface,
} from '@common/interfaces/api/admin';
import { StandardResponseInterface } from '@common/interfaces/api';
import { PaginatorInterface } from '@common/interfaces/common.interface';
import { toNumber } from '@common/helpers/utils';

@Injectable({providedIn: 'root'})
export class AdminTeamsService extends HttpBaseService {


  /**
   * Get teams from administration
   * @param data data options
   * @param paginator paginator options
   */
  teams(data?: AdminUsersFilterData, paginator?: PaginatorInterface): Observable<ReqAdminTeamsInterface> {
    const body = new FormData();

    if (data?.search) {
      body.append('search', data.search);
    }

    if (paginator) {
      let page = paginator?.page || 1;
      let max = paginator?.max || 30;
      page = page < 1 ? 1 : page;
      max = max < 1 ? 1 : max;
      const start = (page - 1) * max;
      body.append('debut', start.toString());
      body.append('limit', max.toString());
    }

    return this.stdRequest<ReqAdminTeamsInterface>(this.http.post<ReqAdminTeamsInterface>(`${ this.rootApi }/administration/Team`, body)).pipe(
      switchMap(response => {
        response?.equipes?.forEach(team => {
          // Cast data: TODO Shall be done by backend
          team.id_action = toNumber(team.id_action);
          team.id_action_equipe = toNumber(team.id_action_equipe);
        });
        return of(response);
      })
    );
  }

  /**
   * Get a team information
   * @param idTeam
   */
  team(idTeam: string): Observable<ReqAdminTeamInterface> {
    const body = new FormData();
    body.append('id_team', idTeam);
    return this.stdRequest(this.http.post<ReqAdminTeamsInterface>(`${ this.rootApi }/administration/Team`, body));
  }

  /**
   * Add new team to platform
   * @param team team data object
   * @param teamAvatar team avatar file
   * @param progressEvent event to report progress
   */
  addTeam(team: AdminTeamData,
          teamAvatar?: File,
          progressEvent?: BehaviorSubject<number>): Observable<StandardResponseInterface> {
    return this.addEditTeam(team, null, teamAvatar);
  }

  /**
   * Update team from platform
   * @param idTeam id of team
   * @param team team data object
   * @param teamAvatar team avatar file
   * @param progressEvent event to report progress
   */
  updateTeam(idTeam: string,
             team: AdminTeamData,
             teamAvatar?: File,
             progressEvent?: BehaviorSubject<number>): Observable<StandardResponseInterface> {
    return this.addEditTeam(team, idTeam, teamAvatar);
  }

  /**
   * Delete team from platform
   * @param idTeam id of team
   */
  deleteTeam(idTeam: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_team', idTeam);
    return this.stdRequest(
      this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Team/delete`, body)
    );
  }

  /**
   * Update options
   * @param idTeam id of team
   * @param options option to update
   */
  updateOptions(idTeam: string, options: AdminTeamOptionsData): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_team', idTeam);
    body.append('options', JSON.stringify(options));
    return this.stdRequest(
      this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Team/setOptions`, body)
    );
  }

  /**
   * Add new team or edit to platform
   * @param team team data object
   * @param idTeam id of team
   * @param teamAvatar team avatar file
   * @param progressEvent event to report progress
   * @private
   */
  private addEditTeam(team: AdminTeamData,
                      idTeam?: string,
                      teamAvatar?: File,
                      progressEvent?: BehaviorSubject<number>): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('team', JSON.stringify(team));
    if (teamAvatar) {
      body.append('image', teamAvatar);
    }
    if (idTeam) {
      body.append('id_team', idTeam);
    }
    return this.uploadRequest<StandardResponseInterface>(
      `${ this.rootApi }/administration/Team/${ idTeam ? 'update' : 'add' }`, body, progressEvent
    );
  }

}

import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmojiPanelController } from '@common/components/utils/emoji-panel';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import { TeamService } from '@common/services/team.service';
import { Utils } from '@common/helpers/utils';
import { AttachmentItemInterface, FieldItemInterface } from './editor-input.interface';
import { CameraService } from '@common/services/camera.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EditorBaseComponent } from '@common/components/forms/editor/_common/editor.base';

interface AttachmentItemDisplayInterface {
  id?: number;
  url: string;
  name: string;
  isBlob: boolean;
}

@Component({
  selector: 'app-editor-input',
  templateUrl: './editor-input.component.html',
  styleUrls: ['./editor-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorInputComponent),
      multi: true,
    },
  ],
})
export class EditorInputComponent extends EditorBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Magic fields to be added in editor
  @Input() fields: FieldItemInterface[] = [];
  // Manage attachments in editor (undefined to disable)
  @Input() attachments?: AttachmentItemInterface[];
  @Input() maxAttachments: number = 0;
  @Input() attachmentAccept?: string;

  @Output() inputKeyup: EventEmitter<{ value: string, event: KeyboardEvent }> = new EventEmitter<{ value: string; event: any }>();
  @Output() inputKeydown: EventEmitter<{ value: string, event: KeyboardEvent }> = new EventEmitter<{ value: string; event: any }>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() attachmentsChange: EventEmitter<AttachmentItemInterface[]> = new EventEmitter<AttachmentItemInterface[]>();

  isAttachmentsBoxVisible: boolean = false;


  editor: Editor;
  editorToolbar: Toolbar = [
    ['bold', 'italic', 'underline'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['link']
  ];

  attachmentsForDisplay: AttachmentItemDisplayInterface[] = [];

  constructor(protected emojiCtrl: EmojiPanelController,
              protected teamServ: TeamService,
              protected cameraServ: CameraService,
              protected sanitizer: DomSanitizer) {
    super(emojiCtrl, teamServ);
  }

  ngOnInit() {
    this.editor = new Editor({
      inputRules: false
    });

    this.init();

    this.buildAttachmentsForDisplay();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.attachments && this.attachments !== undefined) {
      this.buildAttachmentsForDisplay();
    }
  }

  applyAttachmentsChange() {
    const attachments = this.attachments?.map(attachment => {
      return {url: attachment.url, id: attachment.id > 0 ? attachment.id : undefined, file: attachment.file};
    });
    this.attachmentsChange.emit(attachments);
  }

  /**
   * Get formatted message according input and mentions
   */
  getFormattedMessage(inputMessage: any) {
    let message = inputMessage;

    // HTML type
    if (message && typeof message === 'object') {
      message = toHTML(message as any);
    } else if (typeof message !== 'string') {
      message = '';
    }

    return super.getFormattedMessage(message);
  }


  /**
   * Insert emoji
   * @param event event of click
   * @param textEl text element
   */
  insertEmoji(event: Event, textEl: any) {
    if (!this.disabled) {
      this.emojiCtrl.open({
        textEl,
        event
      })?.then(/* Nothing to do */);
    }
  }

  insertField(value: string) {
    if (!this.disabled) {
      this.editor?.commands.insertText('{' + value + '}').focus().exec();
      this.valueChange.emit(this.getFormattedMessage(this.message));
    }
  }

  addAttachment() {
    if (this.maxAttachments > 0 && this.attachments?.length >= this.maxAttachments) {
      return;
    }
    this.cameraServ.getMedia({
      multiple: true,
      type: 'mixed',
      accept: this.attachmentAccept
    }).then((files: File[]) => {
      files?.forEach(file => {
        if (this.maxAttachments <= 0 || (this.attachments?.length || 0) < this.maxAttachments) {
          this.attachments?.push({
            file
          });
        }
      });
      this.buildAttachmentsForDisplay();
      this.applyAttachmentsChange();
    });
  }

  buildAttachmentsForDisplay() {
    if (this.attachments === undefined) {
      return;
    }
    this.attachmentsForDisplay = [];
    let indexAddAttachment = -1;
    this.attachments.forEach(attachment => {
      let newUrl: any;
      if (attachment.url) {
        newUrl = attachment.url;
      } else if (attachment.file) {
        newUrl = URL.createObjectURL(attachment.file);
        if (newUrl?.startsWith('blob')) {
          newUrl = this.sanitizer.bypassSecurityTrustResourceUrl(newUrl);
        }
      }
      // Add Index
      if (!!attachment.file) {
        attachment.id = indexAddAttachment;
        indexAddAttachment--;
      }
      // Push for display
      this.attachmentsForDisplay.push({
        id: attachment.id,
        url: newUrl,
        name: attachment.file?.name || Utils.extractFilename(attachment.url),
        isBlob: !!attachment.file
      });
    });
  }

  removeAttachment(id: number) {
    this.attachments = this.attachments?.filter(attachment => attachment.id !== id);
    this.buildAttachmentsForDisplay();
    this.applyAttachmentsChange();
  }
}

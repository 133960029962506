import { Component, Input } from '@angular/core';
import { UserBaseInterface } from '@common/interfaces/common.interface';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-img-avatar',
  templateUrl: './img-avatar.component.html',
  styleUrls: ['./img-avatar.component.scss'],
})
export class ImgAvatarComponent {

  @Input() data: UserBaseInterface;

  @Input() useIonAvatar = false;
  @Input() enableNameTooltip = false;
  @Input() linkToProfile = false;

  constructor(private navCtrl: NavController) { }

  navToProfile() {
    if (this.linkToProfile) {
      this.navCtrl.navigateForward(['/profile', this.data.id_customer]);
    }
  }

}

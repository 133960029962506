import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffersModal } from './offers.modal';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { OffersController } from './offers.controller';
import { ToolbarModalModule } from '@common/components/modals/toolbar-modal';



@NgModule({
  declarations: [
    OffersModal
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    ToolbarModalModule
  ],
  exports: [
    OffersModal
  ],
  providers: [
    OffersController
  ]
})
export class OffersModule { }

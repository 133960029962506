import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'nl2sp'
})
export class Nl2spPipe implements PipeTransform {

    constructor() { }

    public transform(value: string): string {
        return value.replace(/\n/g, ' ');
    }
}

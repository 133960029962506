import { StandardConfigFieldsInterface } from '@common/interfaces/api/client';


export interface ProfileFieldsModalProps {
  idCustomer: number;
  fields: StandardConfigFieldsInterface;
  filteredFields?: FilteredFieldsInterface;
  showFields?: boolean;
  allowDisable?: boolean;
  editMode?: ProfileFieldsEditType;
  isAdminContext?: boolean;
  userName?: string;
}

export interface ProfileFieldsModalResult {
  data: StandardConfigFieldsInterface;
  role: 'apply' | 'cancel' | 'backdrop';
}

export interface FilteredFieldsInterface {
  basic_fields?: string[];
  custom_fields?: string[];
}

export type ProfileFieldsEditType = 'none' | 'allow' | 'default';

import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  AdminQuizData, ReqAdminQuizInterface, ReqAdminQuizResultsInterface, ReqAdminQuizzesInterface,
} from '@common/interfaces/api/admin';
import { switchMap } from 'rxjs/operators';
import { StandardExportInterface, StandardResponseInterface } from '@common/interfaces/api';

@Injectable()
export class AdminQuizzesService extends HttpBaseService {

  /**
   * Used to get the list of all platform quizzes.
   */
  quizzes(): Observable<ReqAdminQuizzesInterface> {
    return this.stdRequest<ReqAdminQuizzesInterface>(
      this.http.post<ReqAdminQuizzesInterface>(`${ this.rootApi }/administration/Quizz`, null)
    ).pipe(switchMap(response => {
      response.quizzes = [].concat(
        response?.quizzs?.in_progress,
        response?.quizzs?.finished
      );
      return of(response);
    }));
  }

  /**
   * Used to get a quiz.
   * @param idQuiz id of quiz
   */
  quiz(idQuiz: string): Observable<ReqAdminQuizInterface> {
    const body = new FormData();
    body.append('id_quizz', idQuiz);
    return this.stdRequest(this.http.post<ReqAdminQuizInterface>(`${ this.rootApi }/administration/Quizz`, body));
  }


  /**
   * Used to create a new quiz.
   * @param data data object
   * @param picture picture to add
   * @param progressEvent event to report progress
   */
  add(data: AdminQuizData,
      picture?: File,
      progressEvent?: BehaviorSubject<number>): Observable<StandardResponseInterface> {
    return this.addEdit('add', data, picture, progressEvent);
  }

  /**
   * Used to update a new quiz.
   * @param idQuiz id of quiz
   * @param data data object
   * @param picture picture to add
   * @param progressEvent event to report progress
   */
  update(idQuiz: string,
         data: AdminQuizData,
         picture?: File,
         progressEvent?: BehaviorSubject<number>): Observable<StandardResponseInterface> {
    return this.addEdit('update', data, picture, progressEvent, idQuiz);
  }

  /**
   * Used to create a new quiz or edit.
   * @param type add or update
   * @param data data object
   * @param picture picture to add
   * @param progressEvent event to report progress
   * @param idQuiz id of quiz
   */
  addEdit(type: 'add' | 'update',
          data: AdminQuizData,
          picture?: File,
          progressEvent?: BehaviorSubject<number>,
          idQuiz?: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    if (idQuiz && type === 'update') {
      body.append('id_quizz', idQuiz);
    }
    // Rebuild binary files
    data?.questions?.forEach((question, idxQst) => {
      const indexQst = question?.id_question?.toString() || idxQst.toString();
      if (question.image) {
        body.append(`question_image[${indexQst}]`, question.image);
        delete data.questions[idxQst].image;
      }
      if (question.videoUrl) {
        body.append(`embed_video[${indexQst}]`, question.videoUrl);
        delete data.questions[idxQst].videoUrl;
      }
      if (question.answer_type === '1') {
        question.answers?.forEach((answer, idxAns) => {
          const indexAns = answer.id_reponse?.toString() || idxAns.toString();
          if (answer.image) {
            body.append(`answer_image[${indexQst}][${indexAns}]`, answer.image);
            delete data.questions[idxQst].answers[idxAns].image;
          }
        });
      }
    });
    body.append('quizz', JSON.stringify(data));
    if (picture) {
      body.append('image', picture);
    }
    return this.uploadRequest<StandardResponseInterface>(`${ this.rootApi }/administration/Quizz/${type}`, body, progressEvent);
  }


  /**
   * Used to delete a quiz.
   * @param idQuiz id of survey
   */
  delete(idQuiz: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_quizz', idQuiz);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Quizz/delete`, body));
  }

  /**
   * Used to get results of a quiz.
   * @param idQuiz if of quiz
   */
  results(idQuiz: string): Observable<ReqAdminQuizResultsInterface> {
    const body = new FormData();
    body.append('id_quizz', idQuiz);
    return this.stdRequest(this.http.post<ReqAdminQuizResultsInterface>(`${ this.rootApi }/administration/Quizz/getResults`, body));
  }

  /**
   * Used to export results of a quiz.
   * @param idQuiz id of quiz
   */
  exportResults(idQuiz: string): Observable<StandardExportInterface> {
    const body = new FormData();
    body.append('id_quizz', idQuiz);
    body.append('get_csv', '1');
    return this.stdRequest(this.http.post<StandardExportInterface>(`${ this.rootApi }/administration/Quizz/getResults`, body));
  }

  /**
   * Used t delete result of quiz
   * @param idQuiz id of quiz
   * @param idAnswer id of answer
   */
  deleteResults(idQuiz: string, idAnswer: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_quizz', idQuiz);
    body.append('id_user_answer', idAnswer);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Quizz/deleteResult`, body));
  }

}

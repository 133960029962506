import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AdminUsersFilterData,
  ReqAdminUserFieldsInterface,
  ReqAdminSearchFiltersInterface,
  ReqAdminUsersInterface,
  ReqAdminUserUpdateInterface,
  ReqAdminDeliveryAddressInterface,
  ReqAdminTeamsAndChallengesInterface,
  ReqAdminRequestPlatformTokenInterface,
  ReqAdminUserProfileFieldsInterface,
  AdminUserUpdateData,
  ReqAdminUserInterface,
  ReqAdminConsistencyFieldsInterface, AdminConsistencyFieldsValidateProps
} from '@common/interfaces/api/admin';
import { PaginatorInterface } from '@common/interfaces/common.interface';
import { StandardResponseInterface } from '@common/interfaces/api';
import {
  ConfigFieldInterface,
  DeliveryUserAddressInterface,
  StandardConfigFieldsInterface
} from '@common/interfaces/api/client';
import { HttpHeaders } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class AdminUsersService extends HttpBaseService {

  /**
   * Get users from administration
   * @param data data options
   * @param paginator paginator options
   */
  users(data?: AdminUsersFilterData, paginator?: PaginatorInterface): Observable<ReqAdminUsersInterface> {
    const body = new FormData();

    if (data?.search) {
      body.append('search', data.search);
    }
    if (data?.disabled) {
      body.append('disabled', data.disabled ? '1' : '0');
    }

    if (data?.getCsv) {
      body.append('get_csv', data.getCsv ? '1' : '0');
    } else {

      let page = paginator?.page || 1;
      let max = paginator?.max || 30;
      page = page < 1 ? 1 : page;
      max = max < 1 ? 1 : max;
      const start = (page - 1) * max;
      body.append('start', start.toString());
      body.append('limit', max.toString());
    }

    return this.stdRequest(this.http.post<ReqAdminUsersInterface>(`${ this.rootApi }/administration/User`, body));
  }

  /**
   * Get an user data
   * @param idUser id of user
   */
  user(idUser: number): Observable<ReqAdminUserInterface> {
    const body = new FormData();
    body.append('id_customer', idUser.toString());
    return this.stdRequest(this.http.post<ReqAdminUserInterface>(`${ this.rootApi }/administration/User`, body));
  }

  /**
   * Used to get the list filters needed to search for users into platform
   * @param isTeam concern team ?
   */
  searchFilters(isTeam = false): Observable<ReqAdminSearchFiltersInterface> {
    const body = new FormData();
    if (isTeam) {
      body.append('team_filter', '1');
    }
    return this.stdRequest(this.http.post<ReqAdminSearchFiltersInterface>(`${ this.rootApi }/administration/getSearchFilters`, body));
  }

  /**
   * Disable an user
   * @param idUser id of user
   */
  disableUser(idUser: number): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('user', idUser.toString());
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/disable`, body));
  }

  /**
   * Enable an user
   * @param idUser id of user
   */
  enableUser(idUser: number): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('user', idUser.toString());
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/enable`, body));
  }

  /**
   * Invite an user again
   * @param idUser id of user
   */
  inviteUser(idUser: number): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_user', idUser.toString());
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/reInvite`, body));
  }

  /**
   * Invite some users again
   * @param idsUser id of user
   */
  inviteUsers(idsUser: Array<number>): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('ids_user', JSON.stringify(idsUser));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/reInvite`, body));
  }

  /**
   * Update user fields
   * @param data user data
   * @param userAvatar user avatar
   * @param userCover user cover
   */
  updateUser(data: AdminUserUpdateData,
             userAvatar?: File,
             userCover?: File): Observable<ReqAdminUserUpdateInterface> {
    const body = new FormData();
    body.append('fields', JSON.stringify(data.fields));
    body.append('id_customer', data.id_customer.toString());
    if (userAvatar) {
      body.append('profilepic', userAvatar);
    }
    if (userCover) {
      body.append('profilebg', userCover);
    }
    if (data?.disableMandatoryCheck) {
      body.append('disable_mandatory_check', '1');
    }
    return this.stdRequest(this.http.post<ReqAdminUserUpdateInterface>(`${ this.rootApi }/administration/User/update`, body));
  }

  // Get user profile fields
  getProfileFields(): Observable<ReqAdminUserProfileFieldsInterface> {
    return this.stdRequest<ReqAdminUserProfileFieldsInterface>(this.http.post<ReqAdminUserFieldsInterface>(`${ this.rootApi }/administration/User/profileFields`, null));
  }

  // Update user profile fields
  updateProfileFields(fields: StandardConfigFieldsInterface) {
    const body = new FormData();
    body.append('config_fields', JSON.stringify(fields));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/updateProfileFields`, body));
  }

  // Update user profile field
  updateProfileField(field: ConfigFieldInterface) {
    const body = new FormData();
    body.append('field', JSON.stringify(field));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/profileField/update`, body));
  }

  // Add user profile field
  addProfileField(field: ConfigFieldInterface) {
    const body = new FormData();
    body.append('field', JSON.stringify(field));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/profileField/add`, body));
  }

  // Delete user profile field
  deleteProfileField(idField: number) {
    const body = new FormData();
    body.append('id_field', idField.toString());
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/profileField/delete`, body));
  }

  /**
   * Get delivery address
   * @param idUser id of user
   */
  deliveryAddress(idUser: number): Observable<ReqAdminDeliveryAddressInterface> {
    return this.stdRequest(this.http.post<ReqAdminDeliveryAddressInterface>(
      `${ this.rootApi }/administration/User/${ idUser.toString() }/address`,
      null
    ));
  }

  /**
   * Update delivery address
   * @param idUser id of user
   * @param address address
   */
  updateDeliveryAddress(idUser: number, address: DeliveryUserAddressInterface): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('address', JSON.stringify(address));
    return this.stdRequest(this.http.post<StandardResponseInterface>(
      `${ this.rootApi }/administration/User/${ idUser.toString() }/address/update`,
      body
    ));
  }

  /**
   * Get teams and challenges of user
   * @param idUser id of user
   */
  teamsAndChallenges(idUser: number): Observable<ReqAdminTeamsAndChallengesInterface> {
    return this.stdRequest(this.http.post<ReqAdminTeamsAndChallengesInterface>(
      `${ this.rootApi }/administration/User/${ idUser.toString() }/getTeamsAndChallenges`,
      null
    ));
  }

  /**
   * Request user token
   * @param emails emails
   * @param domainReferer domain referer
   */
  requestUserToken(emails: Array<string>, domainReferer?: string): Observable<StandardResponseInterface> {
    const body = new FormData();

    body.append('emails', JSON.stringify(emails));

    let headers = new HttpHeaders();
    if (domainReferer) {
      headers = headers.set('X-Domain-Referer', domainReferer);
    }

    return this.stdRequest(this.http.post<StandardResponseInterface>(
      `${ this.rootApi }/administration/User/requestUserToken`,
      body,
      {headers}
    ));
  }

  /**
   * Request platform token
   * @param renew renew token ?
   * @param validity validity days
   * @param domainReferer domain referer
   */
  requestPlatformToken(renew = false, validity: number = 7, domainReferer?: string): Observable<ReqAdminRequestPlatformTokenInterface> {
    const body = new FormData();

    if (renew) {
      body.append('renew', '1');
    }
    body.append('validity', validity.toString());

    let headers = new HttpHeaders();
    if (domainReferer) {
      headers = headers.set('X-Domain-Referer', domainReferer);
    }

    return this.stdRequest(this.http.post<ReqAdminRequestPlatformTokenInterface>(
      `${ this.rootApi }/administration/User/requestPlatformToken`,
      body,
      {headers}
    ));
  }

  /**
   * Get consistency fields checkup result
   */
  consistencyFields(): Observable<ReqAdminConsistencyFieldsInterface> {
    return this.stdRequest(this.http.post<ReqAdminConsistencyFieldsInterface>(
      `${ this.rootApi }/administration/consistencyFields`,
      null
    ));
  }

  /**
   * Notify users about consistency fields
   * @param users array of id
   */
  consistencyFieldsNotify(users: number[]): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('to_notify', JSON.stringify(users));
    return this.stdRequest(this.http.post<StandardResponseInterface>(
      `${ this.rootApi }/administration/consistencyFields/notify`,
      body
    ));
  }

  /**
   * Validate users fields changes
   * @param props array of accepted/rejected id
   */
  consistencyFieldsValidate(props: AdminConsistencyFieldsValidateProps): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('to_validate', JSON.stringify(props));
    return this.stdRequest(this.http.post<StandardResponseInterface>(
      `${ this.rootApi }/administration/consistencyFields/validate`,
      body
    ));
  }

}

import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { EmojiPanelComponent } from '@common/components/utils/emoji-panel/emoji-panel.component';
import { Editor } from 'ngx-editor';

/**
 * This component display a popover of emoji selection
 */

export interface EmojiPanelOptions {
  // Text element to insert emoji
  textEl?: HTMLTextAreaElement | Editor;
  // called when emoji is selected
  callback?: (value) => void;
  // Event to control popover
  event?: any;
}

@Injectable()
export class EmojiPanelController {

  constructor(private popoverCtrl: PopoverController) { }

  /**
   * Open emoji popover
   * @param options options of panel
   */
  async open(options: EmojiPanelOptions) {
    const modal = await this.popoverCtrl.create({
      component: EmojiPanelComponent,
      componentProps: options,
      cssClass: 'emoji-panel-popover',
      keyboardClose: true,
      showBackdrop: false,
      event: options.event
    });

    modal.onDidDismiss().then(data => {
      if (options.callback) {
        options.callback(data.data);
      }
    });

    return await modal.present();
  }

}

import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CguAgreementModal } from './cgu-agreement.modal';
import { ConnectionService } from '@common/services/connection.service';
import { Observable } from 'rxjs';

export interface CguAgreementOptionsInterface {
  idAction?: number;
  viewCgu?: boolean;
  viewCguMedias?: boolean;
  mode?: 'view' | 'agree';
}

@Injectable()
export class CguAgreementController {

  isOpened: boolean = false;

  constructor(private modalCtrl: ModalController,
              private authServ: ConnectionService) {
  }

  /**
   * Show modal
   * @param props parameters
   * @param callback callback called on dismiss
   */
  async show(props: CguAgreementOptionsInterface, callback?: (choice: boolean) => void) {
    if (this.isOpened) {
      return undefined;
    }

    this.isOpened = true;

    const modal = await this.modalCtrl.create({
      component: CguAgreementModal,
      componentProps: props,
      cssClass: 'default-modals cgu-agreement-modal',
      showBackdrop: true,
      backdropDismiss: false,
      keyboardClose: false
    });

    modal.onDidDismiss().then(data => {
      this.isOpened = false;
      if (callback) {
        callback(data.role === 'backdrop' ? null : data.data);
      }
    });

    return await modal.present();
  }

  /**
   * Show as observable
   * @param props parameters
   * @param callback callback called on choice selection
   */
  showAsObs(props: CguAgreementOptionsInterface, callback?: (choice: boolean) => void): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.show(props, (choice) => {
        if (callback) {
          callback(choice);
        }
        if (!choice) {
          observer.error('User has not agreed');
        } else {
          observer.next(choice);
        }
        observer.complete();
      }).then(/* Nothing to do */)
        .catch(err => {
          observer.error(err);
          observer.complete();
        });
    });
  }

  /**
   * Indicate if this modal shall be show
   */
  public shallBeShow(): boolean {
    const platform = this.authServ.getSelectedPlatformValue();
    return (!platform?.cgu_validated || (!platform.cgu_medias_validated && platform?.require_cgu_medias));
  }

}

import { UserFieldsItemInterface } from '@common/interfaces/api/admin';


export const hasFieldsToManage = (users: UserFieldsItemInterface[]): boolean => {
  let result: boolean = false;

  users.forEach(user => {
    if ((user.consistency_fields.to_notify?.length || 0) > 0) {
      result = true;
    }
    if ((user.consistency_fields.to_validate?.length || 0) > 0) {
      result = true;
    }
    if ((user.consistency_fields.to_update?.length || 0) > 0) {
      result = true;
    }
  });

  return result;
};

export const countNbItemsToManage = (users: UserFieldsItemInterface[]): number => {
  let result: number = 0;

  users.forEach(user => {
    if ((user.consistency_fields.to_notify?.length || 0) > 0) {
      result++;
    }
    if ((user.consistency_fields.to_update?.length || 0) > 0) {
      result++;
    }
    if ((user.consistency_fields.to_validate?.length || 0) > 0) {
      result += (user.consistency_fields.to_validate?.length || 0);
    }
  });

  return result;
};

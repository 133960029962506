import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeComponent } from './tree.component';
import { TreeDatabase } from './tree.database';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    TreeComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatTreeModule,
    MatIconModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule
  ],
  exports: [
    TreeComponent
  ],
  providers: [
    TreeDatabase
  ]
})
export class TreeModule { }

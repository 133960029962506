import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TalkConversation } from '@common/components/app/talk';
import { TalkService } from '@common/components/app/talk/talk.service';
import { TalkConversationsBase } from '@common/components/app/talk/talk-conversations.base';
import { UserProfileInterface } from '@common/interfaces/common.interface';
import { CommonService } from '@common/services/common.service';
import { TalkConversationStartController } from '@common/components/app/talk/conversation-start/conversation-start.controller';
import { Utils } from '@common/helpers/utils';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-talk-popup-conversations',
  templateUrl: './popup-conversations.component.html',
  styleUrls: ['./popup-conversations.component.scss'],
})
export class TalkPopupConversationsComponent extends TalkConversationsBase implements OnInit {

  hasNewMessage: boolean = false;
  isExpanded: boolean = false;

  @Output() selectionChange: EventEmitter<TalkConversation> = new EventEmitter<TalkConversation>();

  private profile?: UserProfileInterface;

  constructor(protected talkServ: TalkService,
              protected commonServ: CommonService,
              protected navCtrl: NavController,
              protected talkConvStartCtrl: TalkConversationStartController) {
    super(talkServ, commonServ);
  }

  ngOnInit() {
    this.profile = this.commonServ.getProfile();
  }

  startConversationAction(event: Event) {
    event.stopPropagation();
    this.startNewConversation();
  }

  /**
   * Wrapper for start conversation
   * @param withUsers users
   * @param subject subject
   */
  startConversation(withUsers: UserProfileInterface[], subject?: string): Promise<any> {
    return new Promise<any>(resolve => {
      super.startConversation(withUsers, subject).then(data => {
        if (data.navigateTo) {
          // Mobile operation
          this.navCtrl.navigateForward(data.url, {queryParams: data.queryParams})?.then(() => {
            resolve(true);
          });
        } else {
          this.onSelectionChange(data.conversation);
        }
      });
    });
  }

  /**
   * Start group conversation
   */
  startNewConversation() {
    this.talkConvStartCtrl.startNewConversation((participants, title) => {
      const otherUsers = (participants as UserProfileInterface[])?.filter(item => Utils.toNumber(item.id_customer) !== Utils.toNumber(this.profile.id_customer));
      this.startConversation(otherUsers, title).then(/* Nothing to do */);
    }).then(/* Nothing to do */);
  }

  onSelectionChange(conversation: TalkConversation) {
    conversation.isRead = true;
    this.selectionChange.emit(conversation);
  }

}

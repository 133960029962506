import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ProfileFieldsComponent } from '@common/components/app/profile-fields/profile-fields.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { PipesModule } from '@common/pipes';
import { MatRadioModule } from '@angular/material/radio';
import { AutocompleteInputModule, DateInputModule, TextInputModule } from '@common/components/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ProfileFieldsController } from './profile-fields.controller';
import { ProfileFieldsModal } from './profile-fields.modal';
import { ToolbarModalModule } from '@common/components/modals/toolbar-modal';


@NgModule({
  declarations: [
    ProfileFieldsComponent,
    ProfileFieldsModal
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    PipesModule,
    MatRadioModule,
    DateInputModule,
    TextInputModule,
    AutocompleteInputModule,
    MatOptionModule,
    MatSelectModule,
    ToolbarModalModule
  ],
  exports: [
    ProfileFieldsComponent
  ],
  providers: [
    ProfileFieldsController
  ]
})
export class ProfileFieldsModule {
}


export interface MentionItemInterface {
  id: string;
  name: string;
  type: 'team' | 'user';
  image: string;
}


export interface FieldItemInterface {
  name: string;
  label?: string;
  labelKey?: string;
}

export interface AttachmentItemInterface {
  id?: number;
  url?: string;
  file?: File;
}

import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorBaseComponent } from '../_common/editor.base';

@Component({
  selector: 'app-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommentInputComponent),
      multi: true,
    },
  ],
})
export class CommentInputComponent extends EditorBaseComponent implements OnInit, ControlValueAccessor {

  ngOnInit() {
    this.init();
  }

  focus() {
    // Delay focus to avoid expression checked error
    setTimeout(() => {
      document.getElementById('textInput')?.focus();
    }, 100);
  }

  /**
   * Insert emoji
   * @param event event of click
   * @param textEl text element
   */
  insertEmoji(event: Event, textEl: any) {
    if (!this.disabled) {
      this.emojiCtrl.open({
        textEl,
        event,
        callback: () => {
            this.message = textEl.value;
            this.applyChange();
        }
      })?.then(/* Nothing to do */);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { NotificationInterface, PlatformInterface } from '@common/interfaces/api';
import { NotificationsBase } from '@app/notifications/notifications.base';
import { GeneralService } from '@common/services/api';
import { ConnectionService } from '@common/services/connection.service';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-notifications-popover',
  templateUrl: './notifications.popover.html',
  styleUrls: ['./notifications.popover.scss'],
})
export class NotificationsPopover extends NotificationsBase implements OnInit {

  notifications: NotificationInterface[];
  viewMore = false;

  private platform: PlatformInterface;

  constructor(protected generaleServ: GeneralService,
              protected popoverCtrl: PopoverController,
              protected connectionServ: ConnectionService,
              protected navCtrl: NavController) {
    super(generaleServ, connectionServ, navCtrl);
  }

  ngOnInit() {
    this.platform = this.connectionServ.getSelectedPlatformValue();

    this.loadNotifications();
  }

  loadNotifications() {
    this.generaleServ.notifications().subscribe(response => {
      this.viewMore =  response.notifications.length > 20;

      this.notifications = response.notifications.slice(0, 20);

      this.notifications?.forEach(notification => {
        this.buildData(notification, this.platform);
      });
    });
  }

  openNotifications() {
    this.navCtrl.navigateForward('/notifications')?.then(/* Nothing to do */);
    this.popoverCtrl.dismiss()?.then(/* Nothing to do */);
  }

  clickAction(notification: NotificationInterface) {
    super.clickAction(notification);
    this.popoverCtrl.dismiss()?.then(/* Nothing to do */);
  }
}

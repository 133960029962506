import { PlatformInterface } from '@common/interfaces/api';
import { PermissionsForSettingsInterface } from '@common/services/access-control/permissions-for-settings.model';

/**
 * Client permissions objects
 */
export interface PermissionsForClientInterface {
  platform: PlatformInterface ;

  isStoreEnabled: boolean;
  isChallengesEnabled: boolean;
  isRoadboxEnabled: boolean;
  isFormsEnabled: boolean;
  isQuizEnabled: boolean;
  isValidateCartEnabled: boolean;
  isTalkEnabled: boolean;
  isFeedEnabled: boolean;
  isRewardsEnabled: boolean;
  isRewardBadgesEnabled: boolean;
  isRewardGiftsEnabled: boolean;

  settings?: PermissionsForSettingsInterface;
  // Valid state of data
  valid: boolean;

  buildPermissions(routeList: any): void;
  resetPermissions(): void;
}

export class PermissionsForClientModel implements PermissionsForClientInterface {
  platform = null;

  isStoreEnabled = false;
  isChallengesEnabled = false;
  isRoadboxEnabled = false;
  isFormsEnabled = false;
  isQuizEnabled = false;
  isTalkEnabled = false;
  isValidateCartEnabled = false;
  isFeedEnabled = false;
  isRewardsEnabled = false;
  isRewardBadgesEnabled = false;
  isRewardGiftsEnabled = false;

  settings = null;
  valid = false;

  /**
   * Build current permissions of model
   * @param routeList route list
   */
  buildPermissions(routeList: any) {
    if (routeList) {
      const routeListArray = Object.keys(routeList).map((key) => {
        return routeList[key];
      });

      this.isStoreEnabled = routeListArray.indexOf('getproducts') > -1;
      this.isRoadboxEnabled = routeListArray.indexOf('getgalleries') > -1;
      this.isChallengesEnabled = routeListArray.indexOf('getChallenges') > -1;
      this.isFormsEnabled = routeListArray.indexOf('getforms') > -1;
      this.isQuizEnabled = routeListArray.indexOf('getquizzs') > -1;
      this.isTalkEnabled = routeListArray.indexOf('Talk') > -1;
      this.isValidateCartEnabled = routeListArray.indexOf('validatecart') > -1;
      this.isFeedEnabled = routeListArray.indexOf('getposts') > -1;
      this.isRewardsEnabled = routeListArray.indexOf('getrewardshistory') > -1;
      this.isRewardBadgesEnabled = this.isRewardsEnabled && routeListArray.indexOf('badges') > -1;
      this.isRewardGiftsEnabled = this.isRewardsEnabled && routeListArray.indexOf('gifts') > -1;

      this.valid = true;
    }
  }

  /**
   * Reset permissions
   */
  resetPermissions() {
    this.platform = null;

    this.isStoreEnabled = false;
    this.isRoadboxEnabled = false;
    this.isChallengesEnabled = false;
    this.isFormsEnabled = false;
    this.isQuizEnabled = false;
    this.isTalkEnabled = false;
    this.isValidateCartEnabled = false;
    this.isFeedEnabled = false;
    this.isRewardsEnabled = false;
    this.isRewardBadgesEnabled = false;
    this.isRewardGiftsEnabled = false;

    this.valid = false;

    if (this.settings) {
      this.settings.resetPermissions();
    }
  }
}

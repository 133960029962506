import { Injectable } from '@angular/core';
import { ConnectionService } from '@common/services/connection.service';
import { Utils } from '@common/helpers/utils';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReqTalkUsersInterface, TalkConversation, TalkLastMessage, TalkUsersOptions } from './talk.interface';
import { TalkNotificationToastController } from './notification-toast/notification-toast.controller';
import { TranslocoService } from '@ngneat/transloco';
import { TalkSdkService } from '@common/components/app/talk/talk-sdk.service';
import { TalkController } from '@common/components/app/talk/talk.controller';
import { map } from 'rxjs/operators';
import Talk from 'talkjs';
import { params } from '@environments/params';
import { AccessControlService } from '@common/services/access-control';
import { HttpBaseService } from '@common/services/api/http-base.service';
import { ThemeService } from '@common/services/theme.service';
import { PlatformInterface } from '@common/interfaces/api';
import { UserProfileInterface } from '@common/interfaces/common.interface';

/**
 * Service to manage Global Talk Service and interface with REST API
 */

@Injectable({
  providedIn: 'root'
})
export class TalkService extends HttpBaseService {
  static API_ROOT = 'https://api.talkjs.com/v1';

  private config = environment.talkJs;

  private unreadMessagesCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private conversationsState: BehaviorSubject<TalkConversation[]> = new BehaviorSubject<TalkConversation[]>(null);
  private conversationsInLoading: boolean = false;

  private profile: UserProfileInterface;
  private platform: PlatformInterface;
  private hasSession: boolean = false;

  private responsiveState: 'hide' | 'show' = null;

  private isNotificationSoundAllowed: boolean = false;
  private audioMessage = new Audio('/assets/sounds/message-pop-alert.mp3');

  private isTalkEnabled: boolean = false;
  private isSessionInCreation: boolean = false;
  private isInitialized: boolean = false;

  constructor(protected connectionServ: ConnectionService,
              protected translateServ: TranslocoService,
              protected http: HttpClient,
              protected toastCtrl: TalkNotificationToastController,
              protected talkSdkServ: TalkSdkService,
              protected talkCtrl: TalkController,
              protected themeServ: ThemeService,
              protected accessServ: AccessControlService) {
    super(http);
  }

  /**
   * Convert interface
   * @param message
   * @private
   */
  static toTalkLastMessage(message: Talk.Message): TalkLastMessage {
    return {
      attachment: message.attachment,
      conversationId: message.conversation.id,
      createdAt: message.timestamp,
      custom: message.custom,
      id: null,
      location: message.location,
      origin: message.origin,
      readBy: [],
      senderId: message.senderId,
      text: message.body,
      type: message.type
    };
  }

  /**
   * Init platform
   */
  initPlatform() {
    this.platform = this.connectionServ.getSelectedPlatformValue();
    if (this.platform) {
      this.talkSdkServ.setPlatform(this.platform);
      this.initTheme();
    }
  }

  initTheme() {
    this.talkSdkServ.setTheme(this.themeServ.isDarkMode() ? 'DarkTheme' : 'LightTheme');
  }

  setLocale(locale?: string) {
    this.talkSdkServ.setLocale(locale || this.translateServ.getActiveLang());
  }

  /**
   * Create Current Session
   */
  async createCurrentSession() {
    if (!environment.talkJs) {
      return null;
    }

    // Return current session if exist
    if (this.hasSession) {
      return this.talkSdkServ.getSession();
    }

    if (this.isSessionInCreation) {
      return null;
    }

    this.isTalkEnabled = this.accessServ.permissionsForClient().isTalkEnabled;

    // Cancel if chat not enabled
    if (!this.isTalkEnabled) {
      this.isSessionInCreation = false;
      return false;
    }

    this.isSessionInCreation = true;
    await Talk.ready;

    this.profile = this.connectionServ.getPlatformUserProfileValue();
    this.isNotificationSoundAllowed = this.connectionServ.getPlatformUserOptionsValue()?.allow_notifs_sound || false;
    this.initPlatform();

    const session = await this.talkSdkServ.initCurrentSession(this.profile);

    /**
     * When unread messages received event
     */
    session.unreads.on('change', (messages) => {
      const newMessage = messages?.length || 0;
      this.unreadMessagesCount.next(newMessage);
      this.talkCtrl.updateNewMessage(newMessage > 0);
    });

    /**
     * When message received event
     */
    session.on('message', (message) => {
      let found = -1;
      const userId = this.talkSdkServ.createUserId(this.profile?.id_customer);
      const senderId = message.senderId;
      const conversations = this.conversationsState.getValue();
      // Update conversation if found
      conversations?.forEach((conversation, index) => {
        if (conversation.id === message.conversation.id) {
          conversation.lastMessage = TalkService.toTalkLastMessage(message);
          conversation.isRead = senderId === userId;
          found = index;
        }
      });
      if (found > -1) {
        const conv = conversations.splice(found, 1);
        conversations.unshift(conv[0]);
        this.conversationsState.next(conversations);
      } else {
        this.buildActiveConversations();
      }

      if (userId !== senderId) {
        // Alert audio
        if (this.isNotificationSoundAllowed) {
          this.audioMessage.play();
        }

        // Notification - Only for tablet / desktop
        if (window.innerWidth >= params.breakpoints.md) {
          this.toastCtrl.show({
            delay: 8000,
            message: message.body,
            title: this.translateServ.translate('messages.new-message-name', {name: message.sender.name}),
            onClick: () => {
              this.talkCtrl.showPopup(null, message.conversation, message.conversation?.subject || message.sender?.name);
            }
          });
        }
      }
    });

    // Display conversations for messages
    this.talkCtrl.showPopupConversations();

    // Build active conversations
    this.buildActiveConversations();

    this.hasSession = true;
    this.isSessionInCreation = false;
    this.isInitialized = true;

    return session;
  }

  /**
   * Destroy current session
   */
  async destroyCurrentSession() {
    if (this.hasSession) {
      const session = await this.talkSdkServ.getSession();
      if (session) {
        this.talkCtrl.hidePopups();
        session.destroy();
      }

      this.hasSession = false;

      return true;
    }
    return false;
  }

  /**
   * Get the state of unread messages count
   */
  getUnreadMessagesCountState() {
    return this.unreadMessagesCount.asObservable();
  }

  /**
   * Build and return active conversations
   */
  activeConversations(): Observable<TalkConversation[]> {
    // Cancel if chat not enabled
    if (!this.isTalkEnabled) {
      return of(null);
    }

    if (this.conversationsState.getValue() === null && !this.conversationsInLoading) {
      this.buildActiveConversations();
    }
    return this.conversationsState.asObservable();
  }

  /**
   * Get conversation from active list
   * @param idConversation id of conversation
   */
  getConversationFromActiveList(idConversation: string) {
    const conversations = this.conversationsState.getValue();

    return conversations?.find(item => item.id === idConversation) || null;
  }

  /**
   * Build subject from participants
   * @param participants participants list
   */
  getSubjectFromParticipants(participants: UserProfileInterface[]) {
    if (participants?.length === 1) {
      return participants[0].firstname + ' ' + participants[0].lastname;
    } else if (participants?.length > 1) {
      let participantsStr = participants.slice(0, 2).map(item => item.firstname).join(', ');
      if (participants.length > 2) {
        participantsStr += ' ' + this.translateServ.translate('messages.and-others', {num: participants.length - 2} );
      }
      return participantsStr;
    }
    return '';
  }

  /**
   * Mark conversation as read
   * @param conversationId
   */
  markAsRead(conversationId: string) {
    const conversations = this.conversationsState?.getValue();
    conversations?.forEach(conv => {
      if (conv.id === conversationId) {
        conv.isRead = true;
      }
    });
    this.conversationsState?.next(conversations);
  }


  /**********************
   * Bypass Interfacing
   **********************/
  async createChatbox(otherApplicationUsers?: UserProfileInterface[], subject?: string) {
    this.initTheme();
    return this.talkSdkServ.createChatbox(otherApplicationUsers, subject);
  }

  async getConversation(otherApplicationUsers?: UserProfileInterface[]) {
    return this.talkSdkServ.getConversation(otherApplicationUsers);
  }
  /**
   * Callback to call oon responsive change
   */

  /**
   * Callback to call on responsive change
   */
  onResponsiveChange() {
    // Cancel if chat not enabled
    if (!this.isTalkEnabled) {
      return;
    }
    // Hide popups for mobile
    if (window.innerWidth < params.breakpoints.lg || !this.isTalkEnabled) {
      if (this.responsiveState !== 'hide') {
        this.talkCtrl.hidePopups();
        this.responsiveState = 'hide';
      }
    } else {
      if (this.responsiveState !== 'show') {
        this.talkCtrl.showPopupConversations();
        this.talkCtrl.updateConversations(this.conversationsState.getValue());
        this.responsiveState = 'show';
      }
    }
  }

  /**
   * Callback when platform change
   */
  onPlatformChange() {
    if (this.isInitialized) {
      this.isTalkEnabled = this.accessServ.permissionsForClient().isTalkEnabled;

      if (this.isTalkEnabled) {
        this.initPlatform();
        if (!this.hasSession) {
          this.createCurrentSession().then(/* Nothing to do */);
        } else {
          this.responsiveState = null;
          this.profile = this.connectionServ.getPlatformUserProfileValue();
          this.isNotificationSoundAllowed = this.connectionServ.getPlatformUserOptionsValue()?.allow_notifs_sound || false;
          this.talkSdkServ.reloadSession(this.profile).then(/* Nothing to do */);
          this.onResponsiveChange();
        }
      } else if (this.hasSession) {
        this.talkCtrl.hidePopups();
      }
    }
  }

  /************************
   * API Interfacing
   ***********************/

  getUsers(options?: TalkUsersOptions): Observable<ReqTalkUsersInterface> {
    const body = new FormData();

    if (options?.query) {
      body.append('query', options.query);
    }
    if (options?.users) {
      body.append('users', JSON.stringify(options.users));
    }
    if (options?.bypassPf) {
      body.append('bypass_pf', 'true');
    }
    body.append('debut', options?.debut?.toString() || '0');
    body.append('limit', options?.limit?.toString() || '50');

    return this.stdRequest(this.http.post(`${ environment.apiRoot }/Talk/getusers`, body));
  }

  /******************************
   * TALK JS API REST Interfacing
   *****************************/
  /**
   * Get conversations of connected user from API
   */
  getMyActiveConversations(): Observable<TalkConversation[]> {
    if (this.profile) {
      const headers = new HttpHeaders({
        Authorization: 'Bearer ' + this.config.secret
      });
      const userId = this.talkSdkServ.createUserId(this.profile.id_customer);
      return this.http.get<any>(`${ TalkService.API_ROOT }/${ this.config.appId }/users/${ userId }/conversations`, {headers}).pipe(
        map(response => response.data),
        map(items => items?.filter(item => item?.lastMessage !== null))
      );
    } else {
      return throwError('currentTalkUser not defined');
    }
  }

  /**
   * Get ori create conversation by Ids
   * @param conversationId
   */
  async getOrCreateConversationById(conversationId: string) {
    const session = await this.talkSdkServ.getSession();
    return session.getOrCreateConversation(conversationId);
  }

  /**
   * Build conversations list
   * @private
   */
  private buildActiveConversations() {
    if (this.conversationsInLoading) {
      return;
    }
    this.conversationsInLoading = true;
    this.profile = this.connectionServ.getPlatformUserProfileValue();
    this.isNotificationSoundAllowed = this.connectionServ.getPlatformUserOptionsValue()?.allow_notifs_sound || false;
    const userId = this.talkSdkServ.createUserId(this.profile.id_customer);
    this.talkSdkServ.getSession().then(() => {
      this.getMyActiveConversations().subscribe(list => {
        this.getUsers({
          users: this.buildUsersIdCustomer(list),
          bypassPf: true
        }).subscribe(response => {
          // Update user
          list?.forEach(item => {
            item.otherParticipants = [];
            // Find participants and add to other
            const ids = Object.keys(item.participants)
              .filter(val => val !== userId);
            ids?.forEach(id => {
              const user = response?.users?.find(u => this.talkSdkServ.createUserId(u.id_customer) === id);
              if (user) {
                item.otherParticipants.push(user);
              }
            });
            // Build unread conv
            item.isRead = userId === item?.lastMessage?.senderId ||
              item?.lastMessage?.readBy?.indexOf(userId) > -1;
            // Build subject
            if (!item.subject) {
              item.subject = this.getSubjectFromParticipants(item.otherParticipants);
            }
          });

          // Clean conversation not linked to user
          const listClean = list?.filter(item => item.otherParticipants?.length > 0);

          // Update conversation list
          this.conversationsState.next(listClean);
          this.talkCtrl.updateConversations(listClean);

          this.conversationsInLoading = false;
        });
      });
    });
  }

  /**
   * Build Users id_customer list from conversation list
   * @param conversations
   * @private
   */
  private buildUsersIdCustomer(conversations: TalkConversation[]): Array<number> {
    let values: Array<number> = [];
    conversations?.forEach(conversation => {
      values = values.concat(Object.keys(conversation.participants).map(val => Utils.toNumber(val.split('-')[0])));
    });
    values = values.filter(val => val !== Utils.toNumber(this.profile.id_customer));
    return values;
  }
}

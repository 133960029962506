import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { TeamService } from '@common/services/team.service';
import { PlatformInterface } from '@common/interfaces/api';
import { ConnectionService } from '@common/services/connection.service';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { UserProfileInterface } from '@common/interfaces/common.interface';

@Component({
  selector: 'app-talk-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class TalkUserSelectComponent implements OnInit, OnDestroy {

  @Input() resetValueOnSelection = true;
  @Input() placeholder = '';

  @Input() set disabled(value: boolean) {
    if (value) {
      this.searchValue.disable();
    } else {
      this.searchValue.enable();
    }
  }

  @Output() selection: EventEmitter<UserProfileInterface> = new EventEmitter<UserProfileInterface>();

  searchValue = new FormControl<string>('');
  inputValue = '';
  filteredSearchValue: Observable<UserProfileInterface[]>;

  users: UserProfileInterface[] = [];

  private platform: PlatformInterface;
  private subscriptions: Subscription[] = [];

  normalize = (val: string): string => {
    return val
      // Remove accents
      .normalize('NFD')
      // Replace diacritical marks in the given Unicode range to empty string
      .replace(/[\u0300-\u036f]/g, '')
      // Replace special chars to empty string
      .replace(/([^a-zA-Z0-9 ]+)/gi, '')
      // Convert to lower case
      .toLowerCase()
      // Remove spaces
      .trim();
  };

  constructor(private teamServ: TeamService,
              private connectionServ: ConnectionService) {
  }

  ngOnInit() {
    this.filteredSearchValue = this.searchValue.valueChanges.pipe(
      startWith(''),
      filter(value => typeof value === 'string'),
      switchMap(value => {
          if (!(value?.length > 0)) {
            return of([]);
          }
          // Remove special chars in input
          const normalizedValue = this.normalize(value);
          return of(this.users?.filter(item =>
            this.normalize(item.firstname).indexOf(normalizedValue) > -1 ||
            this.normalize(item.lastname).indexOf(normalizedValue) > -1 ||
            this.normalize(item.email).indexOf(normalizedValue) > -1
          ));
        }
      ));

    this.subscriptions.push(this.connectionServ.getPlatformState().subscribe(platform => {
      if (platform && this.platform?.id_action !== platform?.id_action) {
        this.platform = platform;
        this.loadUsers();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  loadUsers() {
    this.teamServ.getTeamsAndMembers().subscribe(response => {
      this.users = response.customers || [];
    });
  }

  public getDisplayFn() {
    return (val) => this.resetValueOnSelection ? '' : (val ? val.firstname + ' ' + val.lastname : val);
  }

  public onAutocompleteSelect(value) {
    if (this.resetValueOnSelection) {
      this.searchValue.setValue('');
    }
    this.selection.emit(value);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardConsistencyFieldsModal } from '@app/dashboard/_consistency-fields/consistency-fields.modal';
import { IonicModule } from '@ionic/angular';
import { ToolbarModalModule } from '@common/components/modals/toolbar-modal';
import { TranslocoModule } from '@ngneat/transloco';
import {
  DashboardConsistencyFieldsController
} from '@app/dashboard/_consistency-fields/consistency-fields.controller';
import { PipesModule } from '@common/pipes';
import { FormsModule } from '@angular/forms';
import { ProfileFieldsModule } from '@common/components/app/profile-fields';



@NgModule({
  declarations: [
    DashboardConsistencyFieldsModal
  ],
  imports: [
    CommonModule,
    IonicModule,
    ToolbarModalModule,
    TranslocoModule,
    PipesModule,
    FormsModule,
    ProfileFieldsModule
  ],
  providers: [
    DashboardConsistencyFieldsController
  ]
})
export class DashboardConsistencyFieldsModule { }

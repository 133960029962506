import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading.modal.html',
  styleUrls: ['./loading.modal.scss'],
})
export class LoadingModal implements OnInit, OnDestroy, OnChanges {

  @Input() message?: Observable<string>;

  @Input() progressEvent: Observable<number>;

  progressValue: number = 0;

  private subscription: Subscription;

  ngOnInit() {
    if (this.progressEvent) {
      this.subscription = this.progressEvent.subscribe(val => {
        if (val > 100) {
          val = 100;
        }
        this.progressValue = val / 100;
        if (val > 100) {
          this.ngOnDestroy();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TalkConversation } from '@common/components/app/talk';
import { TalkConversationsParticipantsModal } from '@common/components/app/talk/conversations/participants/participants.modal';
import { SheetModalController } from '@common/services/sheet-modal.controller';
import { UserProfileInterface } from '@common/interfaces/common.interface';

@Component({
  selector: 'app-talk-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss'],
})
export class TalkConversationsComponent {

  @Input() conversations: TalkConversation[];

  @Input() noSelection = false;

  @Input() fullHeight = false;

  @Input() applyChangeOnRemove = true;

  @Output() selectionChange: EventEmitter<TalkConversation> = new EventEmitter<TalkConversation>();

  toStrParticipants = (users: UserProfileInterface[]) => {
    return users?.map(item => item.firstname)?.join(', ') || '';
  }

  constructor(private modalCtrl: SheetModalController) {
  }

  removeConversation(index: number, event: Event) {
    event.stopPropagation();
    this.conversations.splice(index, 1);
    if (this.conversations?.length > 0 && this.applyChangeOnRemove) {
      this.selectionChange.emit(this.conversations[0]);
    }
  }

  /**
   * Show participants
   */
  async showParticipants(participants: UserProfileInterface[], event: Event) {
    event.stopPropagation();
    const modal = await this.modalCtrl.create({
      component: TalkConversationsParticipantsModal,
      componentProps: {participants},
      breakpoints: [0, .75, 1],
      initialBreakpoint: .75,
      cssClass: 'conversations-participants-modal',
      keyboardClose: true,
      showBackdrop: true
    });

    return await modal.present();
  }

}

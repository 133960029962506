import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ThemeService } from '@common/services/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-img-brand',
  template: `
    <img [src]="srcLM" [alt]="alt" *ngIf="!isDarkMode" (error)="onError()" />
    <img [src]="srcDM" [alt]="alt" *ngIf="isDarkMode" (error)="onError()" />
  `,
  styleUrls: ['./img-brand.component.scss'],
})
export class ImgBrandComponent implements OnChanges, OnInit, OnDestroy {
  @Input() src: string;

  @Input() srcDarkMode: string;

  @Input() alt: string;

  @Input() forceDefault = false;

  srcLM: string;
  srcDM: string;

  isDarkMode = false;

  private subscriptions: Subscription[] = [];

  constructor(private themeServ: ThemeService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.themeServ.getDarkModeState().subscribe(() => {
      this.isDarkMode = this.themeServ.isDarkMode();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    let updateDM = false;

    this.srcLM = this.src;
    this.srcDM = this.srcDarkMode;

    if (changes.src || changes.srcDarkMode) {
      if (!this.srcLM) {
        this.srcLM = '/assets/images/app/logo.svg';
      }
      // Apply LightMode logo if no DarkMode logo and LightMode logo provided
      if (!this.srcDM && !!this.src) {
        this.srcDM = this.srcLM || '/assets/images/app/logo-dm.svg';
      }
      if (!this.srcDM) {
        this.srcDM = '/assets/images/app/logo-dm.svg';
      }
      updateDM = true;
    }

    if (changes.forceDefault) {
      if (this.forceDefault) {
        this.onError();
      }
      updateDM = true;
    }

    if (updateDM) {
      this.isDarkMode = this.themeServ.isDarkMode();
    }
  }

  onError() {
    this.srcLM = '/assets/images/app/logo.svg';
    this.srcDM = '/assets/images/app/logo-dm.svg';
  }

}

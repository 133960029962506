import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import {
  ReqCoinsHistoryChartInterface,
  ReqCoinsHistoryInterface,
  ReqPublicProfileInterface,
  ReqTeamsAndMembersInterface,
  ReqTeamsForFeedInterface,
  ReqUpdateUserInterface,
  StandardPostInterface,
  ReqUserCoinsInterface,
  UserProfileDataInterface,
  UserProfileData, ReqLegacyChallengeUsersListInterface, ReqProvisioningFieldsInterface, ReqProfileStatsInterface
} from '@common/interfaces/api/client';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Utils } from '@common/helpers/utils';
import { UserProfileInterface } from '@common/interfaces/common.interface';
import { fromLegacyToUserProfile, toUserProfile, toUserOptions } from '@common/helpers/users';

/**
 * Service to interface with Users API section
 */

@Injectable()
export class UsersService extends HttpBaseService {

  /**
   * Used to get the amount of coins of the current user
   */
  getUsersCoins(): Observable<ReqUserCoinsInterface> {
    return this.stdRequest(this.http.post<ReqUserCoinsInterface>(`${ this.rootApi }/getusercoins`, null));
  }

  /**
   * Used to get all users and teams from the platform
   */
  getTeamsAndMembers(userIds?: Array<number>, teamIds?: Array<number>): Observable<ReqTeamsAndMembersInterface> {
    const body = new FormData();
    if (userIds?.length > 0) {
      body.append('id_customers', JSON.stringify(userIds));
    }
    if (teamIds?.length > 0) {
      body.append('id_teams', JSON.stringify(teamIds));
    }
    return this.stdRequest(this.http.post<ReqTeamsAndMembersInterface>(`${ this.rootApi }/getteamsandmembers`, body));
  }

  /**
   * Get teams for feed
   * @param idTeam id team
   */
  getTeamFeeds(idTeam = 0): Observable<ReqTeamsForFeedInterface> {
    const body = new FormData();
    body.append('id_team', idTeam.toString());

    return this.stdRequest(this.http.post<ReqTeamsForFeedInterface>(`${ this.rootApi }/getteamfeeds`, body));
  }

  /**
   * Get challenge users
   * @param search filter
   * @param idTeam id of the team to get users
   */
  getChallengeUsersList(search?: string, idTeam = 0): Observable<UserProfileInterface[]> {
    const body = new FormData();
    if (search) {
      body.append('search', search);
    }

    if (idTeam > 0) {
      body.append('id_team', Utils.toString(idTeam));
    }

    return this.stdRequest<ReqLegacyChallengeUsersListInterface>(this.http.post<ReqLegacyChallengeUsersListInterface>(`${ this.rootApi }/getChallengeUserList`, body)).pipe(
      switchMap(response => {
        return of(response?.users.map(user => fromLegacyToUserProfile(user)) || []);
      })
    );
  }

  /**
   * Get coins History
   */
  coinsHistory(): Observable<ReqCoinsHistoryInterface> {
    return this.stdRequest<ReqCoinsHistoryInterface>(
      this.http.post<ReqCoinsHistoryInterface>(`${ this.rootApi }/getpointshistory`, null)
    ).pipe(
      tap(response => response?.points_history?.forEach(item => {
        item.added_amount_num = Utils.toFloat(item.added_amount);
      }))
    );
  }

  /**
   * Used to get coins History Chart data
   */
  coinsHistoryChart(): Observable<ReqCoinsHistoryChartInterface> {
    return this.stdRequest<ReqCoinsHistoryChartInterface>(
      this.http.post<ReqCoinsHistoryChartInterface>(`${ this.rootApi }/getpointshistorychart`, null)
    );
  }

  /**
   * Get public profile of user
   * @param idUser id of user
   */
  publicProfile(idUser: number): Observable<UserProfileDataInterface> {
    const body = new FormData();
    body.append('id_user', idUser.toString());
    return this.stdRequest<ReqPublicProfileInterface>(this.http.post<ReqPublicProfileInterface>(`${ this.rootApi }/getpublicprofile`, body)).pipe(
      switchMap((response) => {
        const data: UserProfileDataInterface = {
          user: toUserProfile(response.publicprofile),
          fields: response.fields,
          options: toUserOptions(response.publicprofile),
          teams: response.publicprofile?.teams || []
        };
        return of(data);
      })
    );
  }

  /**
   * Used to get provisioning fields
   * @param idUser id of customer
   */
  provisioningProfile(idUser?: number): Observable<ReqProvisioningFieldsInterface> {
    const body = new FormData();
    if (idUser) {
      body.append('id_customer', idUser.toString());
    }
    return this.stdRequest(this.http.post<ReqProvisioningFieldsInterface>(`${ this.rootApi }/provisioningFields`, body));
  }

  /**
   * Get user profile stats
   * @param idUser id of user to get awards
   */
  profileStats(idUser: number): Observable<ReqProfileStatsInterface> {
    return this.stdRequest<any>(this.http.post(`${ this.rootApi }/profile/${ idUser.toString() }/stats`, null));
  }

  /**
   * Update user in backend
   * @param data data to update
   * @param profileAvatar new profile avatar
   * @param profileCover new profile cover
   */
  updateUser(data?: UserProfileData,
             profileAvatar?: File,
             profileCover?: File): Observable<UserProfileDataInterface> {
    const body = new FormData();
    if ((data?.id_customer || 0) > 0) {
      body.append('id_customer', data.id_customer.toString());
    }
    if (data?.fields) {
      body.append('fields', JSON.stringify(data.fields));
    }
    if (data?.options) {
      body.append('options', JSON.stringify(data.options));
    }
    if (data?.password) {
      body.append('password', JSON.stringify(data.password));
    }
    if (profileAvatar) {
      body.append('profil_pic', profileAvatar);
    }
    if (profileCover) {
      body.append('profil_bg', profileCover);
    }
    return this.stdRequest<ReqUpdateUserInterface>(this.http.post<ReqUpdateUserInterface>(`${ this.rootApi }/updateUser`, body)).pipe(
      switchMap((response) => {
        const retVal: UserProfileDataInterface = {
          user: toUserProfile(response.user),
          fields: data.fields,
          options: toUserOptions(response.user),
          fields_validation: response.fields_validation
        };
        return of(retVal);
      })
    );
  }

  /**
   * Send a message to admin
   * @param message message
   * @param subject subject of message
   * @param idCustomer id of the user
   */
  messageToAdmin(message: string, subject: string, idCustomer: number | string) {
    const body = new FormData();
    body.append('message', message);
    body.append('subject', subject);
    body.append('id_customer', Utils.toString(idCustomer));
    return this.stdRequest(this.http.post<any>(`${ this.rootApi }/messages/admin`, body));
  }

  /**
   * Report a user
   * @param idCustomer id post to remove
   * @param shallBlock shall block user ?
   * @param message message to send
   */
  reportUser(idCustomer: number, shallBlock: boolean = false, message?: string): Observable<StandardPostInterface> {
    const data = new FormData();
    if (shallBlock) {
      data.append('shallBlock', 'true');
    }
    if (message) {
      data.append('message', message);
    }
    return this.stdRequest(this.http.post<StandardPostInterface>(`${ this.rootApi }/reportUser/${ idCustomer.toString() }`, data));
  }

}

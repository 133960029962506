import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DurationInputArg1, DurationInputArg2 } from 'moment';
import { PopoverController } from '@ionic/angular';
import { DateInputPickerPopover } from './picker/picker.popover';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';


const DATE_FORMAT = {
  fr: 'DD/MM/YYYY',
  en: 'MM/DD/YYYY',
  es: 'DD/MM/YYYY',
  it: 'DD/MM/YYYY',
  de: 'DD/MM/YYYY',
  pt: 'DD/MM/YYYY',
};

const DATE_MASK = {
  fr: 'd0/M0/0000',
  en: 'M0/d0/0000',
  es: 'd0/M0/0000',
  it: 'd0/M0/0000',
  de: 'd0.M0.0000',
  pt: 'd0/M0/0000'
};

// TODO: Manage AM/PM Time selection
@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateInputComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateInputComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() type: 'date' | 'datetime' | 'time' = 'date';

  @Input() showSeconds: boolean = false;

  @Input() min?: string | moment.Moment;
  @Input() max?: string | moment.Moment;

  @Input() magicTool?: boolean | string = undefined;

  @Input() invalid: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';

  public isFocused: boolean = false;
  public isOpened: boolean = false;
  public isDisabled: boolean = false;

  public inputDate: string = '';
  public inputMask: string = '0000-M0-d0';

  private dateCurMoment?: moment.Moment;
  private dateMinMoment?: moment.Moment;
  private dateMaxMoment?: moment.Moment;
  private dateStart?: moment.Moment;

  private dateOldValue?: string;

  private locale: string = 'en';

  public onChange = (_: any) => {
  };
  public onTouch = () => {
  };

  constructor(private popoverCtrl: PopoverController,
              private translateServ: TranslocoService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.locale = this.translateServ.getActiveLang();

    this.formatDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.min) {
      this.dateMinMoment = this.min && this.type !== 'time' ? moment(this.min) : undefined;
    }
    if (changes.max) {
      this.dateMaxMoment = this.max && this.type !== 'time' ? moment(this.max) : undefined;
    }
    if (changes.disabled) {
      this.isDisabled = this.disabled;
    }
    if (changes.magicTool) {
      if (typeof this.magicTool === 'string') {
        this.dateStart = moment(this.magicTool);
      } else if (this.magicTool === true) {
        this.dateStart = moment();
      }
    }
  }

  @HostListener('click')
  hostTouched() {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  /**
   * Add unit to date
   * @param num num to add
   * @param unit unit
   */
  magicToolAddUnit(num: DurationInputArg1, unit: DurationInputArg2) {
    const current: moment.Moment = this.dateCurMoment ? moment(this.dateCurMoment) : moment();
    const start: moment.Moment = moment(this.dateStart);

    start.add(num, unit);
    start.hour(current.hour());
    start.minute(current.minute());
    start.second(current.second());

    this.dateCurMoment = start;

    this.applyChange();
  }

  /**
   * On Date Change event
   */
  onDateChange() {
    const date = moment(this.inputDate, this.getDateMaskFormat());

    if (date.isValid()) {
      this.dateCurMoment = date;
      this.applyChange(false);
    } else if (this.inputDate === '') {
      this.dateCurMoment = undefined;
      this.applyChange(false);
    }
  }

  /**
   * Open date picker
   */
  async openPicker(presentation: string, event: Event) {
    const popover = await this.popoverCtrl.create({
      component: DateInputPickerPopover,
      componentProps: {
        date: this.dateCurMoment?.format('YYYY-MM-DDTHH:mm:ssZ') || moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        min: this.dateMinMoment?.format('YYYY-MM-DDTHH:mm:ssZ'),
        max: this.dateMaxMoment?.format('YYYY-MM-DDTHH:mm:ssZ'),
        showSeconds: this.showSeconds,
        presentation
      },
      cssClass: 'date-input-popover presentation-' + presentation,
      keyboardClose: true,
      showBackdrop: false,
      event
    });

    popover.onDidDismiss().then(data => {
      if (data.role === 'apply') {
        switch (this.type) {
          case 'date':
            this.dateCurMoment = moment(data.data, 'YYYY-MM-DD');
            break;
          case 'datetime':
            this.dateCurMoment = moment(data.data, 'YYYY-MM-DD HH:mm:ss');
            break;
          case 'time':
            this.dateCurMoment = moment(data.data, this.showSeconds ? 'HH:mm:ss' : 'HH:mm');
            break;
        }
        this.applyChange();
      }
    });

    return await popover.present();
  }

  /**
   * Apply change using string as output
   * @param updateInputDate update input date
   */
  private applyChange(updateInputDate: boolean = true) {
    let format: string = '';
    let date: string | undefined = '';
    let expectedFormatLength: number = 0;

    // Parse Output
    switch (this.type) {
      case 'date':
        format = 'YYYY-MM-DD';
        expectedFormatLength = 10;
        break;
      case 'datetime':
        format = 'YYYY-MM-DD HH:mm:ss';
        expectedFormatLength = 17;
        break;
      case 'time':
        format = this.showSeconds ? 'HH:mm:ss' : 'HH:mm';
        expectedFormatLength = this.showSeconds ? 8 : 5;
        break;
    }
    if (this.dateCurMoment) {
      // Min / Max
      if (this.dateMinMoment) {
        this.dateCurMoment = moment.max(this.dateCurMoment, this.dateMinMoment);
        if ((this.inputDate?.length || 0) === expectedFormatLength) {
          updateInputDate = true;
        }
      }
      if (this.dateMaxMoment) {
        this.dateCurMoment = moment.min(this.dateCurMoment, this.dateMaxMoment);
        if ((this.inputDate?.length || 0) === expectedFormatLength) {
          updateInputDate = true;
        }
      }
      date = this.dateCurMoment.format(format);
    }

    if (date !== this.dateOldValue) {
      this.dateOldValue = date;
      this.onChange(date);
      this.formatDate(updateInputDate);
    }
  }

  /**
   * Format date according type
   * @param updateInputDate update input date
   */
  private formatDate(updateInputDate: boolean = true) {
    const dateFormat = DATE_FORMAT[this.locale] || 'YYYY-MM-DD';
    const dateMask = DATE_MASK[this.locale] || '0000-M0-d0';
    let date: string = '';

    if (this.dateCurMoment) {
      switch (this.type) {
        case 'date':
          date = this.dateCurMoment.format(dateFormat);
          this.inputMask = dateMask;
          break;
        case 'datetime':
          date = this.dateCurMoment.format(dateFormat + ', HH:mm');
          this.inputMask = dateMask + ', Hh:m0';
          break;
        case 'time':
          date = this.dateCurMoment.format(this.showSeconds ? 'HH:mm:ss' : 'HH:mm');
          this.inputMask = this.showSeconds ? 'Hh:m0:s0' : 'Hh:m0';
          break;
      }
    }

    if (updateInputDate) {
      this.inputDate = date;
      this.cdr.detectChanges();
    }
  }

  /**
   * Get date mask format
   */
  private getDateMaskFormat() {
    let dateFormat = DATE_FORMAT[this.locale] || 'YYYYMMDD';

    switch (this.type) {
      case 'datetime':
        dateFormat += 'HHmm';
        break;
      case 'time':
        dateFormat = this.showSeconds ? 'HHmmss' : 'HHmm';
        break;
    }

    return dateFormat;
  }

  /**
   * Parse input value
   * @param value
   * @private
   */
  private parseInput(value: string): moment.Moment {
    switch (this.type) {
      case 'date':
        return moment(value, 'YYYY-MM-DD');
      case 'datetime':
        return moment(value, 'YYYY-MM-DD HH:mm:ss');
      case 'time':
        return moment(value, this.showSeconds ? 'HH:mm:ss' : 'HH:mm');
    }
    return moment(value);
  }

  /**
   * ControlValueAccessor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {

    if (moment.isMoment(inputValue)) {
      this.dateCurMoment = inputValue;
    } else if (typeof inputValue === 'string') {
      this.dateCurMoment = this.parseInput(inputValue);
    } else {
      this.dateCurMoment = undefined;
    }

    if (!this.dateCurMoment?.isValid()) {
      this.dateCurMoment = undefined;
    }

    this.formatDate();

    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}

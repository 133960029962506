import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from '@common/services/common.service';
import { Observable, of } from 'rxjs';
import { AdminUsersService } from '@common/services/api/admin';
import { DashboardConsistencyFieldsModal } from './consistency-fields.modal';
import { ConsistencyFieldsModalProps } from './consistency-fields.interface';
import { countNbItemsToManage, hasFieldsToManage } from './consistency-fields.utils';
import { UserFieldsItemInterface } from '@common/interfaces/api/admin';
import { map, tap } from 'rxjs/operators';
import { Utils } from '@common/helpers/utils';


/**
 * Component that manage medias viewer modal showing
 */

@Injectable()
export class DashboardConsistencyFieldsController {

  private usersToManage: UserFieldsItemInterface[] = [];

  constructor(protected modalCtrl: ModalController,
              protected usersServ: AdminUsersService,
              protected commonServ: CommonService) {
  }

  /**
   * Check for consistency fields
   */
  checkForConsistencyFields() {
    return new Observable(observer => {
      this.commonServ.dataGet('__dashboard_consistency_fields_not_displayed').subscribe(result => {
        if (Utils.resultToBoolean(result)) {
          observer.next();
          observer.complete();
        } else {
          this.usersServ.consistencyFields().subscribe(response => {
            if (hasFieldsToManage(response.manage_users)) {
              this.show({
                users: response.manage_users,
              }).then(() => {
                observer.next();
                observer.complete();
              });
            } else {
              observer.next();
              observer.complete();
            }
          });
        }
      });
    });
  }

  /**
   * Has fields to manage ?
   * @param forceCheck force check on server
   */
  hasFieldsToManage(forceCheck: boolean = false): Observable<number> {
    if (this.usersToManage.length > 0 && forceCheck === false) {
      return of(countNbItemsToManage(this.usersToManage));
    }
    return this.usersServ.consistencyFields().pipe(
      tap(response => {
        this.usersToManage = response.manage_users;
      }),
      map(response => countNbItemsToManage(response.manage_users))
    );
  }

  /**
   * Show current fields
   */
  showCurrentFields(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.hasFieldsToManage(true).subscribe(result => {
        if (result > 0) {
          this.show({users: this.usersToManage}).then(() => {
            this.usersToManage = [];
            resolve();
          });
        } else {
          reject();
        }
      });
    });
  }

  /**
   * Display modal
   * @param props options of modal
   */
  show(props: ConsistencyFieldsModalProps) {
    return new Promise<void>(async resolve => {
      const modal = await this.modalCtrl.create({
        component: DashboardConsistencyFieldsModal,
        componentProps: {...props},
        cssClass: 'consistency-fields-modal',
        keyboardClose: true,
        showBackdrop: true,
        backdropDismiss: true
      });

      modal.onDidDismiss().then(() => {
        resolve();
      });

      modal.present().then(/* Nothing to do */);
    });
  }
}

import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import {
  GroupKpiInterface,
  KpiInterface,
  ModelGroupInterface,
  ReqModelInterface,
  ReqModelsInterface
} from '@common/interfaces/api/client';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Utils } from '@common/helpers/utils';

/**
 * ModelsService manage challenge models and challenge KPIs
 */
@Injectable({providedIn: 'root'})
export class ModelsService extends HttpBaseService {

  public kpiCustom = {
    id_model: 0,
    labelKey: 'kpi.custom',
    unit: '--custom--',
    identifier: 'XYZ3',
    icon: 'code-outline'
  };
  public kpiOtherGroup: GroupKpiInterface = {
    labelKey: 'dashboard.challenges.utils.kpi-other',
    icon: 'code-outline',
    kpis: [this.kpiCustom]
  };

  private groupsList: ModelGroupInterface[];
  private usedList: GroupKpiInterface[];

  private modelsKpisList: GroupKpiInterface[] = [];
  private legacyKpisList: GroupKpiInterface[] = [
    {
      labelKey: 'dashboard.challenges.utils.kpi-classics',
      icon: 'briefcase-outline',
      kpis: [
        {
          id_model: -1,
          labelKey: 'kpi.appointments',
          unit: 'Appointments',
          identifier: 'RDV',
          icon: 'briefcase-outline'
        },
        {
          id_model: -2,
          labelKey: 'kpi.opportunity',
          unit: 'Project',
          identifier: 'PR',
          icon: 'briefcase-outline'
        },
        {
          id_model: -3,
          label: 'Euro',
          unit: 'ca',
          identifier: 'CA',
          icon: 'briefcase-outline'
        },
        {
          id_model: -4,
          labelKey: 'kpi.percent',
          unit: 'Percent',
          identifier: 'XYZ2',
          icon: 'briefcase-outline'
        }
      ]
    },
    {
      labelKey: 'dashboard.challenges.utils.kpi-socials',
      icon: 'laptop-outline',
      kpis: [
        {
          id_model: -5,
          labelKey: 'kpi.post',
          unit: 'Post',
          identifier: '_post_miles',
          icon: 'laptop-outline'
        },
        {
          id_model: -6,
          labelKey: 'kpi.post-image',
          unit: 'Post image',
          identifier: '_post_miles_image',
          icon: 'laptop-outline'
        },
        {
          id_model: -7,
          labelKey: 'kpi.post-video',
          unit: 'Post video',
          identifier: '_post_miles_video',
          icon: 'laptop-outline'
        },
        {
          id_model: -8,
          labelKey: 'kpi.like-feed',
          unit: 'Like feed',
          identifier: '_like_miles',
          icon: 'laptop-outline'
        },
        {
          id_model: -9,
          labelKey: 'kpi.comment-feed',
          unit: 'Comment feed',
          identifier: '_comment_miles',
          icon: 'laptop-outline'
        },
        {
          id_model: -10,
          labelKey: 'kpi.form-answer-title',
          unit: 'Form Answer',
          identifier: '_form',
          icon: 'laptop-outline'
        }
      ]
    },
    this.kpiOtherGroup
  ];

  /**
   * Reset data
   */
  reset() {
    this.groupsList = undefined;
    this.usedList = undefined;
    this.modelsKpisList = [];
  }

  /**
   * Used to get the list of available groups.
   */
  groups(reload = false): Observable<ModelGroupInterface[]> {
    if (this.groupsList && !reload) {
      return of(this.groupsList);
    } else {
      return this.stdRequest(
        this.http.post<ReqModelsInterface>(`${ this.rootApi }/challenge/models`, null)
      ).pipe(
        map<ReqModelsInterface, ModelGroupInterface[]>(response => response.groups),
        tap(response => {
          this.groupsList = response;

          this.modelsKpisList = [];
          this.groupsList?.forEach(group => {
            const kpiGroup = {
              label: group.name,
              kpis: [],
              icon: group.icon
            };

            group?.models?.forEach(model => {
              kpiGroup.kpis.push({
                label: model.name,
                unit: model.kpi_unit,
                identifier: model.kpi_identifier,
                id_group: group.id,
                id_model: model.id,
                icon: group.icon,
                backgroundUrl: group.picture_url
              });
            });

            this.modelsKpisList.push(kpiGroup);
          });

          if (this.modelsKpisList?.length > 0) {
            this.modelsKpisList.push(this.kpiOtherGroup);
          }
        })
      );
    }
  }

  /**
   * Used to get complete model.
   */
  model(idModel: number): Observable<ReqModelInterface> {
    return this.stdRequest<ReqModelInterface>(
      this.http.post<ReqModelInterface>(`${ this.rootApi }/challenge/model/${ idModel.toString() }`, null)
    );
  }

  /**
   * Return kpis will be used in app:
   * - Legacy: if no models available
   * - From model: if models is defined
   */
  kpis(): Observable<GroupKpiInterface[]> {
    if (this.usedList !== undefined) {
      return of(this.usedList);
    }
    if (this.modelsKpisList?.length > 0) {
      this.usedList = this.modelsKpisList;
      return of(this.modelsKpisList);
    } else if (this.groupsList !== undefined && this.modelsKpisList?.length === 0) {
      this.usedList = this.legacyKpisList;
      return of(this.legacyKpisList);
    }
    return this.groups(true).pipe(switchMap(() => {
      if (this.modelsKpisList?.length > 0) {
        this.usedList = this.modelsKpisList;
        return of(this.modelsKpisList);
      } else {
        this.usedList = this.legacyKpisList;
        return of(this.legacyKpisList);
      }
    }));
  }

  /**
   * Return KPI in flatten list
   */
  kpisList(): Observable<KpiInterface[]> {
    return this.kpis().pipe(switchMap(response => of(this.flatGroup(response))));
  }

  /**
   * Get KPI data
   * @param unit
   */
  kpiData(unit: string): KpiInterface {
    const kpi = this.flatGroup(this.modelsKpisList).find(item => item.unit === unit);
    if (kpi) {
      return kpi;
    }
    return this.flatGroup(this.legacyKpisList).find(item => item.unit === unit);
  }

  /**
   * Get KPI data from model id
   * @param modelId
   */
  kpiDataFromModelId(modelId: string|number): KpiInterface {
    const idModel = Utils.toNumber(modelId);

    if (idModel > 0) {
      return this.flatGroup(this.modelsKpisList).find(item => item.id_model === idModel);
    } else if (idModel < 0) {
      return this.flatGroup(this.legacyKpisList).find(item => item.id_model === idModel);
    } else {
      return this.kpiCustom;
    }
  }

  /**
   * Indicate if custom KPI
   * @param unit unit of kpi
   * @param hasChildren if challenge has children
   */
  isCustomKpi(unit: string, hasChildren = false) {
    if (unit === 'ca' || unit === 'CA' || unit === '-ca-' || unit === '%') {
      return false;
    }
    if (unit.toLowerCase() === 'points' && !hasChildren) {
      return true;
    }
    if (this.usedList === undefined) {
      return null;
    }
    const kpis = this.flatGroup(this.usedList);
    return kpis?.map(item => item.unit).indexOf(unit) === -1;
  }

  /**
   * Find legacy model id
   * @param unit
   */
  findLegacyModelId(unit: string) {
    return this.flatGroup(this.legacyKpisList)?.find(item => item.unit === unit)?.id_model || 0;
  }

  /**
   * Determine if a legacy KPI
   * @param unit
   */
  isLegacyKpi(unit: string) {
    if (this.groupsList === undefined) {
      return null;
    }
    const modelKpi = this.flatGroup(this.modelsKpisList)?.find(item => item.unit === unit);
    if (!!modelKpi) {
      return false;
    }
    return this.flatGroup(this.legacyKpisList)?.find(item => item.unit === unit) || false;
  }

  /**
   * Flat a KPI group
   * @param groups
   * @private
   */
  private flatGroup(groups: GroupKpiInterface[]): KpiInterface[] {
    const kpis = [];
    groups?.forEach(group => group.kpis?.forEach(kpi => kpis.push(kpi)));
    return kpis;
  }

}

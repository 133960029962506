import { Injectable } from '@angular/core';
import { SchemeInterface } from '@environments/schemes';
import { Platform } from '@ionic/angular';
import { params } from '@environments/params';
import { environment } from '@environments/environment';


@Injectable({providedIn: 'root'})
export class ZoneService {
  scheme?: SchemeInterface;

  constructor(private ionicPlatform: Platform) {
  }

  /**
   * Return absolute url if exist
   */
  getAbsoluteDomainUrl(): string | undefined {
    if (
      window &&
      'location' in window &&
      'protocol' in window.location &&
      'host' in window.location
    ) {
      return window.location.hostname;
    }
    return undefined;
  }

  /**
   * Return the subdomain if provided and different from www and app
   */
  getSubDomain(): string | undefined {
    // return 'mideaplus';
    // return 'demotech';
    // return 'mylink';
    // return 'testsso';
    const url = this.getAbsoluteDomainUrl();
    if (!url) {
      return null;
    }
    if (url === 'localhost') {
      return 'dev';
    }
    const parts = url.split('.');
    if (parts.length >= 3) {
      const sub = parts[0];
      if (sub && sub !== 'www' && sub !== 'app') {
        return sub;
      }
    }
    return undefined;
  }

  /**
   * Get the URL to be used to access to app according to the context
   */
  getContextualUrl() {
    if (this.ionicPlatform.is('capacitor')) {
      let url = 'https://';
      if (environment.context !== 'prod') {
        url += environment.context + '.';
      } else if (this.scheme?.landing) {
        url += this.scheme.landing + '.';
      }
      return url + params.domainReferer;
    } else {
      // In case of desktop, return the current URL
      const location = new URL(window.location.href);
      return location.protocol + '//' + location.host;
    }
  }

  /**
   * Get whiteMark context of App
   */
  getWhiteMarkContext() {
    return this.scheme?.landing || this.getSubDomain();
  }

  /**
   * Get the current scheme
   */

  getCurrentScheme(): SchemeInterface {
    return this.scheme;
  }

  /**
   * Set the current scheme
   * @param scheme scheme to set
   */
  setScheme(scheme: SchemeInterface) {
    this.scheme = scheme;
  }

}

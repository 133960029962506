import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeSelectComponent } from './date-range-select.component';
import { IonicModule } from '@ionic/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PipesModule } from '@common/pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';



@NgModule({
  declarations: [
    DateRangeSelectComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    PipesModule,
    TranslocoModule
  ],
  exports: [
    DateRangeSelectComponent
  ]
})
export class DateRangeSelectModule { }

/**
 * Present phone number as readable phone
 */

import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import parsePhoneNumber from 'libphonenumber-js';

@Pipe({name: 'phone'})
export class PhonePipe implements PipeTransform {

  constructor(private translateServ: TranslocoService) {
  }

  transform(value: string | number, format: 'national' | 'international' | 'uri' = 'international'): string {
    const val = typeof value !== 'string' ? value.toString() : value;
    const local: any = this.translateServ.getActiveLang().toUpperCase();

    const phone = parsePhoneNumber(val, local);

    if (phone === undefined) {
      return val;
    }

    if (format === 'national') {
      return phone.formatNational();
    } else if (format === 'uri') {
      return phone.getURI();
    }
    return phone.formatInternational();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteInputModule } from '@common/components/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TalkUserSelectComponent } from '@common/components/app/talk/_user-select/user-select.component';



@NgModule({
  declarations: [
    TalkUserSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteInputModule,
    MatAutocompleteModule
  ],
  exports: [
    TalkUserSelectComponent
  ]
})
export class TalkUserSelectModule { }

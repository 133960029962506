import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgBrandComponent } from '@common/components/images/img-brand/img-brand.component';



@NgModule({
  declarations: [
    ImgBrandComponent
  ],
  exports: [
    ImgBrandComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ImgBrandModule { }

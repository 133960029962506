import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { EmojiPanelController } from '@common/components/utils/emoji-panel';
import { TeamService } from '@common/services/team.service';
import { Utils } from '@common/helpers/utils';
import { MentionItemInterface } from '@common/components/forms';


@Component({
  template: ''
})
export abstract class EditorBaseComponent {
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';

  // Min Rows and Max Rows for text type only
  @Input() minRows: number = 1;
  @Input() maxRows: number = 8;

  @Input() disableEmojiButton: boolean = false;

  @Input() fill: 'clear' | 'outline' = 'clear';

  @Input() allowMentions: boolean = true;

  @Output() inputKeyup: EventEmitter<{ value: string, event: KeyboardEvent }> = new EventEmitter<{ value: string; event: any }>();
  @Output() inputKeydown: EventEmitter<{ value: string, event: KeyboardEvent }> = new EventEmitter<{ value: string; event: any }>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  public isTextareaFocused: boolean = false;
  public message: string;
  public oldMessage: string;

  public mentionItems: MentionItemInterface[] = [];
  public mentionConfig = {
    labelKey: 'name'
  };


  public onChange = (_: any) => {
  };
  public onTouch = () => {
  };

  constructor(@Inject(EmojiPanelController) protected emojiCtrl: EmojiPanelController,
              @Inject(TeamService) protected teamServ: TeamService) { }

  init() {
    if (this.allowMentions) {
      if (this.teamServ.getTeamFeedValue() > 0) {
        const team = this.teamServ.getSelectedTeamForFeed();
        team?.members?.forEach(user => {
          this.mentionItems.push({
            id: Utils.toString(user.id_customer),
            name: user.firstname + ' ' + user.lastname,
            type: 'user',
            image: user.profile_pic
          });
        });
      } else {
        this.teamServ.getTeamsAndMembers().subscribe(response => {
          response?.customers?.forEach(user => {
            this.mentionItems.push({
              id: Utils.toString(user.id_customer),
              name: user.firstname + ' ' + user.lastname,
              type: 'user',
              image: user.profile_pic
            });
          });
          response?.teams?.forEach(team => {
            this.mentionItems.push({
              id: Utils.toString(team.id_action_equipe),
              name: team.nom,
              type: 'team',
              image: team.image_url
            });
          });
        });
      }
    }
  }

  /**
   * Apply change according type
   */
  applyChange() {
    const message = this.getFormattedMessage(this.message);
    this.onChange(message);
    this.valueChange.emit(message);
  }

  /**
   * Get formatted message according input and mentions
   */
  getFormattedMessage(inputMessage: any) {
    let message = inputMessage;

    // Mentions
    if (this.allowMentions) {
      // Add space in order to works mention replacement
      message += ' ';
      this.mentionItems?.forEach(item => {
        // Replace all mention with space or < at the end
        message = message.replace(new RegExp(`@${item.name}([ <]+)`, 'gi'), `{{@:${ item.id }:${ item.name }:${ item.type }}}$1`);
      });
      message = message.trim();
    }

    return message;
  }


  /**
   * Focus or blur component
   * @param focused is focused ?
   */
  focusBlur(focused: boolean) {
    this.isTextareaFocused = focused;
    if (!focused && this.message !== this.oldMessage) {
      this.oldMessage = this.message;
      this.applyChange();
    }
  }

  /**
   * ControlValueAccessor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {

    this.message = inputValue;

    if (this.allowMentions && this.message) {
      this.message = this.message.replace(/{{([^{}]*)}}/g, (c, p) => {
        const part = p.split(':');

        if (part[0] === '@') {
          return `@${ part[2] } `;
        } else {
          return c;
        }
      });
    }

    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

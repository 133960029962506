import { ModalController, ModalOptions } from '@ionic/angular';
import { params } from '@environments/params';
import { Injectable } from '@angular/core';

/**
 * Controller to manage modal presented as Sheet Document
 * https://ionicframework.com/docs/api/modal#sheet-modal
 */

@Injectable()
export class SheetModalController {

  constructor(private modalCtrl: ModalController) {
  }

  create(opts: ModalOptions): Promise<HTMLIonModalElement> {

    if (window.innerWidth < params.breakpoints.md) {
      if (opts.canDismiss === undefined || opts.canDismiss === null) {
        opts.canDismiss = true;
        opts.backdropDismiss = true;
      }
      if (opts.breakpoints === undefined) {
        opts.breakpoints = [0, 0.25, 1];
      }
      if (opts.initialBreakpoint === undefined) {
        opts.initialBreakpoint = 0.25;
      }
      opts.componentProps = Object.assign(opts.componentProps || {}, {device: 'mobile'});
    } else {
      opts.breakpoints = undefined;
      opts.initialBreakpoint = undefined;
      opts.componentProps = Object.assign(opts.componentProps || {}, {device: 'desktop'});
    }

    return this.modalCtrl.create(opts);
  }

  dismiss(data?: any, role?: string, id?: string): Promise<boolean> {
    return this.modalCtrl.dismiss(data, role, id);
  }

  getTop(): Promise<HTMLIonModalElement | undefined> {
    return this.modalCtrl.getTop();
  }
}


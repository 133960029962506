import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@common/helpers/utils';
import { TeamInterface } from '@common/interfaces/api/client';
import { Subscription } from 'rxjs';
import { TeamService } from '@common/services/team.service';
import { TranslocoService } from '@ngneat/transloco';
import { PlatformInterface } from '@common/interfaces/api';
import { AdminTeamsService } from '@common/services/api/admin';
import { ModalController } from '@ionic/angular';
import { TeamFeedSelectModal } from '@common/components/forms/team-feed-select/team-feed-select.modal';
import { AdminTeamInterface } from '@common/interfaces/api/admin';
import { AccessControlService, PermissionsForDashboardInterface } from '@common/services/access-control';

export interface TeamFeedSelectFilters {
  canPost?: boolean;
  canComments?: boolean;
}

@Component({
  selector: 'app-team-feed-select',
  templateUrl: './team-feed-select.component.html',
  styleUrls: ['./team-feed-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TeamFeedSelectComponent),
      multi: true,
    }
  ],
})
export class TeamFeedSelectComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  @Input() filters: TeamFeedSelectFilters;

  teamFeedId: number;
  currentTeam: TeamInterface;

  teamsList: AdminTeamInterface[];
  teamsFeedList: TeamInterface[];

  platform: PlatformInterface;

  isDisabled = false;
  isMatSelectUsed = true;
  isLoading = false;

  private subscriptions: Subscription[] = [];
  private isInteger = false;
  private isInitialized = false;

  private permissions: PermissionsForDashboardInterface;

  public onChange = (_: any) => {};
  public onTouch = () => {};

  constructor(private teamServ: TeamService,
              private teamsServ: AdminTeamsService,
              private modalCtrl: ModalController,
              private translateServ: TranslocoService,
              private accessServ: AccessControlService) { }

  ngOnInit() {
    // Load posts on platform change
    this.subscriptions.push(this.accessServ.permissionsForDashboardState().subscribe(permissions => {
      if (permissions.platform) {
        this.platform = permissions.platform;
        this.permissions = permissions;
        this.loadTeamsFeed().then(/* Nothing to do */);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && this.isInitialized) {
      this.buildList().then(/* Nothing to do */);
    }
  }

  async loadTeamsFeed() {
    this.isLoading = true;
    this.initList().then(() => {
      // Add other team feed in list
      if (this.platform.team_feed) {
        if (this.platform.is_admin) {
          if (this.permissions.permissions?.Team?.view) {
            this.teamsServ.teams().subscribe(response => {
              this.teamsList = response?.equipes?.filter(item => Utils.resultToBoolean(item.team_feed)) || [];
              this.buildList(false);
            });
          } else {
            this.isLoading = false;
          }
        } else {
          this.teamServ.getTeamFeedsState(true).subscribe(response => {
            this.teamsFeedList = this.teamsFeedList.concat(response);
            this.buildData();
          });
        }
      } else {
        this.isLoading = false;
      }
    });
  }

  // Add general feed in list
  initList() {
    return new Promise(resolve => {
      this.teamsFeedList = [];
      this.translateServ.selectTranslate('utils.general').subscribe(name => {
        // General Feed
        this.teamsFeedList.push({
          id_action_equipe: 0,
          id_action: 0,
          nom: name,
          image_url: null,
          user_count: 0
        });

        resolve(true);
      });
    });
  }

  /**
   * Build list according filter
   */
  async buildList(initList = true) {
    let newList = this.teamsList || [];

    if (this.filters?.canPost) {
      newList = newList.filter(item => Utils.resultToBoolean(item.team_feed_post));
    }

    if (this.filters?.canComments) {
      newList = newList.filter(item => Utils.resultToBoolean(item.team_feed_comment));
    }

    if (initList) {
      await this.initList();
    }

    this.teamsFeedList = this.teamsFeedList.concat(newList);

    // Cast data
    this.teamsFeedList.forEach(item => item.id_action_equipe = Utils.toNumber(item.id_action_equipe));
    this.buildData();
  }

  buildData() {
    // Check if selected team is in list
    if (this.teamsFeedList?.findIndex(item => item.id_action_equipe === this.teamFeedId) === -1) {
      this.teamFeedId = 0;
    }
    this.isMatSelectUsed = this.teamsFeedList.length < 11;
    this.currentTeam = this.teamsFeedList.find(item => item.id_action_equipe === this.teamFeedId);
    this.isLoading = false;
    this.isInitialized = true;
    this.applyChange();
  }

  applyChange() {
    this.onChange(this.isInteger ? this.teamFeedId : this.teamFeedId.toString());
  }


  /**
   * Show children
   */
  async selectTeamInModal() {
    if (!this.isDisabled) {
      const modal = await this.modalCtrl.create({
        component: TeamFeedSelectModal,
        componentProps: {
          list: this.teamsFeedList
        },
        cssClass: 'team-feed-select-modal',
        keyboardClose: true,
        showBackdrop: true
      });

      modal.onDidDismiss().then(data => {
        if (data?.role === 'apply') {
          this.currentTeam = data?.data;
          this.teamFeedId = this.currentTeam.id_action_equipe;
          this.applyChange();
        }
      });

      return await modal.present();
    } else {
      return null;
    }
  }

  /**
   * ControlValueAccessor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {
    this.isInteger = typeof inputValue === 'number';
    this.teamFeedId = Utils.toNumber(inputValue);
    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

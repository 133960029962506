import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeDatabase, TreeItemNode } from './tree.database';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';

class TreeFlatItemNode {
  id: string;
  name: string;
  level: number;
  imageSrc?: string;
  icon?: string;
  expandable: boolean;
}

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit {

  @Input() data: TreeItemNode[];
  @Output() dataChange = new EventEmitter<TreeItemNode[]>();
  @Output() collapseEvent = new EventEmitter<TreeItemNode>();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeFlatItemNode, TreeItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItemNode, TreeFlatItemNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeFlatItemNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TreeFlatItemNode>;

  treeFlattener: MatTreeFlattener<TreeItemNode, TreeFlatItemNode>;

  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeFlatItemNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<string>(true /* multiple */);

  constructor(private database: TreeDatabase) {}

  ngOnInit() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TreeFlatItemNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    if (this.data) {
      this.setData(this.data);

      this.database.dataChange.subscribe(data => {
        this.dataSource.data = data;
      });
    }
  }

  getLevel = (node: TreeFlatItemNode) => node.level;

  isExpandable = (node: TreeFlatItemNode) => node.expandable;

  getChildren = (node: TreeItemNode): TreeItemNode[] => node.children;

  hasChild = (num: number, nodeData: TreeFlatItemNode) => nodeData.expandable;

  hasNoContent = (num: number, nodeData: TreeFlatItemNode) => nodeData.id === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.id === node.id
      ? existingNode
      : new TreeFlatItemNode();
    flatNode.id = node.id;
    flatNode.name = node.name;
    flatNode.imageSrc = node.imageSrc;
    flatNode.icon = node.icon;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /**
   * Set new data
   */
  setData(data?: TreeItemNode[]) {
    this.data = data || this.data;

    this.database.setItems(this.data);

    this.checkSelection(this.data);
  }

  /**
   * Check selection for each node item
   */
  checkSelection(node: TreeItemNode[], level = 0) {
    let allChildrenSelected = true;
    node?.forEach(item => {
      if (!item.isSelected) {
        allChildrenSelected = false;
      }
      if (item.children?.length > 0) {
        // Selected by children
        item.isSelected = this.checkSelection(item.children, level + 1);
      }
      item.isSelected ? this.checklistSelection.select(item.id) : this.checklistSelection.deselect(item.id);
    });
    return allChildrenSelected;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TreeFlatItemNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child.id);
    });
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeFlatItemNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child.id));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeFlatItemNode): void {
    this.checklistSelection.toggle(node.id);
    const descendants = this.treeControl.getDescendants(node);

    const nodeSelected = this.checklistSelection.isSelected(node.id);
    descendants.forEach(item => {
      nodeSelected ? this.checklistSelection.select(item.id) : this.checklistSelection.deselect(item.id);
    });

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child.id));
    this.checkAllParentsSelection(node);
    this.buildOutput();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeFlatItemNode): void {
    this.checklistSelection.toggle(node.id);
    this.checkAllParentsSelection(node);
    this.buildOutput();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeFlatItemNode): void {
    let parent: TreeFlatItemNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeFlatItemNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node.id);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child.id);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node.id);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node.id);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeFlatItemNode): TreeFlatItemNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /**
   * Get flat node from id
   * @param id id of node
   */
  getFlatItemNodeFromId(id: string) {
    let flatNode = null;
    this.flatNodeMap.forEach(node => {
      if (node.id === id) {
        flatNode = node;
      }
    });
    return flatNode;
  }

  /**
   * Build selection for output
   */
  buildOutput() {
    this.data.forEach(node => {
      this.buildOutputNode(node);
    });
    this.dataChange.emit(this.data);
  }

  buildOutputNode(node?: TreeItemNode) {
    node.isSelected = this.checklistSelection.isSelected(node.id);
    if (node.children) {
      node.children.forEach(childNode => {
        this.buildOutputNode(childNode);
      });
    }
  }
}

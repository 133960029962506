import { Component } from '@angular/core';
import { PaginatorInterface } from '@common/interfaces/common.interface';
import { PageEvent } from '@angular/material/paginator';

/**
 * Abstract component to manage mat-paginator
 */

@Component({
  template: ''
})
export abstract class PaginatorBase {
  public pageSizeOptions = [10, 30, 50, 100];
  public paginator: PaginatorInterface = {
    page: 1,
    max: 30,
    total: 0
  };
  /**
   * Callback to define when implement this component by parent
   * @protected
   */
  protected paginatorCallback: () => void;

  private noResetPage = false;

  /**
   * Build Paginator list
   * @param list list of items
   * @param page set up to page
   */
  buildPaginatorList<T>(list: T[], page = null): T[] {
    this.setPaginatorTotal(list?.length, page);
    if (list?.length > this.paginator.max) {
      const start = (this.paginator.page - 1) * this.paginator.max;
      list = list.slice(start, start + this.paginator.max);
    }
    return list;
  }

  /**
   * Set paginator total and reset page if needed
   * @param value
   * @param page set up to page
   */
  setPaginatorTotal(value: number, page = null) {
    if (page === null) {
      if (!this.noResetPage && this.paginator.page !== 1) {
        this.paginator.page = 1;
      }
    } else {
      this.paginator.page = page;
    }
    this.noResetPage = false;
    this.paginator.total = value || 0;
  }

  /**
   * Set the paginator callback change
   * @param callback
   */
  setPaginatorCallback(callback: () => void) {
    this.paginatorCallback = callback;
  }

  /**
   * Callback on paginator change
   * @param event paginator event
   */
  onPaginatorChange(event: PageEvent) {
    this.paginator.page = event.pageIndex + 1;
    this.paginator.max = event.pageSize;
    this.noResetPage = true;
    if (this.paginatorCallback) {
      this.paginatorCallback();
    }
  }
}


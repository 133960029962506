import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Editor } from 'ngx-editor';
import { EditorView } from 'prosemirror-view';
import { Subscription } from 'rxjs';

import TextColor from './TextColor';


const TEXT_COLOR = new TextColor('text_color');
const TEXT_BACKGROUND_COLOR = new TextColor('text_background_color');

@Component({
  selector: 'app-color-menu',
  templateUrl: './color-menu.component.html',
  styleUrls: ['./color-menu.component.scss'],
})
export class ColorMenuComponent implements OnInit {
  @Input() editor: Editor;
  @Input() type: 'background_color' | 'text_color' = 'text_color';
  @Input() withSeparator = false;

  isActive = false;
  isDisabled = false;
  activeColors: string[] = [];

  showPopup = false;
  currentColor = null;


  presets = [[
    '#b60205',
    '#d93f0b',
    '#fbca04',
    '#0e8a16',
    '#006b75',
    '#1d76db',
    '#0052cc',
    '#5319e7'
  ], [
    '#e99695',
    '#f9d0c4',
    '#fef2c0',
    '#c2e0c6',
    '#bfdadc',
    '#c5def5',
    '#bfd4f2',
    '#d4c5f9'
  ]];

  private updateSubscription: Subscription;

  private get command() {
    return this.type === 'text_color' ? TEXT_COLOR : TEXT_BACKGROUND_COLOR;
  }

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.updateSubscription = this.editor.update.subscribe((view: EditorView) => {
      this.update(view);
    });
  }

  @HostListener('document:mousedown', ['$event'])
  closePopup(event: MouseEvent){
    if (!this.el.nativeElement.contains(event.target) && this.showPopup) {
      this.hidePopup();
    }
  }

  onOpenPicker(e: MouseEvent) {
    e.preventDefault();
    this.showPopup = !this.showPopup;
  }

  onColorSelect(e: MouseEvent, color: string): void {
    e.preventDefault();

    if (e.button !== 0) {
      return;
    }

    const {state, dispatch} = this.editor.view;

    if (this.type === 'text_color') {
      const attrs = {color};
      this.command.apply(attrs)(state, dispatch);
    } else {
      const attrs = {backgroundColor: color};
      this.command.apply(attrs)(state, dispatch);
    }

    if (!this.editor.view.hasFocus()) {
      this.editor.view.focus();
    }

    this.hidePopup();
  }

  getContrastYIQ(hexcolor: string): string {
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  remove(e: MouseEvent): void {
    e.preventDefault();
    const {state, dispatch} = this.editor.view;

    this.command.remove()(state, dispatch);
    this.hidePopup();
  }

  private update = (view: EditorView) => {
    const {state} = view;
    this.isDisabled = !this.command.canExecute(state);
    this.isActive = this.command.isActive(state);
    this.activeColors = [];

    if (this.isActive) {
      this.activeColors = this.command.getActiveColors(state);
      this.currentColor = this.activeColors?.length > 0 ? this.activeColors[0] : null;
    }

  };

  private hidePopup(): void {
    this.showPopup = false;
  }

}

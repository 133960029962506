import { UrlTree } from '@angular/router';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { NotificationInterface } from '@common/interfaces/api';
import { BadgeNotificationsEventsInterface } from '@common/services/notifications/notifications.interface';
import { params } from '@environments/params';

export interface NotificationsRouteInterface {
  url: string | UrlTree | any[];
  options?: NavigationOptions;
}

/**
 * Static Notifications Utils
 */
export class NotificationsUtils {
  static COINS = 'CoinsNotification';
  static CASH = 'CashNotification';
  static GIFT = 'GiftNotification';
  static FEED = 'FeedNotification';
  static LIKE = 'LikeNotification';
  static ROADBOX = 'GalerieNotification';
  static QUIZ = 'QuizzNotification';
  static SURVEY = 'SondageNotification';
  static FORM = 'FormNotification';
  static CHALLENGE = 'ChallengeNotification';
  static CHALLENGE_UPDATE = 'ChallengeUpdateNotification';
  static CHALLENGE_PROOF = 'ChallengeProofNotification';
  static TALK = 'TalkNotification';
  static BADGE = 'BadgesNotification';
  static ACTION = 'ActionNotification';
  static CUSTOMER = 'CustomerNotification';

  /**
   * Get route according Notifications params
   * @param type type of notification
   * @param referenceId reference Id
   * @param isAdmin is admin ?
   */
  static getRoute(type: string, referenceId?, isAdmin = false): NotificationsRouteInterface {

    // Build route according admin and reference id
    const buildRoute = (clientRoute,
                        clientWithIdRoute,
                        adminRoute,
                        adminWithIdRoute,
                        options?): NotificationsRouteInterface => {
      if (isAdmin) {
        return referenceId ? {url: adminWithIdRoute, options} : {url: adminRoute, options};
      } else {
        return referenceId ? {url: clientWithIdRoute, options} : {url: clientRoute, options};
      }
    };

    switch (type) {
      case NotificationsUtils.ACTION:
        return {
          url: ['/']
        };
      case NotificationsUtils.CUSTOMER:
        return {
          url: ['/profile/', referenceId]
        };
      case NotificationsUtils.COINS:
        return {
          url: [`/rewards/coins`]
        };
      case NotificationsUtils.CASH:
        return {
          url: [`/rewards/cash`]
        };
      case NotificationsUtils.GIFT:
        return {
          url: [`/rewards/gifts`]
        };
      case NotificationsUtils.FEED:
      case NotificationsUtils.LIKE:
        return referenceId ? {
          url: [`/feed`, referenceId],
        } : {
          url: [`/`]
        };
      case NotificationsUtils.ROADBOX:
        // --roadbox--
        return buildRoute(
          [`/library`],
          [`/library`, referenceId],
          [`/dashboard/library/open`],
          [`/dashboard/library/open`, referenceId]
        );
      case NotificationsUtils.QUIZ:
        return buildRoute(
          [`/quizzes`],
          [`/quizzes`],
          [`/dashboard/quizzes`],
          [`/dashboard/quizzes`, referenceId]
        );
      case NotificationsUtils.SURVEY:
        return buildRoute(
          [`/`],
          [`/`],
          [`/dashboard/surveys`],
          [`/dashboard/surveys`],
          {fragment: referenceId}
        );
      case NotificationsUtils.FORM:
        return buildRoute(
          [`/forms`],
          [`/forms`],
          [`/dashboard/forms`],
          [`/dashboard/forms`, referenceId]
        );
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
        const isMobile = window.innerWidth < params.breakpoints.md;
        const clientRoute = isMobile ? [`/challenge`, referenceId, 'mobile'] : [`/challenge`, referenceId];
        return buildRoute(
          [`/challenges`],
          clientRoute,
          [`/dashboard/challenges`],
          [`/dashboard/challenges/overview`, referenceId]
        );
      case NotificationsUtils.CHALLENGE_PROOF:
        return buildRoute(
          [`/dashboard/challenges`],
          [`/dashboard/challenges/validation`, referenceId],
          [`/dashboard/challenges`],
          [`/dashboard/challenges/validation`, referenceId]
        );
      case NotificationsUtils.TALK:
        return buildRoute(
          [`/messages/conversation`],
          [`/messages/conversation`, referenceId],
          [`/messages/conversation`],
          [`/messages/conversation`, referenceId]
        );
      case NotificationsUtils.BADGE:
        return {
          url: [`/profile`]
        };
    }
  }

  /**
   * Get reference id of notification
   * @param notification object
   */
  static getReferenceId(notification: NotificationInterface) {
    if (notification?.items?.length > 0) {
      return notification?.items.pop().reference_id;
    }
    return null;
  }

  /**
   * List all notification items by event type of badge model
   * @param events list of events
   * @param type type of notification
   */
  static badgeListByType(events: BadgeNotificationsEventsInterface, type: string) {
    switch (type) {
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
      case NotificationsUtils.CHALLENGE_PROOF:
        return events.challengesIds;
      case NotificationsUtils.COINS:
        return events.coinsIds;
      case NotificationsUtils.CASH:
        return events.cashIds;
      case NotificationsUtils.GIFT:
        return events.giftsIds;
      case NotificationsUtils.QUIZ:
        return events.quizzesIds;
      case NotificationsUtils.FEED:
      case NotificationsUtils.LIKE:
        return events.feedIds;
      case NotificationsUtils.FORM:
        return events.formsIds;
      case NotificationsUtils.SURVEY:
        return events.surveysIds;
      case NotificationsUtils.ROADBOX:
        return events.roadboxIds;
    }
  }

  /**
   * Get ids key by type applied to badge model
   * @param type type of notification
   */
  static badgeIdsKeyByType(type: string) {
    switch (type) {
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
      case NotificationsUtils.CHALLENGE_PROOF:
        return 'challengesIds';
      case NotificationsUtils.COINS:
        return 'coinsIds';
      case NotificationsUtils.CASH:
        return 'cashIds';
      case NotificationsUtils.GIFT:
        return 'giftsIds';
      case NotificationsUtils.QUIZ:
        return 'quizzesIds';
      case NotificationsUtils.FEED:
      case NotificationsUtils.LIKE:
        return 'feedIds';
      case NotificationsUtils.FORM:
        return 'formsIds';
      case NotificationsUtils.SURVEY:
        return 'surveysIds';
      case NotificationsUtils.ROADBOX:
        return 'roadboxIds';
    }
  }
}

import { ComponentRef, Injectable } from '@angular/core';
import { UserProfileInterface } from '@common/interfaces/common.interface';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TalkPopupComponent } from '@common/components/app/talk/popup/popup.component';
import { Utils } from '@common/helpers/utils';
import { NavController } from '@ionic/angular';
import { params } from '@environments/params';
import { UsersService } from '@common/services/api/client';
import { TalkPopupConversationsComponent } from '@common/components/app/talk/popup-conversations/popup-conversations.component';
import { TalkConversation } from '@common/components/app/talk/talk.interface';
import { ConversationData } from 'talkjs/all';

interface CdkPopupInterface {
  id: string;
  componentPortal: ComponentPortal<TalkPopupComponent>;
  componentRef: ComponentRef<TalkPopupComponent>;
  overlayRef: OverlayRef;
}

interface CdkPopupConversationsInterface {
  componentPortal: ComponentPortal<TalkPopupConversationsComponent>;
  componentRef: ComponentRef<TalkPopupConversationsComponent>;
  overlayRef: OverlayRef;
}

@Injectable({
  providedIn: 'root'
})
export class TalkController {

  private cdkPopupsRef: CdkPopupInterface[] = [];
  private cdkPopupConversationRef: CdkPopupConversationsInterface;

  constructor(private overlay: Overlay,
              private navCtrl: NavController,
              private usersServ: UsersService) {
  }

  /**
   * Display popup with user id
   * @param userId
   */
  showPopupFromUserId(userId: string) {
    if (userId) {
      this.usersServ.publicProfile(Utils.toNumber(userId)).subscribe(response => {
        this.showPopup(response.user);
      });
    }
  }

  /**
   * Create or open new popup
   * @param withUser
   * @param conversation
   * @param subject
   */
  showPopup(withUser?: UserProfileInterface, conversation?: ConversationData, subject?: string) {
    if (!conversation && !withUser) {
      return;
    }
    if (window.innerWidth < params.breakpoints.lg) {
      // If mobile view, navigate to chat box page
      this.navCtrl.navigateForward(['/messages/user', withUser.id_customer])?.then(/* Nothing to do */);
    } else {
      // If desktop, open chat popup
      const id = withUser ? Utils.toString(withUser.id_customer) : conversation?.id;

      // Not open if already open
      if (this.cdkPopupsRef.findIndex(pop => pop.id === id) > -1) {
        return;
      }

      const config = new OverlayConfig({positionStrategy: this.getPosition()});
      const overlayRef = this.overlay.create(config);
      const componentPortal = new ComponentPortal(TalkPopupComponent);

      const componentRef = overlayRef.attach(componentPortal);

      componentRef.instance.id = id;
      if (withUser) {
        componentRef.instance.user = withUser;
        componentRef.instance.title = subject || conversation?.subject || withUser.firstname;
      } else {
        componentRef.instance.conversation = conversation;
        componentRef.instance.title = subject || conversation?.subject;
      }
      componentRef.instance.closePopup.subscribe(() => {
        overlayRef.detach();
        // Remove popup
        const popupIndex = this.cdkPopupsRef.findIndex(pop => pop.id === id);
        if (popupIndex > -1) {
          this.cdkPopupsRef.splice(popupIndex, 1);
        }
        // Replace popup
        this.cdkPopupsRef?.forEach((popup, index) => {
          popup.overlayRef.updatePositionStrategy(this.getPosition(index));
        });
      });

      this.cdkPopupsRef.push({
        id,
        componentPortal,
        componentRef,
        overlayRef,
      });
    }
  }

  /**
   * Display popup conversations
   */
  showPopupConversations() {
    // Cancel displaying for mobile
    if (window.innerWidth < params.breakpoints.lg || !!this.cdkPopupConversationRef) {
      return;
    }
    const config = new OverlayConfig({positionStrategy: this.getPosition()});
    const overlayRef = this.overlay.create(config);
    const componentPortal = new ComponentPortal(TalkPopupConversationsComponent);

    const componentRef = overlayRef.attach(componentPortal);

    componentRef.instance.selectionChange.subscribe((conversation: TalkConversation) => {
      if (conversation) {
        this.showPopup(null, conversation);
      }
    });

    this.cdkPopupConversationRef = {
      componentPortal,
      componentRef,
      overlayRef
    };
  }

  /**
   * Update conversations of popup conversations
   * @param conversations
   */
  updateConversations(conversations: TalkConversation[]) {
    if (this.cdkPopupConversationRef && conversations) {
      this.cdkPopupConversationRef.componentRef.instance.conversations = conversations;
    }
  }

  /**
   * Update new message notification
   * @param hasNewMessage
   */
  updateNewMessage(hasNewMessage: boolean) {
    if (this.cdkPopupConversationRef) {
      this.cdkPopupConversationRef.componentRef.instance.hasNewMessage = hasNewMessage;
    }
  }

  /**
   * Hide all popups
   */
  hidePopups() {
    if (this.cdkPopupConversationRef) {
      this.cdkPopupConversationRef.overlayRef.detach();
      this.cdkPopupConversationRef = undefined;
    }
    this.cdkPopupsRef?.forEach(popup => popup.overlayRef.detach());
    this.cdkPopupsRef = [];
  }

  /**
   * Return the position according options
   * @private
   */
  private getPosition(index: number = null) {
    const position = this.overlay.position().global();
    const indexPos = index !== null ? index : this.cdkPopupsRef.length;
    const offsetPopupConv = !!this.cdkPopupConversationRef ? 345 : 0;
    let right = indexPos * 305 + 20 + offsetPopupConv;
    if ((right + 305) > window.innerWidth) {
      right = 20 + offsetPopupConv;
    }
    position.bottom('0px');
    position.right(right + 'px');
    return position;
  }

}


import Talk from 'talkjs';
import { StandardResponseInterface } from '@common/interfaces/api';
import { UserProfileInterface } from '@common/interfaces/common.interface';

export interface TalkConversation extends Talk.ConversationData {
  participants?: {
    [name: string]: {
      access: string;
      notify: boolean;
    };
  };
  lastMessage?: TalkLastMessage;

  // User data
  otherParticipants: UserProfileInterface[];
  isSelected?: boolean;
  isRead?: boolean;
}

export interface TalkLastMessage {
  attachment?: any;
  conversationId?: string;
  createdAt: number;
  custom?: {
    [name: string]: string;
  };
  id: string;
  location?: any;
  origin?: string;
  readBy?: Array<string>;
  senderId: string;
  text: string;
  type: string;
}

export interface ReqTalkUsersInterface extends StandardResponseInterface {
  users: UserProfileInterface[];
}

export interface TalkUsersOptions {
  users?: Array<string | number>;
  query?: string;
  debut?: number;
  limit?: number;
  bypassPf?: boolean;
}


import { Component, HostListener, Input } from '@angular/core';
import { StandardConfigFieldsInterface } from '@common/interfaces/api/client';
import { ModalController } from '@ionic/angular';
import { FilteredFieldsInterface, ProfileFieldsEditType } from '@common/components/app/profile-fields/profile-fields.interface';
import { UsersService } from '@common/services/api/client';
import { ToolboxService } from '@common/services/toolbox.service';

@Component({
  selector: 'app-profile-fields-modal',
  templateUrl: './profile-fields.modal.html',
  styleUrls: ['./profile-fields.modal.scss'],
})
export class ProfileFieldsModal {

  @Input() idCustomer: number;
  @Input() userName?: string;
  @Input() fields: StandardConfigFieldsInterface;
  @Input() filteredFields?: FilteredFieldsInterface;

  @Input() showFields: boolean = false;
  @Input() allowDisable: boolean = true;
  @Input() editMode: ProfileFieldsEditType = 'none';
  @Input() closable: boolean = false;
  @Input() isAdminContext: boolean = false;

  processing: boolean = false;

  constructor(private modalCtrl: ModalController,
              private usersServ: UsersService,
              private toolboxServ: ToolboxService) {
  }

  applyChanges(fields: StandardConfigFieldsInterface) {
    if (this.isAdminContext) {
      // Admin context doesn't update user
      this.modalCtrl.dismiss(fields, 'apply')?.then(/* Nothing to do */);
    } else {
      this.processing = true;
      this.usersServ.updateUser({fields, id_customer: this.idCustomer}).subscribe({
        next: () => {
          this.processing = false;
          this.toolboxServ.flash({
            messageKey: 'alerts.std-update'
          })?.then(/* Nothing to do */);
          this.modalCtrl.dismiss(fields, 'apply')?.then(/* Nothing to do */);
        },
        error: err => {
          this.processing = false;
          this.toolboxServ.flash({
            message: this.toolboxServ.translate('alerts.std-error') + '<br />' + (err?.error?.message || err?.message),
            type: ToolboxService.TYPE_ERROR,
            duration: 5000
          })?.then(/* Nothing to do */);
        }
      });
    }
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel')?.then(/* Nothing to do */);
  }
}

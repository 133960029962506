import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Utils } from '@common/helpers/utils';

@Component({
  selector: 'app-autocomplete-input',
  template: `
    <div class="container ion-color-{{color || 'primary'}}" [class.focused]="isFocused" [class.disabled]="disabled" [class.invalid]="invalid">
      <input [(ngModel)]="value" [matAutocomplete]="template || localAuto"
             [disabled]="disabled"
             [placeholder]="placeholder"
             inputmode="search" title=""
             (focus)="isFocused = true" (blur)="isFocused = false" (keyup)="onKeyUp(value)"/>
      <ion-icon [name]="icon" [color]="iconColor || 'medium'" *ngIf="!isLoading && !!icon"></ion-icon>
      <ion-spinner [color]="iconColor || 'medium'" *ngIf="isLoading"></ion-spinner>
      <mat-autocomplete #localAuto="matAutocomplete">
        <mat-option *ngFor="let autocompleteValue of filteredAutocompleteValues | async" [value]="autocompleteValue"
                    (onSelectionChange)="onAutocompleteSelect($event)">
          {{autocompleteValue}}
        </mat-option>
      </mat-autocomplete>
      <div class="counter" *ngIf="counter">
        {{counterValue}}
      </div>
    </div>`,
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true,
    }
  ],
})
export class AutocompleteInputComponent implements OnInit {

  @Input() value?: string;

  @Input() color: string = 'primary';
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() placeholder: string = '';

  @Input() icon?: string;
  @Input() iconColor: string = 'primary';

  @Input() maxlength?: string | number;
  @Input() counter: boolean = false;

  @Input() template: any;
  @Input() autocompleteValues?: string[];

  @Input() isLoading: boolean = false;
  // Hysteresis for key up in ms
  @Input() keyUpHyst: number = 500;

  isFocused: boolean = false;
  counterValue: string = '';

  filteredAutocompleteValues?: Observable<string[]>;

  private valueChange: Subject<string> = new Subject<string>();
  private keyUpHystTimer: any = null;

  public onChange = (_: any) => {};
  public onTouch = () => {};

  ngOnInit() {
    if (this.autocompleteValues) {
      this.filteredAutocompleteValues = this.valueChange.asObservable().pipe(
        startWith(''),
        filter(value => value.length > 0),
        map(value => this._filter(value))
      );

      if (this.value) {
        this.valueChange.next(this.value);
        this.updateCounter();
      }
    }
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.autocompleteValues.filter(option => option.toLowerCase().includes(filterValue));
  }

  onKeyUp(value: string) {
    this.valueChange.next(value);
    this.updateCounter(value);
    if (this.keyUpHyst > 0) {
      if (this.keyUpHystTimer !== null) {
        clearTimeout(this.keyUpHystTimer);
      }
      this.keyUpHystTimer = setTimeout(() => {
        this.updateValue(value);
        this.keyUpHystTimer = null;
      }, this.keyUpHyst);
    } else {
      this.updateValue(value);
    }
  }

  onAutocompleteSelect(event: MatOptionSelectionChange) {
    this.updateValue(event.source.value);
  }

  updateValue(value?: string) {
    this.onChange(value || this.value);
    this.updateCounter(value);
  }

  updateCounter(value?: string) {
    value = value || this.value || '';
    // Update counter
    if (this.counter === true) {
      if (this.maxlength !== undefined) {
        this.counterValue = `${ value.length.toString() || '0' } / ${ this.maxlength.toString() }`;
      } else if (Utils.toNumber(value.length) > 0) {
        this.counterValue = value.length.toString() || '0';
      } else {
        this.counterValue = '';
      }
    }
  }

  /**
   * ControlValueAccessor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {
    this.value = inputValue;
    this.valueChange.next(this.value);
    this.updateCounter();
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}

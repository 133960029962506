import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentInputComponent } from './comment-input.component';
import { DirectivesModule } from '@common/directives/directives.module';
import { FormsModule } from '@angular/forms';
import { ImgSmartModule } from '@common/components/images';
import { IonicModule } from '@ionic/angular';
import { MatMenuModule } from '@angular/material/menu';
import { NgxEditorModule } from 'ngx-editor';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TranslocoModule } from '@ngneat/transloco';
import { MentionModule } from '@common/components/utils/mention';



@NgModule({
  declarations: [
    CommentInputComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    ImgSmartModule,
    IonicModule,
    MatMenuModule,
    NgxEditorModule,
    TextFieldModule,
    TranslocoModule,
    MentionModule,
  ],
  exports: [
    CommentInputComponent
  ]
})
export class CommentInputModule { }

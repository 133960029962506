/**
 * Schemes definition for custom apps
 */

export interface SchemeInterface {
  appIds: Array<string>;
  iosBatchId: string;
  androidBatchId: string;
  logoPath?: string;            // Path to logo of scheme
  landing?: string;             // If shall load configuration of landing page (fr SSO)
  oAuthRedirectBase?: string;   // Oauth config used for redirection
  domainRefererUrl: string;            // Domain Referer URL for onboarding invitation ,
  colors?: {
    primary: string;
    secondary: string;
    primaryDark?: string;
    secondaryDark?: string;
  };
}

interface Schemes {
  default: SchemeInterface;
  customs: SchemeInterface[];
}

export const schemes: Schemes = {
  default: {
    // Default scheme
    appIds: ['roadoo.roadoo', 'com.roadoo.roadoonetwork'],
    iosBatchId: '58F885BF6AE36B995F1D7898E2488D',
    androidBatchId: '595E47297BD54987398D0F3B4022DA',
    domainRefererUrl: 'https://roadoo.com'
  },
  customs: [
    {
      // Nexity Scheme
      appIds: ['roadoo.nexity', 'com.roadoo.nexity'],
      iosBatchId: '59F1EE402FFBE560A4A9A5AEE52C8F',
      androidBatchId: '59F1B580A7CA9C2DAA82216E0AD62A',
      logoPath: '/assets/images/app-whitemark/nexity.png',
      landing: 'nexity',
      domainRefererUrl: 'https://nexity.roadoo.com',
      colors: {
        primary: '#d22238',
        secondary: '#d22238',
        primaryDark: '#9B192A'
      }
    },
    {
      // Mylink Scheme
      appIds: ['roadoo.mylink', 'com.roadoo.mylinknewrest'],
      iosBatchId: '5FF44AE93A668C8F03FCC33786E0F3',
      androidBatchId: '5FD201CA4844F8A19932ECC86CD7D9',
      logoPath: '/assets/images/app-whitemark/mylink.png',
      landing: 'mylink',
      oAuthRedirectBase: 'https://mylink.roadoo.com',
      domainRefererUrl: 'https://mylink.roadoo.com',
      colors: {
        primary: '#D8750F',
        secondary: '#B6630D',
        primaryDark: '#8F4E0B'
      }
    },
    {
      // PistonR Scheme
      appIds: ['roadoo.pistonr', 'com.roadoo.pistonr'],
      iosBatchId: '625EB0B48EAA115B9572E47495B7EC',
      androidBatchId: '625EAEBD112C5144E2DD3F18499BA1',
      logoPath: '/assets/images/app-whitemark/pistonr.png',
      landing: 'pistonr',
      oAuthRedirectBase: 'https://pistonr.roadoo.com',
      domainRefererUrl: 'https://pistonr.roadoo.com',
      colors: {
        primary: '#8c52ff',
        secondary: '#000000',
        primaryDark: '#512e94'
      }
    },
    {
      // Abrisud Scheme
      appIds: ['com.abrisud.abrisudteams', 'com.abrisud.abrisudteams'],
      iosBatchId: '6364EE60AFFC1929E27A36DC18AA30',
      androidBatchId: '6364EE0886D2BE5961F7D83D832B3E',
      logoPath: '/assets/images/app-whitemark/abrisud.png',
      landing: 'abrisud',
      oAuthRedirectBase: 'https://abrisud.roadoo.com',
      domainRefererUrl: 'https://abrisud.roadoo.com',
      colors: {
        primary: '#E3A413',
        secondary: '#000E28',
        primaryDark: '#91690d'
      }
    },
    {
      // Midea Plus Scheme
      appIds: ['app.maslo.mideaplus'],
      iosBatchId: '66911C997C4A3D3F8485031925A333',
      androidBatchId: '66911D3AA18A5FFC11BA40DE3E9789',
      logoPath: '/assets/images/app-whitemark/mideaplus.png',
      landing: 'mideaplus',
      oAuthRedirectBase: 'https://mideaplus.roadoo.com',
      domainRefererUrl: 'https://mideaplus.roadoo.com',
      colors: {
        primary: '#1F94D2',
        secondary: '#000000',
        primaryDark: '#2ea2e0'
      }
    }
  ]
};


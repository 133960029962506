import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ConnectionService } from '@common/services/connection.service';
import { Subscription } from 'rxjs';
import { UserProfileInterface } from '@common/interfaces/common.interface';

@Component({
  selector: 'app-header-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class ClientMenuButtonComponent implements OnInit, OnDestroy {

  @Input() menuId: string;

  profile: UserProfileInterface;

  subscription: Subscription;

  constructor(private menuCtrl: MenuController,
              private connectionServ: ConnectionService) { }

  ngOnInit() {
    this.subscription = this.connectionServ.getToken().subscribe(token => {
      if (token) {
        this.profile = token.user;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  @HostListener('click')
  toggleMenu() {
    this.menuCtrl.toggle(this.menuId);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '@common/services/common.service';
import { ConfigFieldInterface } from '@common/interfaces/api/client';

@Pipe({
  name: 'translocoProfileField'
})
export class TranslocoProfileFieldPipe implements PipeTransform {

  constructor(private commonServ: CommonService) {
  }

  public transform(field: ConfigFieldInterface): string {
    return this.commonServ.translateProfileField(field);
  }

}

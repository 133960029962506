import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModal } from '@common/components/utils/loading/loading.modal';
import { IonicModule } from '@ionic/angular';
import { LoadingController } from '@common/components/utils/loading/loading.controller';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';



@NgModule({
  declarations: [
    LoadingModal
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoLocaleModule
  ],
  providers: [
    LoadingController
  ]
})
export class LoadingModule { }

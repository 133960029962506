import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';

@Component({
  selector: 'app-date-input-picker',
  templateUrl: './picker.popover.html',
  styleUrls: ['./picker.popover.scss']
})
export class DateInputPickerPopover implements OnInit {

  @Input() date: string = '';
  @Input() min?: string;
  @Input() max?: string;
  @Input() showSeconds: boolean = false;
  @Input() presentation: 'date' | 'date-time' | 'time' = 'date';

  public locale: string = 'en';
  public isDateChanged: boolean = false;

  private dateCurMoment?: moment.Moment;
  private dateFirst?: string;

  constructor(private popoverCtrl: PopoverController,
              private translateServ: TranslocoService) {
  }

  ngOnInit() {
    this.locale = this.translateServ.getActiveLang();
    this.dateCurMoment = !!this.date ? moment(this.date) : moment();
    this.dateFirst = !!this.date ? this.dateCurMoment.second(0).toISOString() : '';

    if (!this.max) {
      this.max = moment().add(10, 'years').toISOString();
    }
  }

  onDateChange(event: CustomEvent) {
    if (event.detail.value) {
      this.dateCurMoment = moment(event.detail.value);
    }
    this.isDateChanged = this.dateFirst !== this.dateCurMoment.toISOString();
  }

  dismiss() {
    if (!this.dateCurMoment) {
      this.popoverCtrl.dismiss(null, 'cancel').then(/* Nothing to do */);
      return;
    }

    let date: string;
    switch (this.presentation) {
      case 'date':
        date = this.dateCurMoment.format('YYYY-MM-DD');
        break;
      case 'date-time':
        date = this.dateCurMoment.format('YYYY-MM-DDTHH:mm:ssZ');
        break;
      case 'time':
        date = this.dateCurMoment.format('HH:mm') + (this.showSeconds ? ':00' : '');
        break;
    }
    this.popoverCtrl.dismiss(date, 'apply').then(/* Nothing to do */);
  }

}

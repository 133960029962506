import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteInputComponent } from '@common/components/forms/autocomplete-input/autocomplete-input.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


/**
 * Abstraction module to manage text input with custom focus management and allow access to matAutocomplete
 */
@NgModule({
  declarations: [
    AutocompleteInputComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule
  ],
  exports: [
    AutocompleteInputComponent
  ]
})
export class AutocompleteInputModule { }

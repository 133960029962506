import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import {
  QuizAnswerData,
  QuizInterface, QuizListInterface, ReqQuizGetProgressionInterface, ReqQuizRankingInterface, ReqQuizInterface,
  ReqQuizzesInterface
} from '@common/interfaces/api/client';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { StandardResponseInterface } from '@common/interfaces/api';

@Injectable()
export class QuizzesService extends HttpBaseService {
  quizzesList: QuizInterface[] = [];

  /**
   * Used to get the list of available quizzs.
   */
  quizzes(reload = false): Observable<QuizInterface[]> {
    if (this.quizzesList?.length > 0 && !reload) {
      return of(this.quizzesList);
    } else {
      if (reload) {
        this.quizzesList = [];
      }
      return this.stdRequest<ReqQuizzesInterface>(
        this.http.post<ReqQuizzesInterface>(`${ this.rootApi }/getQuizzes`, null)
      ).pipe(
        tap(response => {
          response?.quizzs?.forEach(quiz => this.updateLocalList(quiz as QuizInterface));
          response?.finished?.forEach(quiz => this.updateLocalList(quiz as QuizInterface));
        }),
        map(() => this.quizzesList)
      );
    }
  }

  /**
   * Used to get quiz
   */
  quiz(idQuiz: string): Observable<QuizInterface> {
    return this.stdRequest<QuizInterface>(
      this.http.post<ReqQuizInterface>(`${ this.rootApi }/getQuizz/${ idQuiz }`, null).pipe(
        tap(response => this.updateLocalList(response.quizz)),
        map(response => response.quizz)
      )
    );
  }

  /**
   * Update quiz results of local list
   * @param quiz object
   */
  updateLocalQuizResults(quiz: QuizInterface) {
    const quizIndex = this.quizzesList?.findIndex(item => item.id_action_quizz === quiz.id_action_quizz);

    if (quizIndex !== undefined && quizIndex > -1) {
      this.quizzesList[quizIndex].quiz_results = quiz.quiz_results;
    }
  }

  /**
   * Used to get the ranking of a given quiz
   * @param idQuiz id of quiz
   */
  ranking(idQuiz: string): Observable<ReqQuizRankingInterface> {
    return this.stdRequest<ReqQuizRankingInterface>(
      this.http.post<ReqQuizRankingInterface>(`${ this.rootApi }/getquizranking/${idQuiz}`, null)
    );
  }

  /**
   * Used to set the user’s progression for a certain quiz.
   * @param idQuiz id of the quiz.
   * @param idQuestion id of the question.
   * @param idAnswer id of the answer.
   */
  setProgression(idQuiz: string, idQuestion: string, idAnswer: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_quizz', idQuiz);
    body.append('id_question', idQuestion);
    body.append('reponses', idAnswer);
    return this.stdRequest<StandardResponseInterface>(
      this.http.post<StandardResponseInterface>(`${ this.rootApi }/setquizzprogression`, body)
    );
  }

  /**
   * Used to get the user’s progression for a certain quiz.
   * @param idQuiz id of the quiz.
   */
  getProgression(idQuiz: string): Observable<ReqQuizGetProgressionInterface> {
    const body = new FormData();
    body.append('id_quizz', idQuiz);
    return this.stdRequest<ReqQuizGetProgressionInterface>(
      this.http.post<ReqQuizGetProgressionInterface>(`${ this.rootApi }/getquizzprogression`, body)
    );
  }

  /**
   * Used to validate a quiz once the user has answered all the questions.
   * @param idQuiz id of the quiz.
   * @param answers each element contains the id of the question and the id of the response.
   */
  validateProgression(idQuiz: string, answers: QuizAnswerData[]): Observable<ReqQuizInterface> {
    const body = new FormData();
    body.append('id_quizz', idQuiz);
    body.append('reponses', JSON.stringify(answers));
    return this.stdRequest<ReqQuizInterface>(
      this.http.post<ReqQuizInterface>(`${ this.rootApi }/validatequizz`, body)
    );
  }

  /**
   * Update local list with quiz
   * @param quiz quiz object
   */
  updateLocalList(quiz: QuizInterface) {
    const quizIndex = this.quizzesList.findIndex(item => item.id_action_quizz === quiz.id_action_quizz);

    if (quizIndex > -1) {
      this.quizzesList[quizIndex] = quiz;
    } else {
      this.quizzesList.push(quiz);
    }
  }

}

import {AbstractControl, FormControl} from '@angular/forms';

export class DoublePhoneValidator {

  /**
   * Validate if at least one phone number is defined
   * @param otherPhoneControl other phone control or value
   */
  public static isValid(otherPhoneControl: string | AbstractControl): any {
    return (control: FormControl): any => {

      const phone1Value = otherPhoneControl instanceof AbstractControl ? otherPhoneControl.value : otherPhoneControl;

      if ((control?.value || '').length < 1 && phone1Value.length < 1) {
        return {'no-phone': true};
      }

      return null;
    };
  }

}
